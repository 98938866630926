export const ROUTES_WITH_RECAPTCHA_VALIDATION = [
  {
    route: 'withdrawals/v3/withdrawal/validate-client',
    method: 'POST',
    action: 'validateClient'
  },
  {
    route: 'withdrawals/v3/bonus/validate-client',
    method: 'POST',
    action: 'validateClient'
  },
  {
    route: 'withdrawals/v3/withdrawal/validate-foreign-client',
    method: 'POST',
    action: 'validateForeignClientGenericWithdrawal'
  },
  {
    route: 'withdrawals/v3/withdrawal/confirmation-voucher',
    method: 'POST',
    action: 'sendConfirmationVoucher'
  },
  {
    route: 'public/contact',
    method: 'POST',
    action: 'contact'
  },
  {
    route: 'withdrawals/v3/withdrawal/search-beneficiary-withdraw',
    method: 'POST',
    action: 'searchBeneficiaryGenericWithdrawal'
  },
  {
    route: 'withdrawals/v3/withdrawal/beneficiary-withdraw',
    method: 'POST',
    action: 'beneficiaryRequestGenericWithdrawal'
  },
  {
    route: 'withdrawals/v3/withdrawal/political-rejection',
    method: 'POST',
    action: 'saveRejectionPoliticalPosition'
  },
  {
    route: 'withdrawals/v3/withdrawal',
    method: 'POST',
    action: 'requestGenericWithdrawal'
  },
  {
    route: 'withdrawals/v3/withdrawal',
    method: 'GET',
    action: 'getGenericWithdrawals'
  },
  {
    route: 'withdrawals/v3/withdrawal/foreign',
    method: 'POST',
    action: 'foreignGenericRequestWithdrawal'
  },
  {
    route: 'withdrawals/v3/withdrawal/beneficiary-clients',
    method: 'GET',
    action: 'getBeneficiaryClientGenericWithdrawal'
  },
  {
    route: 'withdrawals/v3/withdrawal/annul',
    method: 'POST',
    action: 'annulGenericWithdrawal'
  },
  {
    route: 'withdrawals/v3/bonus',
    method: 'POST',
    action: 'insertFirstBonusRequest'
  },
  {
    route: 'withdrawals/v3/bonus/foreign',
    method: 'POST',
    action: 'foreignFirstBonusWithdrawal'
  },
  {
    route: 'withdrawals/v3/bonus/check-requirement/serial-number',
    method: 'POST',
    action: 'checkQualificationBySerialNumber'
  },
  {
    route: 'withdrawals/v3/bonus/check-requirement/password',
    method: 'POST',
    action: 'checkQualificationByPassword'
  },
  {
    route: 'withdrawals/v3/bonus/search-beneficiary-withdraw',
    method: 'POST',
    action: 'searchBeneficiaryFirstBonus'
  },
  {
    route: 'withdrawals/v3/bonus/beneficiary',
    method: 'POST',
    action: 'insertFirstBonusBeneficiaryRequest'
  },
  {
    route: 'withdrawals/v3/bonus/validate-foreign-client',
    method: 'POST',
    action: 'validateForeignClientFirstBonusWithdrawal'
  },
  {
    route: 'withdrawals/v3/withdrawal/available',
    method: 'GET',
    action: 'isWithdrawalAvailable'
  },
];
