import { Mockup } from '@interfaces/mockup.interface';

export const SEND_VOUCHER_MOCKUP: Mockup = {
  success: [{
    response: {}
  }],
  failures: [{
    response: {}
  }]
};
