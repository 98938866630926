import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  MAX_AMOUNT_SECOND_WITHDRAWAL, MAX_AMOUNT_THIRD_WITHDRAWAL
} from '@constants/max-amount-modal.constant';
import { WithdrawalTypeInWords } from '@interfaces/withdrawal.interface';

@Component({
  selector: 'app-max-amount-modal',
  templateUrl: './max-amount-modal.component.html',
  styleUrls: ['./max-amount-modal.component.scss']
})
export class MaxAmountModalComponent {

  public contentModal;

  public get isThirdWithdrawal(): boolean { return this.data ===  'third'; }

  constructor(
    public dialogRef: MatDialogRef<MaxAmountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WithdrawalTypeInWords
  ) {
    this.informationModal();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public informationModal(): void {
    this.contentModal = this.isThirdWithdrawal ?  MAX_AMOUNT_THIRD_WITHDRAWAL :  MAX_AMOUNT_SECOND_WITHDRAWAL;
  }
}
