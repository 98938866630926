import { WithdrawalClient } from '@interfaces/funds-withdrawal.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const GET_CLIENT_MOCKUP: Mockup = {
  success: [{
    response: {
      type: 'A',
      accounts: [
        'CAF',
        'CCO'
      ],
      rejectCode: '0',
      existingWithdrawalRequest: false,
      finishedWithdrawal: {
        rut: '19494426-8',
        nic: null,
        serialNumber: '520513997',
        name: 'FRANCISCO GABRIEL',
        fathersName: 'RONDA',
        mothersName: 'RODRÍGUEZ',
        email: 'esteban.ortiz@planvital.cl',
        phone: '978626108',
        address: 'Concon 67 Dpto E 23',
        commune: 13106,
        city: 'SANTIAGO',
        region: 13,
        percentage: 100,
        paymentMethod: 'B',
        bankCode: 1,
        accountType: '02',
        accountNumber: '987654321',
        authorizeGenerali: true,
        alimonyDebt: false,
      }
    } as WithdrawalClient
  }],
  failures: [{
    response: {
      statusCode: 404,
      messageError: 'Not Found',
      path: '/withdrawal/clients/1-9',
    }
  }]
};
