import { ContactFormLabels, ContactReasonItem } from '@interfaces/contact-form.interface';
import { ModalData } from '@interfaces/modal-data.interface';
import { GENERIC_WITHDRAWAL_NUMBER } from './funds-withdrawal.constant';

export const CONTACT_FORM_FIELDS: ContactFormLabels = {
  reasonCode: 'Motivo',
  name: 'Nombre',
  fatherLastName: 'Apellido Paterno',
  motherLastName: 'Apellido Materno',
  rut: 'RUT',
  email: 'E-mail',
  phoneNumber: 'Teléfono celular',
  description: 'Comentarios'
};

export const CONTACT_FORM_REASONS: {[key: number]: ContactReasonItem} = {
  [GENERIC_WITHDRAWAL_NUMBER.FIRST]: { code: '12', reason: 'Primer Retiro 10%' },
  [GENERIC_WITHDRAWAL_NUMBER.SECOND]: { code: '13', reason: 'Segundo Retiro 10%' },
  [GENERIC_WITHDRAWAL_NUMBER.THIRD]: { code: '14', reason: 'Tercer Retiro 10%' },
  [GENERIC_WITHDRAWAL_NUMBER.FIRST_BONUS]: { code: '16', reason: 'Bono de Cargo Fiscal' }
};

export const SEND_CONTACT_FORM_SUCCESS_MODAL: ModalData = {
  iconName: 'i-send-outline',
  title: 'Su solicitud ha sido ingresada exitosamente.',
  message: '',
  firstBtnText: 'Aceptar',
  primaryCallback: () => { }
};
