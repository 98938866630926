import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as WITHDRAWAL from '@constants/funds-withdrawal.constant';
import {
  COMPENSATION_BOX_CODE, COOPEUCH_CODE, RUT_ACCOUNT_ID, STATE_BANK_CODE, VISTA_ACCOUNT_ID
} from '@constants/general.constant';
import { ALPHANUMERIC_PATTERN } from '@constants/regex.constant';
import { WithdrawalFormInfo } from '@interfaces/components.interface';
import { Item, SelectOption } from '@interfaces/general.interface';
import { Region } from '@interfaces/parameters.interface';
import { PaymentInfo } from '@interfaces/payment-info.interface';
import { Utils } from '@utils/utils';
import { mustMatch } from '@validators/must-match.validator';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-payment-info-form',
  templateUrl: './payment-info-form.component.html',
  styleUrls: ['./payment-info-form.component.scss'],
})
export class PaymentInfoFormComponent implements OnInit, OnChanges {
  @Input() public paymentInfo: PaymentInfo;
  @Input() public banks: Array<SelectOption> = [];
  @Input() public accountTypes: Array<SelectOption> = [];
  @Input() public rut: string;
  @Input() public applicantRut: string;
  @Input() public regions: Array<Region>;
  @Input() public isBeneficiary: boolean;
  @Input() public cmsInformation: WithdrawalFormInfo;
  @Output() nextStep = new EventEmitter();

  public form: FormGroup;
  public accountNumberMaxLength = WITHDRAWAL.ACCOUNT_NUMBER_MAX_LENGTH;
  public accountNumberPattern = ALPHANUMERIC_PATTERN;
  public communes: Array<SelectOption> = [];
  public stateBankCode = STATE_BANK_CODE;
  private fieldsTransferOption = [
    'bankCode',
    'accountTypeId',
    'accountNumber',
    'repeatAccountNumber',
    'authorize',
    'personalAccountConsent'
  ];
  private fieldsVoucherOption = ['voucherRegion', 'voucherCommune'];

  constructor(
    private utils: Utils,
    private formBuilder: FormBuilder
  ) { }

  public get paymentMethodMask(): AbstractControl { return this.form.get('paymentMethodMask'); }

  public get paymentMethod(): AbstractControl { return this.form.get('paymentMethod'); }

  public get bankCode(): AbstractControl { return this.form.get('bankCode'); }

  public get accountTypeId(): AbstractControl { return this.form.get('accountTypeId'); }

  public get accountNumber(): AbstractControl { return this.form.get('accountNumber'); }

  public get repeatAccountNumber(): AbstractControl { return this.form.get('repeatAccountNumber'); }

  public get voucherRegion(): AbstractControl { return this.form.get('voucherRegion'); }

  public get voucherCommune(): AbstractControl { return this.form.get('voucherCommune'); }

  public get transferOption(): boolean { return this.paymentMethod.value === WITHDRAWAL.TRANSFER_ID; }

  public get secondaryAccountOption(): boolean { return this.paymentMethod.value === WITHDRAWAL.SECONDARY_ACCOUNT_ID; }

  public get voucherOption(): boolean { return this.paymentMethod.value === WITHDRAWAL.VOUCHER_ID; }

  public get otherPaymentMethods(): Item[] {
    return this.isBeneficiary ? WITHDRAWAL.BENEFICIARY_OTHER_PAYMENT_METHODS : WITHDRAWAL.OTHER_PAYMENT_METHODS;
  }

  public get paymentMethodsMask(): Item[] {
    return this.isBeneficiary ? WITHDRAWAL.BENEFICIARY_PAYMENT_METHODS_MASK : WITHDRAWAL.PAYMENT_METHODS_MASK;
  }

  public get otherPaymentMethodDefault(): string {
    return this.isBeneficiary ? WITHDRAWAL.VOUCHER_ID : WITHDRAWAL.SECONDARY_ACCOUNT_ID;
  }

  private get rutAccountNumber(): string {
    const rutFormated = this.rut || this.applicantRut;
    if (!rutFormated) { return; }
    const rutUnformatted = this.utils.rutClean(rutFormated);
    return rutUnformatted.substring(0, rutUnformatted.length - 1);
  }

  public ngOnInit(): void {
    this.form = this.createForm();

    this.setLocationFields();
    this.paymentMethodMaskSubscription();
    this.paymentMethodSubscription();
    this.otherInstitutionsSubscription();
    this.rutAccountTypeSubscription();
    this.accountNumberSubscription();
    this.setDefaultRutAccount();
    this.regionSubscription();
    this.fillForm(this.paymentInfo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.paymentInfo && !changes.paymentInfo.firstChange) {
      this.fillForm(this.paymentInfo);
    }
  }

  public createForm(): FormGroup {
    const paymentInfoForm = this.formBuilder.group({
      paymentMethodMask: [WITHDRAWAL.TRANSFER_ID, Validators.required],
      paymentMethod: [WITHDRAWAL.TRANSFER_ID, Validators.required],
      bankCode: [null, Validators.required],
      accountTypeId: [null, Validators.required],
      accountNumber: [null, this.generateAlfanumericValidator(WITHDRAWAL.ACCOUNT_NUMBER_MAX_LENGTH)],
      repeatAccountNumber: [null, this.generateAlfanumericValidator(WITHDRAWAL.ACCOUNT_NUMBER_MAX_LENGTH)],
      voucherRegion: [null, Validators.required],
      voucherCommune: [null, Validators.required],
      authorize: [false, Validators.requiredTrue],
      personalAccountConsent: [false, Validators.requiredTrue],
    }, {
      validator: [
        mustMatch('accountNumber', 'repeatAccountNumber'),
      ],
    });

    return paymentInfoForm;
  }

  public fillForm(paymentInfo: PaymentInfo): void {
    if (paymentInfo && this.form) {
      const { voucherRegion, voucherCommune } = paymentInfo;
      if (voucherRegion) {
        this.voucherRegion.setValue(voucherRegion.toString());
      }
      if (voucherCommune) {
        this.voucherCommune.setValue(voucherCommune.toString());
      }
    }
  }

  private generateAlfanumericValidator(maxLength: number): Array<Validators> {
    return [Validators.required, Validators.maxLength(maxLength), Validators.pattern(ALPHANUMERIC_PATTERN)];
  }

  public confirm() {
    if (this.form.invalid) { return; }
    const params = this.getParsedData();
    this.nextStep.emit(params);
  }

  private getParsedData(): PaymentInfo {
    const data = this.form.getRawValue();

    const { bankCode, accountTypeId, voucherRegion, voucherCommune } = data;

    if (bankCode) {
      data.bankName = this.getBankName(bankCode);
      data.bankCode = parseInt(bankCode, 10);
    }
    const accountType = accountTypeId ? this.accountTypes.find((type) => type.id === accountTypeId) : null;
    if (accountType) {
      data.accountType = !this.isBeneficiary ? accountType.code : accountType.beneficiaryId;
      data.accountTypeName = accountType.description;
    }
    if (bankCode === COMPENSATION_BOX_CODE) { data.paymentMethod = WITHDRAWAL.COMPENSATION_BOX_ID; }
    if (voucherRegion) { data.voucherRegion = parseInt(voucherRegion, 10); }
    if (voucherCommune) {
      data.voucherCommune = parseInt(voucherCommune, 10);
      data.city = this.utils.getCity(this.regions, voucherRegion, voucherCommune);
    }

    return data;
  }

  public showAccountTypeOption(id: number): boolean {
    return id !== RUT_ACCOUNT_ID || this.bankCode.value === this.stateBankCode;
  }

  private setLocationFields(): void {
    const regionSelected = this.regions.find(region => region.code === this.voucherRegion.value);
    this.communes = regionSelected ? regionSelected.communes : [];
    regionSelected ? this.voucherCommune.enable() : this.voucherCommune.disable();
    this.utils.disableFields(this.form, this.fieldsVoucherOption, false);
  }

  private regionSubscription(): void {
    this.voucherRegion.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe(
        (regionCodeSelected) => {
          const regionSelected = this.regions.find(region => region.code === regionCodeSelected);
          this.voucherCommune.setValue('');
          this.communes = regionSelected ? regionSelected.communes : [];
          regionSelected ? this.voucherCommune.enable() : this.voucherCommune.disable();
        });
  }

  private accountNumberSubscription(): void {
    this.accountNumber.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((accountNumber: string) => {
        if (!accountNumber) { return; }
        this.validateAccountNumber();
      });
  }

  private isRutAccountNumber(accountNumber: string, bankCode: string, accountTypeId: number) {
    return bankCode === STATE_BANK_CODE && accountTypeId !== RUT_ACCOUNT_ID && accountNumber.startsWith(this.rutAccountNumber);
  }

  private validateAccountNumber(): void {
    const { accountNumber, bankCode, accountTypeId } = this.form.getRawValue();
    if (this.utils.isVirtualAccount(accountNumber, bankCode)) {
      this.accountNumber.setErrors({ virtualAccount: true });
    } else if (this.isRutAccountNumber(accountNumber, bankCode, accountTypeId)) {
      this.accountNumber.setErrors({ rutAccount: true });
    }
  }

  private paymentMethodMaskSubscription(): void {
    this.paymentMethodMask.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe(
        (paymentMethodMask: string) => {
          this.paymentMethod.setValue(paymentMethodMask === WITHDRAWAL.TRANSFER_ID ?
            WITHDRAWAL.TRANSFER_ID : this.otherPaymentMethodDefault);
        }
      );
  }

  private paymentMethodSubscription(): void {
    this.paymentMethod.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe(
        (paymentMethod: string) => {
          paymentMethod === WITHDRAWAL.TRANSFER_ID ? this.utils.enableFields(this.form, this.fieldsTransferOption)
            : this.resetFields(this.fieldsTransferOption);
          paymentMethod === WITHDRAWAL.VOUCHER_ID ? this.utils.enableFields(this.form, this.fieldsVoucherOption)
            : this.utils.disableFields(this.form, this.fieldsVoucherOption, false);
        }
      );
  }

  private resetFields(fieldNames: Array<string>, disable: boolean = true): void {
    fieldNames.forEach((field) => {
      const control = this.form.get(field);
      control.reset();
      if (disable) { control.disable(); }
    });
  }

  private otherInstitutionsSubscription(): void {
    this.bankCode.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((code: string) => {
        this.resetFields(['accountTypeId', 'accountNumber', 'repeatAccountNumber'], false);
        if (code === COMPENSATION_BOX_CODE || code === COOPEUCH_CODE) {
          this.accountTypeId.setValue(VISTA_ACCOUNT_ID);
          this.accountTypeId.disable();
        } else {
          this.accountTypeId.setValue(null);
          this.accountTypeId.enable();
        }
        this.accountTypeId.updateValueAndValidity();
      });
  }

  private rutAccountTypeSubscription(): void {
    this.accountTypeId.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((accountTypeId: number) => {
        if (accountTypeId === RUT_ACCOUNT_ID && (this.rut || this.applicantRut)) {
          this.accountNumber.setValue(this.rutAccountNumber);
          this.accountNumber.disable();
        } else {
          this.accountNumber.setValue(null);
          this.accountNumber.enable();
        }
        this.accountNumber.updateValueAndValidity();
      });
  }

  private setDefaultRutAccount(): void {
    if (!this.rut && !this.applicantRut) { return; }
    this.form.patchValue({
      bankCode: STATE_BANK_CODE,
      accountTypeId: RUT_ACCOUNT_ID,
    });
  }

  private getBankName(bankCode): string {
    const selectBank = this.banks.find(bank => bank.code === bankCode);
    if (selectBank) {
      return selectBank.description;
    } else if (this.paymentMethod.value === WITHDRAWAL.VOUCHER_ID) {
      return WITHDRAWAL.OTHER_PAYMENT_DEFAULT;
    } else if (this.paymentMethod.value === WITHDRAWAL.SECONDARY_ACCOUNT_ID) {
      return WITHDRAWAL.SECONDARY_ACCOUNT;
    }
    return null;
  }

}

