import { Mockup } from '@interfaces/mockup.interface';

export const REQUEST_WITHDRAWAL_MOCKUP: Mockup = {
  success: [{
    response: {
      folio: '5643529405595648'
    }
  }],
  failures: [{
    response: {}
  }]
};
