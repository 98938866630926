import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client/http-client.interceptor';
import { PaymentMethods, Region } from '@interfaces/parameters.interface';
import { PAYMENT_METHODS_MOCKUP } from '@services/parameters/mocks/payment-methods.mock';
import { REGIONS_MOCKUP } from '@services/parameters/mocks/regions.mock';
import { ErrorUtils } from '@utils/error.utils';

import { VALIDATE_EMAIL_DOMAIN_MOCK } from './mocks/email-domain-validation.mock';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  private baseUrl = `${environment.withdrawalsUrl}parameters/`;

  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils,
  ) {}

  public getPaymentMethods(): Observable<PaymentMethods> {
    const url = `${this.baseUrl}payment-methods`;
    return this.http.get(url, PAYMENT_METHODS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getRegions(): Observable<Array<Region>> {
    const url = `${this.baseUrl}public-regions`;
    return this.http.get(url, REGIONS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public validateEmailDomain(email: string): Observable<boolean> {
    const url = `${this.baseUrl}validate-email/?email=${email}`;
    return this.http.get(url, VALIDATE_EMAIL_DOMAIN_MOCK)
      // tslint:disable-next-line deprecation
      .pipe(catchError(() => of(true)));
  }
}
