import { BeneficiaryWithdrawalClient } from '@interfaces/funds-withdrawal.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const SEARCH_BENEFICIARY_CLIENT_MOCKUP: Mockup = {
  success: [{
    response: {
        personId: '123',
        beneficiaryId: '123',
        paidReceiverId: '123',
        relationshipCod: 90,
        name: 'FRANCISCO GABRIEL',
        paternalLastName: 'RONDA',
        maternalLastName: 'RODRÍGUEZ',
        typeOfPayment: '123',
        bankCode: '1',
        bankAccountTypeCode: '123',
        accountNumber: '987654321',
      } as BeneficiaryWithdrawalClient
  }],
  failures: [{
    response: {
      statusCode: 404,
      messageError: 'Not Found',
      path: '/search-beneficiary-withdraw',
    }
  }]
};
