import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client/http-client.interceptor';
import {
  AnullmentResponse, BeneficiaryWithdrawalClient, GetRequests, WithdrawalClient,
  WithdrawalForeignClient, WithdrawalRequestResponse, WithdrawalType
} from '@interfaces/funds-withdrawal.interface';
import { BeneficiaryWithdrawal, SearchBeneficiaryWithdrawal, Withdrawal } from '@interfaces/withdrawal.interface';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GET_CLIENT_MOCKUP } from './mocks/get-client.mock';
import { GET_FOREIGN_CLIENT_MOCKUP } from './mocks/get-foreign-client.mock';
import { GET_REQUESTS_MOCKUP } from './mocks/get-requests.mock';
import { REQUEST_BENEFICIARY_WITHDRAWAL_MOCKUP } from './mocks/request-beneficiary-withdrawal.mock';
import { REQUEST_WITHDRAWAL_MOCKUP } from './mocks/request-withdrawal.mock';
import { SEARCH_BENEFICIARY_CLIENT_MOCKUP } from './mocks/search-beneficiary-client.mock';
import { SEND_VOUCHER_MOCKUP } from './mocks/send-voucher.mock';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalsService {
  private genericWithdrawalUrl = `${environment.withdrawalsUrl}v3/withdrawal/`;

  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils,
    private utils: Utils,
  ) { }

  public checkIfWithdrawalIsEnabled(withdrawalNumber: WithdrawalType): Observable<any> {
    const url = `${this.genericWithdrawalUrl}available/?withdrawalNumber=${withdrawalNumber}`;
    return this.http.get(url, REQUEST_WITHDRAWAL_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public requestBeneficiaryGenericWithdrawal(withdrawalNumber: WithdrawalType, withdrawal: BeneficiaryWithdrawal): Observable<any> {
    const body = { withdrawalNumber, ...withdrawal };
    return this.http.post(`${this.genericWithdrawalUrl}beneficiary-withdraw/`, body, REQUEST_BENEFICIARY_WITHDRAWAL_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public requestGenericWithdrawal(withdrawalNumber: WithdrawalType, withdrawal: Withdrawal): Observable<any> {
    return this.http.post(this.genericWithdrawalUrl, { withdrawalNumber, ...withdrawal }, REQUEST_WITHDRAWAL_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public requestGenericForeignWithdrawal(withdrawalNumber: WithdrawalType, form: FormData): Observable<any> {
    const url = `${this.genericWithdrawalUrl}foreign/`;
    form.append('withdrawalNumber', withdrawalNumber.toString());
    const headers = new HttpHeaders().set('withdrawal-number', withdrawalNumber.toString());
    return this.http.post(url, form, REQUEST_WITHDRAWAL_MOCKUP, headers)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getGenericRequests(withdrawalNumber: WithdrawalType, data: GetRequests): Observable<WithdrawalRequestResponse> {
    return this.http.get(`${this.genericWithdrawalUrl}${this.getUrlParams(withdrawalNumber, data)}`, GET_REQUESTS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  private getUrlParams(withdrawalNumber: WithdrawalType, data: GetRequests): string {
    const { rut, typeSearchOption, folio, phone, email } = data;
    const cleanRut = this.utils.rutClean(rut);
    const additionalParam = typeSearchOption === 1 ? `folio=${folio}` :
      typeSearchOption === 2 ? `phone=${phone}` : `email=${email}`;
    const withdrawalNumberParam = `withdrawalNumber=${withdrawalNumber}`;
    return `?rut=${cleanRut}&${additionalParam}&${withdrawalNumberParam}`;
  }

  public getClientGenericWithdrawal(
    withdrawalNumber: WithdrawalType, id: string, serialNumber?: string, password?: string
  ): Observable<WithdrawalClient> {
    const url = `${this.genericWithdrawalUrl}validate-client/`;
    const body = { rut: id, serialNumber, password, withdrawalNumber };
    return this.http.post(url, body, GET_CLIENT_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getForeignClientGenericWithdrawal(
    withdrawalNumber: WithdrawalType, id: string, serialNumber: string, password: string,
  ): Observable<WithdrawalForeignClient> {
    const url = `${this.genericWithdrawalUrl}validate-foreign-client/`;
    const body = { rut: id, serialNumber, password, withdrawalNumber };
    return this.http.post(url, body, GET_FOREIGN_CLIENT_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getBeneficiaryClientGenericWithdrawal(id: string): Observable<WithdrawalClient> {
    const url = `${this.genericWithdrawalUrl}beneficiary-clients/${id}`;
    // TODO: change the mock
    return this.http.get(url, GET_CLIENT_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public annulGenericRequest(
    withdrawalNumber: WithdrawalType, rut: string, folio: string, reasonId: number
  ): Observable<AnullmentResponse> {
    const url = `${this.genericWithdrawalUrl}annul/`;
    const data = { withdrawalNumber, rut, folio, reasonId };
    return this.http.post(url, data, REQUEST_WITHDRAWAL_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public sendConfirmationVoucher(withdrawalNumber: WithdrawalType, rut: string, folio: string): Observable<void> {
    const data = { withdrawalNumber, rut, folio };
    return this.http.post(`${this.genericWithdrawalUrl}confirmation-voucher/`, data, SEND_VOUCHER_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public searchBeneficiaryClientGenericWithdrawal(withdrawalNumber: WithdrawalType, applicantRut: string, beneficiaryRut: string,
    affiliateRut: string, isMinor: boolean): Observable<BeneficiaryWithdrawalClient> {
    const url = `${this.genericWithdrawalUrl}search-beneficiary-withdraw/`;
    const data: SearchBeneficiaryWithdrawal = { applicantRut, beneficiaryRut, affiliateRut, isMinor, withdrawalNumber };
    return this.http.post(url, data, SEARCH_BENEFICIARY_CLIENT_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public saveRejectionPoliticalPosition(withdrawalNumber: WithdrawalType, rut: string, isPolitical: boolean): Observable<void> {
    const url = `${this.genericWithdrawalUrl}political-rejection/`;
    const body = { withdrawalNumber, rut, isPolitical };
    return this.http.post(url, body, GET_CLIENT_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }
}
