import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ONLY_NUMBERS_PATTERN } from '@constants/regex.constant';
import { Subscription } from 'rxjs';
import { FEMALE_BALANCE_VALUES, FEMALE_PENSION_VALUES } from './female-values.constants';
import { AMOUNT_CONSTANTS, GENDER_CONSTANTS, MAX_AGE_FEMALE_CONSTANT, MAX_AGE_MALE_CONSTANT,
  MIN_AGE_CONSTANT } from './general-input.constants';
import { MALE_BALANCE_VALUES, MALE_PENSION_VALUES } from './male-values.constants';

@Component({
  selector: 'app-simulation-modal',
  templateUrl: './simulation-modal.component.html',
  styleUrls: ['./simulation-modal.component.scss']
})
export class SimulationModalComponent implements OnInit, OnDestroy {
  public step = 0;
  public genders = GENDER_CONSTANTS;
  public amounts = AMOUNT_CONSTANTS;
  public amountBalance = '';
  public pensionBalance = '';
  public age: number;
  public amountWithdrawal = '';
  public form: FormGroup;
  public validatorSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SimulationModalComponent>,
    public formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      gender: ['', Validators.required],
      age: ['', [Validators.required, Validators.pattern(ONLY_NUMBERS_PATTERN), Validators.min(MIN_AGE_CONSTANT)]],
      amount: ['', [Validators.required]]
    });
  }

  public get firstStep(): boolean { return this.step === 0; }
  public get secondStep(): boolean { return this.step === 1; }

  public ngOnInit() {
    this.setMaxAgeValidator();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public getError(controlName: string, validatorName: string) {
    return this.form.get(controlName).hasError(validatorName);
  }

  public nextStep() {
    const { gender, age, amount: amountIndex } = this.form.value;
    this.amountWithdrawal = this.amounts[amountIndex].description;
    let balanceValues;
    let pensionValues;
    if (gender === 0) {
      this.age = MAX_AGE_FEMALE_CONSTANT;
      balanceValues = FEMALE_BALANCE_VALUES;
      pensionValues = FEMALE_PENSION_VALUES;
    } else {
      this.age = MAX_AGE_MALE_CONSTANT;
      balanceValues = MALE_BALANCE_VALUES;
      pensionValues = MALE_PENSION_VALUES;
    }
    const balanceItem = balanceValues.find((option) => option.id === Number(age));

    this.amountBalance = balanceItem ? balanceItem.values[amountIndex] : '';

    const pensionItem = pensionValues.find((option) => option.id === Number(age));
    this.pensionBalance = pensionItem ? pensionItem.values[amountIndex] : '';

    this.step++;
  }

  public previousStep() {
    this.step--;
  }

  public ngOnDestroy() {
    if (!this.validatorSubscription.closed) { this.validatorSubscription.unsubscribe(); }
  }

  private setMaxAgeValidator() {
    this.validatorSubscription = this.form.get('gender').valueChanges
      .subscribe(gender => {
        const ageValidator = gender === GENDER_CONSTANTS[0].id ? [Validators.max(MAX_AGE_FEMALE_CONSTANT)] :
         [Validators.max(MAX_AGE_MALE_CONSTANT)];
        const actualValidators = [Validators.required, Validators.pattern(ONLY_NUMBERS_PATTERN), Validators.min(MIN_AGE_CONSTANT)];
        this.form.get('age').setValidators(ageValidator.concat(actualValidators));
        this.form.get('age').updateValueAndValidity();
      });
  }

}
