// tslint:disable: max-line-length
import { BRANCH_OFFICES_URL, CONSULT_AFFILIATION_URL } from '@constants/url.constant';
import { environment } from '@environment';
import { WithdrawalType } from '@interfaces/funds-withdrawal.interface';
import { Element, Item, Section, SelectOption } from '@interfaces/general.interface';
import { ModalData } from '@interfaces/modal-data.interface';

export const PHONE_NUMBER_LENGTH = 9;
export const PHONE_NUMBER_MIN = 100000000;
export const PHONE_NUMBER_MAX = 999999999;

export const EXPRESS_QUESTION_MODAL = {
  title: 'Eres parte de AFP PlanVital',
  subtitle: 'Hemos identificado que ya realizaste un retiro de fondos anteriormente.',
  message: '¿Deseas que tu solicitud de retiro sea procesada considerando la misma información utilizada en el retiro anterior (datos de contacto, modalidad de pago, etc)?'
};

export const STEPS_LABELS: Array<string> = [
  'Tus datos',
  'Monto a retirar',
  'Medio de pago',
  '¡Solicitud lista!',
];

export const STEPS_LABELS_FOREIGN: Array<string> = [
  'Tus datos',
  'Monto a retirar',
  'Medio de pago',
  'Archivos',
  '¡Solicitud lista!',
];

export const VALIDATE_OPTIONS: Array<Item> = [
  { id: 1, name: 'Con mi RUT' },
  { id: 2, name: 'Con mi NIC', description: '(solo para extranjeros sin Cédula de Identidad)' },
  { id: 3, name: 'Desde el extranjero' },
];

export const CLIENT_WITH_DEBT_TYPES = {
  HAS_NOT_DEBT_TYPE: '1',
  HAS_DEBT_WITHOUT_BENEFICIARY_TYPE: '2',
  HAS_DEBT_WITH_BENEFICIARY_TYPE: '3',
};

export const TRANSFER_ID = 'B';
export const TRANSFER_BENEFICIARY_ID = 'BCO';
export const SECONDARY_ACCOUNT_ID = 'T';
export const VOUCHER_ID = 'V';
export const VOUCHER_BENEFICIARY_ID = 'VV';
export const COMPENSATION_BOX_ID = 'C';

export const WITHDRAWAL_STATUS_RESPONSE_LABELS = {
  IN_PROGRESS: 'En curso',
  REJECTED: 'Rechazada',
  ACCEPTED: 'Aceptada',
  FINISHED: 'Terminada',
  ANNULLED: 'Anulada',
};

export const GENERIC_WITHDRAWAL_NUMBER = {
  FIRST: 1 as WithdrawalType,
  SECOND: 2 as WithdrawalType,
  THIRD: 3 as WithdrawalType,
  FIRST_BONUS: 201 as WithdrawalType
};

export const GENERIC_WITHDRAWAL_TEXTS = {
  2: {
    TITLE: 'Solicitud del segundo retiro de fondo',
    FOREIGN_TITLE: 'Solicitud del segundo retiro de fondo extranjero',
    DESCRIPTION: 'Completa estos sencilllos pasos para enviar tu solicitud del segundo retiro de fondos del 10% de tus ahorros previsionales',
  },
  3: {
    TITLE: 'Solicitud del tercer retiro de fondos',
    FOREIGN_TITLE: 'Solicitud del tercer retiro de fondo extranjero',
    DESCRIPTION: 'Completa estos sencilllos pasos para enviar tu solicitud del tercer retiro de fondos del 10% de tus ahorros previsionales',
  },
};

export const MANDATE_ID = '3';
export const FOREIGN_BANK_ID = '1';

export const OTHER_COUNTRY_CODE = 'OTRO';
export const STATUS_WITH_RETENTION_LABEL = 'con retención';

export const PAYMENT_METHODS_MASK: Array<Item> = [
  { id: TRANSFER_ID, name: 'Transferencia' },
  { id: SECONDARY_ACCOUNT_ID, name: 'Otros medios' },
];

export const BENEFICIARY_PAYMENT_METHODS_MASK: Array<Item> = [
  { id: TRANSFER_ID, name: 'Transferencia' },
  { id: VOUCHER_ID, name: 'Otros medios' },
];

export const OTHER_PAYMENT_METHODS: Array<Item> = [
  { id: SECONDARY_ACCOUNT_ID, name: 'Cuenta 2' },
  { id: VOUCHER_ID, name: 'Otro a informar por la AFP' },
];

export const BENEFICIARY_OTHER_PAYMENT_METHODS: Array<Item> = [
  { id: VOUCHER_ID, name: 'Otro a informar por la AFP' },
];

export const PAYMENT_METHODS: Array<Item> = [
  { id: TRANSFER_ID, name: 'Transferencia' },
  ...OTHER_PAYMENT_METHODS
];

export const BENEFICIARY_PAYMENT_METHODS: Array<Item> = [
  { id: TRANSFER_ID, name: 'Transferencia' },
  ...BENEFICIARY_OTHER_PAYMENT_METHODS
];

export const PAYMENT_METHODS_FOREIGN: Array<Item> = [
  { id: FOREIGN_BANK_ID, name: 'Transferencia a Banco Extranjero' },
  { id: MANDATE_ID, name: 'A través de un representante' }
];

export const ID_MAX_LENGTH = 12;
export const ACCOUNT_NUMBER_MAX_LENGTH = 14;
export const FOREIGN_ACCOUNT_NUMBER_MAX_LENGTH = 50;
export const NAMES_MAX_LENGTH = 50;
export const SECOND_WITHDRAWAL_NAMES_MAX_LENGTH = 30;
export const LASTNAMES_MAX_LENGTH = 40;
export const PASSWORD_MIN_LENGTH = 4;
export const PASSWORD_MAX_LENGTH = 8;
export const SECURITY_KEY_MIN_LENGTH = 6;
export const SECURITY_KEY_MAX_LENGTH = 20;
export const PERCENTAGE_MIN_VALUE = 1;
export const PERCENTAGE_MAX_VALUE = 100;
export const SERIAL_NUMBER_MAX_LENGTH = 10;
export const MIN_FIRST_FOLIO_LENGTH = 10;
export const MAX_FIRST_FOLIO_LENGTH = 16;
export const MIN_SECOND_FOLIO_LENGTH = 7;
export const MAX_SECOND_FOLIO_LENGTH = 17;
export const BANK_CODE_TYPE_SWIFT_MIN_LENGTH = 8;
export const BANK_CODE_TYPE_SWIFT_MAX_LENGTH = 11;
export const BANK_CODE_TYPE_BSB_MAX_LENGTH = 6;
export const BANK_CODE_TYPES_MIN_LENGTH = {
  swift: 8,
  aba: 9,
  bsb: 6,
  iban: 15,
};
export const BANK_CODE_TYPES_MAX_LENGTH = {
  swift: 11,
  aba: 9,
  bsb: 6,
  iban: 32,
};

export const REQUEST_STATUS = {
  inProgress: 'I',
  annulled: 'N',
  accepted: 'A',
  rejected: 'R',
  finished: 'T',
};

export const CLIENT_TYPES: Array<Element> = [
  {
    id: 'A',
    name: 'affiliate',
    title: 'Eres Afiliado en AFP PlanVital',
    description: 'El retiro será desde tu Cuenta Obligatoria',
  },
  {
    id: 'P',
    name: 'pensioner',
    title: 'Eres Afiliado en AFP PlanVital',
    description: 'El retiro será desde tu Cuenta Obligatoria',
  },
  {
    id: 'B',
    name: 'beneficiary',
    title: 'Eres Afiliado en AFP PlanVital',
    description: 'El retiro será desde tu Cuenta Obligatoria',
  },
];

export const ACCOUNTS_NAMES = {
  CCO: 'Obligatoria',
  CAF: 'Voluntaria',
};

export const OTHER_PAYMENT_DEFAULT = 'BCI';

export const SECONDARY_ACCOUNT = 'AFP PLANVITAL';

export const CLIENT_DESCRIPTION: Section = {
  title: 'Eres parte de AFP PlanVital',
  description: 'Puedes continuar con el proceso de solicitud de retiro',
};

export const AFILIATE_DESCRIPTION: Section = {
  title: 'Afiliado consultado es parte de AFP PlanVital',
  description: 'Puedes continuar con el proceso de solicitud de retiro',
};

export const NOT_CLIENT_DESCRIPTION: Section = {
  title: 'No eres parte de AFP PlanVital',
  description: `Te invitamos a realizar la solicitud en tu AFP. Si no la conoces, <a target="_blank" href="${CONSULT_AFFILIATION_URL}">ingresa aquí</a>`,
};

export const DECEASED_RUT_NOT_FOUND_DESCRIPTION: Section = {
  title: 'Afiliado consultado no es parte de AFP PlanVital',
  description: `Te invitamos a realizar la solicitud en su AFP. Si no la conoces, <a target="_blank" href="${CONSULT_AFFILIATION_URL}">ingresa aquí</a>`,
};

export const BENEFICIARY_DESCRIPTION: Section = {
  title: 'No podemos validar tu Rut',
  description: `Por favor dirígete a una de nuestras <a target="_blank" href="${BRANCH_OFFICES_URL}">sucursales</a>`,
};

export const PRIVATE_RUT_DESCRIPTION: Section = {
  title: 'No es posible validar tu Rut',
  description: `Inténtalo más tarde`,
};

export const RECOVERY_PASSWORD_DISCLAIMER = `Solicita o recupera tu clave de acceso <a target="_blank" href="${environment.recoverAccessKeyUrl}">aquí</a>`;

export const BASE_REASONS: Array<Item> = [
  {
    id: 0,
    name: 'Yo no hice la solicitud',
  },
  {
    id: 1,
    name: 'Quiero cambiar los datos de mi solicitud',
  },
  {
    id: 2,
    name: 'Declaración errónea de deuda alimenticia',
  },
];

export const CANCEL_REASONS: Array<Item> = [
  ...BASE_REASONS,
  {
    id: 3,
    name: 'Declaración errónea por cargo público',
  },
];

export const INACTIVE_BANKS: Array<SelectOption> = [
  {
    code: '303',
    description: 'CAJA DE COMPENSACIÓN LOS HÉROES'
  },
  {
    code: '507',
    description: 'BANCO DEL DESARROLLO'
  },
  {
    code: '27',
    description: 'CORPBANCA'
  },
  {
    code: '46',
    description: 'ABN AMRO BANK'
  },
  {
    code: '43',
    description: 'BANCO DE LA NACION ARGENTINA'
  },
  {
    code: '17',
    description: 'BANCO DO BRASIL S.A'
  },
  {
    code: '56',
    description: 'BANCO PENTA'
  },
  {
    code: '52',
    description: 'DEUTSCHE BANK CHILE'
  },
  {
    code: '41',
    description: 'JP MORGAN CHASE BANK, N. A.'
  },
  {
    code: '54',
    description: 'RABOBANK CHILE, ex HNS BANCO'
  },
];

export const REQUIERED_DOCUMENTS_FOR_FOREIGN_WITHDRAWAL = [
  {
    id: 1,
    title: 'Documento de Identificación',
  },
  {
    id: 2,
    title: 'Certificado de residencia'
  },
  {
    id: 3,
    title: 'Certificado de Cuenta Corriente'
  }
];

export const REQUIERED_DOCUMENTS_FOR_FOREIGN_WITHDRAWAL_DESCRIPTIONS = {
  1: 'Debes subir tu Cédula de identidad o documento de identidad del país de residencia o Pasaporte.',
  2: 'Debes subir este documento emitido por alguna autoridad en tu país de residencia.',
  3: 'Debes subir el documento emitido por el banco en la que desea que se realice la transferencia.',
  4: 'Debes subir una foto legible de tu rostro.',
};

export const VALID_WITHDRAWAL_FILES = ['identificationFile', 'residenceCertificateFile', 'accountCertificateFile', 'facePhotoFile'];
export const VALID_WITHDRAWAL_FILE_EXTENSIONS = ['doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf', 'gif'];
export const WITHRAWAL_FILE_MAX_SIZE = 3145728;

export const BANK_CODE_SWIFT_INVALID_LENGTH_ERROR = `Código inválido, debe ser entre ${BANK_CODE_TYPE_SWIFT_MIN_LENGTH} y ${BANK_CODE_TYPE_SWIFT_MAX_LENGTH} dígitos`;
export const BANK_CODE_BSB_INVALID_LENGTH_ERROR = `Código inválido, debe ser igual o menor a ${BANK_CODE_TYPE_BSB_MAX_LENGTH} dígitos`;
export const BANK_CODE_REQUIRED_FIELD_ERROR = 'Campo requerido';
export const BANK_CODE_SWIFT_THREE_LAST_DIGITS_ERROR = 'Los últimos dos dígitos deben ser números';
export const SIMPLE_STATEMENT_TEXT = 'Declaro que soy titular de la cuenta bancaria cuyo certificado se solicitó en la opción anterior, para que se deposite allí el retiro y que no puedo asistir al Consulado a realizar mi solicitud.';
export const MANDATE_STATEMENT_TEXT = 'Declaro que no puedo asistir al consulado a realizar mi solicitud de retiro.';

export const BANK_CODES_INVALID_LENGTH_ERROR = {
  aba: `Código inválido, debe tener ${BANK_CODE_TYPES_MIN_LENGTH.aba} dígitos`,
  bsb: `Código inválido, debe ser igual o menor a ${BANK_CODE_TYPES_MAX_LENGTH.bsb} dígitos`,
  iban: `Código inválido, debe ser entre ${BANK_CODE_TYPES_MIN_LENGTH.iban} y ${BANK_CODE_TYPES_MAX_LENGTH.iban} dígitos`,
  swift: `Código inválido, debe ser entre ${BANK_CODE_TYPES_MIN_LENGTH.swift} y ${BANK_CODE_TYPES_MAX_LENGTH.swift} dígitos`,
};
export const IBAN_CODE_SPECIFIC_LENGTH_ERROR = `Código inválido, debe tener $length dígitos`;
export const COUNTRY_CODE_ERROR = 'Código inválido, no corresponde al país de destino';
export const IBAN_CODE_SPECIFIC_MOD97_ERROR = 'Código inválido, verifique dígitos y mayúsculas';

export const ACCOUNT_NUMBER_SPECIFIC_LENGTH_ERROR = `El número de cuenta debe tener $length dígitos`;
export const ACCOUNT_NUMBER_GENERIC_LENGTH_ERROR = `El número de cuenta no puede exceder los $length dígitos`;

export const PASSWORD_AND_SERIAL_NUMBER_INVALID_TEXT = {
  title: 'No se ha podido validar tu Clave de Acceso ni el Número de Serie de tu cédula',
  text: 'De todas formas, puedes ingresar tu solicitud y se te contactará para agendar una videollamada'
};
export const PASSWORD_INVALID_AND_SERIAL_NUMBER_VALID_TEXT = {
  title: 'Tu Número de Serie se validó correctamente, no así tu Clave de Acceso',
  text: 'Se te pedirá adjuntar una foto de tu rostro al ingresar el formulario'
};
export const PASSWORD_VALID_AND_SERIAL_NUMBER_INVALID_TEXT = {
  title: 'Tu Clave de Acceso se validó correctamente, no así tu Número de Serie',
  text: 'De todas formas, puedes ingresar tu solicitud'
};
export const PASSWORD_AND_SERIAL_NUMBER_VALID_TEXT = {
  title: 'Clave de Acceso y Número de Serie validados correctamente',
  text: ''
};

export const PUBLIC_AUTHORITY_QUESTION = '¿Eres una autoridad pública de las señaladas en el artículo 38 bis?';

export const BENEFICIARY_PUBLIC_AUTHORITY_QUESTION = '¿El beneficiario es una autoridad pública de las señaladas en el artículo 38 bis?';

export const VERIFY_AFP_CLIENT_BUTTON = 'Verificar que soy parte de <strong>AFP PlanVital</strong>';

export const BENEFICIARY_VERIFY_AFP_CLIENT_BUTTON = 'Verificar que soy parte de <strong>AFP PlanVital</strong>';

export const MIN_DATE = new Date('1900-01-01T00:00');

export const TITLE_MODAL_CONFIRMATION = 'Confirma tu solicitud de retiro de fondos';

export const REQUEST_MODAL = {
  title: 'Disponible próximamente',
  firstBtnText: 'Aceptar',
  iconName: 'i-alert'
} as ModalData;

export const LOGIN_FLOW = {
  AFFILIATE: 'Affiliate',
  BENEFICIARY: 'Beneficiary',
  FOREIGN: 'Foreign',
};

export const PERSONAL_INFO_TITLE = {
  AFFILIATE: 'Datos Personales',
  BENEFICIARY: 'Datos de quien recibe el pago de pensión'
};

export const WITHDRAWAL_AMOUNT_TITLE = {
  NATIONAL: '¿Deseas efectuar el retiro de tu monto máximo legal? (100%)',
  FOREIGN: '¿Quieres retirar tu monto máximo?'
};

export const RESTRICTED_PAYMENT_COUNTRY_ERROR = 'Las transferencias hacia este país están siendo restringidas por los bancos';
export const CARDHOLDER_NAME_GLOSS = 'Debe corresponder al que se encuentra registrado en su banco';
export const CURRENCY_GLOSS = 'Debe corresponder a la que considera su banco de destino';
export const BANK_DNI_GLOSS = 'Documento nacional de identificación asociado a tu cuenta bancaria en el extranjero';
export const RESTRICTED_COUNTRIES_DISCLAIMER = 'Las transferencias hacia Venezuela y Cuba están siendo restringidas por los bancos.'
  + ' Se sugiere realizar el retiro de fondos hacia una cuenta CAV, hacia un banco de otro país, o retirar a través de un mandatario.';

export const DEFINITELY_UNAVAILABLE_WITHDRAWALS = [
  GENERIC_WITHDRAWAL_NUMBER.FIRST,
  GENERIC_WITHDRAWAL_NUMBER.SECOND,
];
