import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[digitsOnly]'
})
export class DigitsOnlyDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    this._el.nativeElement.value = this._el.nativeElement.value.length <= 9 ?
    this._el.nativeElement.value : this._el.nativeElement.value.substr(0, 8);
  }
}
