import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from '@environment';
import { LoadingService } from '@providers/loading/loading.service';
import { GoogleAnalyticsService } from '@providers/google-analytics/google-analytics.service';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public showLoading = false;
  public VERSION = version;

  constructor(
    private loadingService: LoadingService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private meta: Meta,
  ) { }

  public ngOnInit() {
    this.loadingService.getLoadingRequestIntercepted().subscribe((showLoading) => setTimeout(() => this.showLoading = showLoading, 0));
    this.addRecaptchaScript();
    this.meta.addTag({ name: 'Version', content: version });
  }

  private addRecaptchaScript() {
    const recaptchaSrc = (document: any, s: string, id: string, obj: any) => {
      // tslint:disable-next-line: prefer-const
      let js: any, fjs = document.getElementsByTagName(s)[0];
      if (document.getElementById(id)) { return; }
      js = document.createElement(s); js.id = id;
      js.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.siteKey}`;
      fjs.parentNode.insertBefore(js, fjs);
    };
    recaptchaSrc(document, 'script', 'recaptcha-jssdk', this);
  }
}
