import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  OTHER_COUNTRY_NAME,
  WITHDRAWAL_BANK_CODE_TYPES,
  WITHDRAWAL_COUNTRY_ARRAY
} from '@constants/country.constants';
import * as WITHDRAWAL from '@constants/funds-withdrawal.constant';
import { CURENCY_ARRAY } from '@constants/general.constant';
import { ALPHANUMERIC_PATTERN, ONLY_NUMBERS_PATTERN, VALID_INPUT_PATTERN } from '@constants/regex.constant';
import { ForeignPaymentInfo } from '@interfaces/foreign-payment-info.interface';
import { Utils } from '@utils/utils';
import { restrictedCountryValidator } from '@validators/country.validator';
import { ibanValidator } from '@validators/iban.validator';
import { swiftValidator } from '@validators/swift.validator';
import { mustMatch } from '@validators/must-match.validator';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-foreign-payment-options',
  templateUrl: './foreign-payment-options.component.html',
  styleUrls: ['./foreign-payment-options.component.scss'],
})

export class ForeignPaymentOptionsComponent implements OnInit {
  @Output() nextStep = new EventEmitter();
  @Output() goToBack = new EventEmitter();

  public form: FormGroup;
  public currencies = CURENCY_ARRAY;
  public countries = WITHDRAWAL_COUNTRY_ARRAY;
  public accountNumberMaxLength = WITHDRAWAL.FOREIGN_ACCOUNT_NUMBER_MAX_LENGTH;
  public accountNumberPattern = ONLY_NUMBERS_PATTERN;
  public namesMaxLength = WITHDRAWAL.NAMES_MAX_LENGTH;
  public lastnamesMaxLength = WITHDRAWAL.LASTNAMES_MAX_LENGTH;
  public bsbMaxLength = WITHDRAWAL.BANK_CODE_TYPE_BSB_MAX_LENGTH;
  public cardholderNameGloss = WITHDRAWAL.CARDHOLDER_NAME_GLOSS;
  public bankDniGloss = WITHDRAWAL.BANK_DNI_GLOSS;
  public currencyGloss = WITHDRAWAL.CURRENCY_GLOSS;
  public restrictedCountriesDisclaimer = WITHDRAWAL.RESTRICTED_COUNTRIES_DISCLAIMER;
  public bankCodesMinLength = WITHDRAWAL.BANK_CODE_TYPES_MIN_LENGTH;
  public bankCodesMaxLength = WITHDRAWAL.BANK_CODE_TYPES_MAX_LENGTH;
  public bankCodeLabels: Array<WITHDRAWAL_BANK_CODE_TYPES> = [];
  public selectedCountry;

  public get nameOwner(): AbstractControl { return this.form.get('nameOwner'); }
  public get bankDni(): AbstractControl { return this.form.get('bankDni'); }
  public get bankName(): AbstractControl { return this.form.get('bankName'); }
  public get bankAddress(): AbstractControl { return this.form.get('bankAddress'); }
  public get country(): AbstractControl { return this.form.get('country'); }
  public get otherCountry(): AbstractControl { return this.form.get('otherCountry'); }
  public get city(): AbstractControl { return this.form.get('city'); }
  public get accountNumber(): AbstractControl { return this.form.get('accountNumber'); }
  public get repeatAccountNumber(): AbstractControl { return this.form.get('repeatAccountNumber'); }
  public get currency(): AbstractControl { return this.form.get('currency'); }
  public get bankCodes(): FormGroup { return this.form.get('bankCodes') as FormGroup; }

  constructor(
    private utils: Utils,
    private formBuilder: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.form = this.createForm();
    this.accountNumberSubscription();
    this.countrySubscription();
    this.setCountryValidators(this.country);
  }

  private createForm(): FormGroup {
    const form = this.formBuilder.group(
      {
        nameOwner: [null, [Validators.required, Validators.maxLength(this.namesMaxLength), Validators.pattern(VALID_INPUT_PATTERN)]],
        bankDni: [null, [Validators.required]],
        bankName: [null, Validators.required],
        bankAddress: [null, Validators.required],
        country: [null, Validators.required],
        otherCountry: [null],
        city: [null, Validators.required],
        accountNumber: [null, this.generateAlfanumericValidator(WITHDRAWAL.FOREIGN_ACCOUNT_NUMBER_MAX_LENGTH)],
        repeatAccountNumber: [null, this.generateAlfanumericValidator(WITHDRAWAL.FOREIGN_ACCOUNT_NUMBER_MAX_LENGTH)],
        currency: ['USD', Validators.required],
        bankCodes: this.formBuilder.group({
          swift: [null],
          iban: [null],
          bsb: [null],
          aba: [null]
        }),
        authorize: [false, Validators.requiredTrue],
        personalAccountConsent: [false, Validators.requiredTrue],
      },
      {
        validator: [
          mustMatch('accountNumber', 'repeatAccountNumber'),
        ],
      }
    );

    return form;
  }

  private generateAlfanumericValidator(maxLength: number): Array<Validators> {
    return [Validators.required, Validators.maxLength(maxLength), Validators.pattern(ALPHANUMERIC_PATTERN)];
  }

  private accountNumberSubscription(): void {
    this.accountNumber.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((accountNumber: string) => {
        if (!accountNumber) { return; }
      });
  }

  private countrySubscription(): void {
    this.country.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe(
        (countryCodeSelected) => {
          this.selectedCountry = this.countries.find(country => country.name === countryCodeSelected);
          this.bankCodeLabels = this.selectedCountry.bankCodeType;
          this.setBankCodeValidators(this.bankCodeLabels);
          this.setAccountNumberValidators();
          Object.keys(this.bankCodes.controls).forEach((key) => {
            this.bankCodes.get(key).setValue(null);
          });
          this.otherCountry.setValue('');
          this.selectedCountry ? this.bankCodes.enable() : this.bankCodes.disable();
        });
  }

  public patternError(formControlName: string): boolean {
    return this.form.get(formControlName).hasError('pattern');
  }

  public confirm() {
    if (this.form.invalid) { return; }
    const params = this.getParsedData();
    this.nextStep.emit(params);
  }

  private getParsedData(): ForeignPaymentInfo {
    const data = this.form.getRawValue();
    const { country, otherCountry } = data;
    if (country && otherCountry && country.trim() === WITHDRAWAL.OTHER_COUNTRY_CODE.trim()) {
      data.country = otherCountry;
    }
    delete data.otherCountry;
    return data;
  }

  private setBankCodeValidators(bankCodeTypes: WITHDRAWAL_BANK_CODE_TYPES[]): void {
    Object.keys(this.bankCodes.controls).forEach((key) => {
      this.bankCodes.get(key).clearValidators();
      this.bankCodes.get(key).markAsUntouched();
    });
    this.otherCountry.clearValidators();
    this.otherCountry.markAsUntouched();
    const countryCode = this.selectedCountry.code;
    this.setBankCodeTypeValidators(bankCodeTypes, countryCode);
    if (this.isOtherCountrySelected()) {
      this.setCountryValidators(this.otherCountry);
    }
  }

  private setBankCodeTypeValidators(bankCodeTypes: WITHDRAWAL_BANK_CODE_TYPES[], countryCode: string): void {
    bankCodeTypes.forEach((bankCodeType) => {
      const countryPattern = this.getCodeBankRegExp(bankCodeType, countryCode);
      if (bankCodeType !== WITHDRAWAL_BANK_CODE_TYPES.iban) {
        let validators = [
          Validators.required,
          Validators.minLength(this.bankCodesMinLength[bankCodeType]),
          Validators.maxLength(this.bankCodesMaxLength[bankCodeType]),
        ];
        if (bankCodeType === WITHDRAWAL_BANK_CODE_TYPES.swift) {
          validators = validators.concat([
            swiftValidator,
            Validators.pattern(countryPattern)
          ]);
        }
        this.bankCodes.get(bankCodeType).setValidators(validators);
      } else {
        const codeLength = this.selectedCountry.ibanLength;
        this.bankCodes.get(bankCodeType).setValidators([
          Validators.required,
          Validators.minLength(codeLength ? codeLength : this.bankCodesMinLength[bankCodeType]),
          Validators.maxLength(codeLength ? codeLength : this.bankCodesMaxLength[bankCodeType]),
          Validators.pattern(countryPattern),
          ibanValidator
        ]);
      }
    });
  }

  private getCodeBankRegExp(bankCodeType: WITHDRAWAL_BANK_CODE_TYPES, countryCode: string) {
    if (bankCodeType === WITHDRAWAL_BANK_CODE_TYPES.iban) {
      return new RegExp(`^${countryCode}`);
    } else if (bankCodeType === WITHDRAWAL_BANK_CODE_TYPES.swift) {
      return new RegExp(`^.{4}${countryCode}`);
    }
  }

  public paddingLeftZeros(bankCodeLabel): void {
    const bsbControl = this.bankCodes.get(WITHDRAWAL_BANK_CODE_TYPES.bsb);
    if (bankCodeLabel === WITHDRAWAL_BANK_CODE_TYPES.bsb && bsbControl.value) {
      bsbControl.setValue(this.utils.getBankCodeBsb(bsbControl.value));
    }
  }

  public isOtherCountrySelected(): boolean {
    return this.country.value === OTHER_COUNTRY_NAME;
  }

  public setAccountNumberValidators() {
    if (!this.selectedCountry) {
      this.accountNumber.clearValidators();
    } else if (this.selectedCountry.accountNumberLength) {
      this.accountNumber.setValidators([
        Validators.required,
        Validators.maxLength(this.selectedCountry.accountNumberLength),
        Validators.minLength(this.selectedCountry.accountNumberLength),
      ]);
    } else {
      this.accountNumber.setValidators([
        Validators.required,
        Validators.maxLength(this.accountNumberMaxLength)
      ]);
    }
    this.accountNumber.updateValueAndValidity();
  }

  public getAccountNumberErrorMessage(): string {
    if (!this.selectedCountry) {
      this.selectedCountry = this.countries.find(country => country.name === this.country.value);
    }
    if (this.accountNumber.hasError('minlength') || this.accountNumber.hasError('maxlength')) {
      const exactLength = this.selectedCountry.accountNumberLength;
      if (exactLength) {
        return WITHDRAWAL.ACCOUNT_NUMBER_SPECIFIC_LENGTH_ERROR.replace('$length', exactLength);
      } else {
        return WITHDRAWAL.ACCOUNT_NUMBER_GENERIC_LENGTH_ERROR.replace('$length', String(this.accountNumberMaxLength));
      }
    }
  }

  public getBankCodeErrorMessage(bankCodeLabel): string {
    const bankCodeControl = this.bankCodes.get(bankCodeLabel);
    if (bankCodeControl.hasError('required')) {
      return WITHDRAWAL.BANK_CODE_REQUIRED_FIELD_ERROR;
    } else if (bankCodeControl.hasError('minlength') || bankCodeControl.hasError('maxlength')) {
      const ibanLength = this.selectedCountry.ibanLength;
      if (bankCodeLabel === WITHDRAWAL_BANK_CODE_TYPES.iban && ibanLength) {
        return WITHDRAWAL.IBAN_CODE_SPECIFIC_LENGTH_ERROR.replace('$length', ibanLength);
      } else {
        return WITHDRAWAL.BANK_CODES_INVALID_LENGTH_ERROR[bankCodeLabel];
      }
    } else if (bankCodeControl.hasError('pattern')) {
      return WITHDRAWAL.COUNTRY_CODE_ERROR;
    } else if (bankCodeControl.hasError('validIban') || bankCodeControl.hasError('validSwift')) {
      return WITHDRAWAL.IBAN_CODE_SPECIFIC_MOD97_ERROR;
    }
  }

  public getBankCodePlaceholder(bankCodeLabel): string {
    if (bankCodeLabel !== 'iban') { return ''; }
    return this.selectedCountry.example ? this.selectedCountry.example : '';
  }

  public previousStep(): void {
    this.goToBack.emit();
  }

  public getCountryErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) { return WITHDRAWAL.BANK_CODE_REQUIRED_FIELD_ERROR; }
    if (control.hasError('restrictedCountry')) { return WITHDRAWAL.RESTRICTED_PAYMENT_COUNTRY_ERROR; }
  }

  private setCountryValidators(control: AbstractControl): void {
    control.setValidators([
      Validators.required,
      restrictedCountryValidator
    ]);
  }
}
