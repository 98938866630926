import { GENERIC_WITHDRAWAL_NUMBER } from '@constants/funds-withdrawal.constant';
import { WITHDRAWAL_INFORMATION } from '@constants/pages-content/home.constant';
import { GeneralInfoItem, HomeItem, HomeMoreInformation, WithdrawalFormInfoItem, WithdrawalItem } from '@interfaces/cms.interface';
import { BannerInfo, FaqInfo, GeneralInfo, MoreInformationInfo, WithdrawalFormInfo } from '@interfaces/components.interface';
import { WithdrawalType } from '@interfaces/funds-withdrawal.interface';

export class CmsUtils {
  public mapGeneralInfo(generalInfoItem: GeneralInfoItem, language: string): GeneralInfo {
    const { horarios_call_center = null } = generalInfoItem.traducciones.find(item => item.idioma === language) || {};
    const { titulo = null, bajada = null, boton = null } = generalInfoItem.flotante.find(item => item.idioma === language) || {};
    return {
      callCenterHours: horarios_call_center,
      loginModalActive: generalInfoItem.modal_login,
      floatingButton: {
        show: generalInfoItem.mostrar_flotante,
        title: titulo,
        description: bajada,
        button: {
          label: boton,
          route: generalInfoItem.flotante_url,
        },
      },
    };
  }

  public mapFaqInfo(withdrawalItems: Array<WithdrawalItem>, withdrawalNumber: WithdrawalType): Array<FaqInfo> {
    const withdrawalItem: WithdrawalItem = withdrawalItems.find(item => item.codigo === withdrawalNumber);
    if (withdrawalItem) {
      const faqs = withdrawalItem.faqs.map(faq => {
        const { pregunta, respuesta } = faq.ret_faqs_id;
        return {
          title: pregunta,
          description: respuesta
        };
      });
      return faqs;
    }
  }

  public mapLandingFaqs(withdrawalItems: Array<WithdrawalItem>, withdrawalNumber: WithdrawalType): Array<FaqInfo> {
    const withdrawalItem: WithdrawalItem = withdrawalItems.find(item => item.codigo === withdrawalNumber);
    if (withdrawalItem) {
      const faqs = withdrawalItem['landing_faqs'].map(faq => {
        const { pregunta, respuesta } = faq.ret_faqs_id;
        return {
          title: pregunta,
          description: respuesta
        };
      });
      return faqs;
    }
  }

  public mapWithdrawalMoreInformation(withdrawalItems: Array<WithdrawalItem>,
    withdrawalNumber: WithdrawalType): Array<MoreInformationInfo> {
    const withdrawalItem: WithdrawalItem = withdrawalItems.find(item => item.codigo === withdrawalNumber);
    if (withdrawalItem) {
      const moreInformations = withdrawalItem.mas_informaciones.map(moreInformation => {
        const { tab, titulo, cuerpo, imagen, boton_texto, boton_link } = moreInformation.ret_mas_informacion_id;
        return {
          label: tab,
          title: titulo,
          body: cuerpo,
          img: imagen.data,
          buttonLabel: boton_texto,
          buttonUrl: boton_link,
        };
      });
      return moreInformations;
    }
  }

  public mapHomeMoreInformation(homeItem: HomeMoreInformation): Array<MoreInformationInfo> {
    const moreInformations = homeItem.mas_informaciones.map(moreInformation => {
      const { tab, titulo, cuerpo, imagen, boton_texto, boton_link } = moreInformation.ret_mas_informacion_id;
      return {
        label: tab,
        title: titulo,
        body: cuerpo,
        img: imagen.data,
        buttonLabel: boton_texto,
        buttonUrl: boton_link,
      };
    });
    return moreInformations;
  }

  public mapBannerInfo(withdrawalItems): BannerInfo[] {
    const withdrawalsBannerInfo = withdrawalItems.map((withdrawalItem) => {
      const { titulo_landing, titulo_faqs, banner, codigo } = withdrawalItem;
      return {
        code: codigo,
        image: {
          url: banner.data.full_url,
          alt: '',
        },
        icon: 'withdrawal',
        style: WITHDRAWAL_INFORMATION[codigo].styleClass,
        landingTitle: titulo_landing,
        faqsTitle: titulo_faqs,
        firstLine: titulo_landing,
      };
    });
    return withdrawalsBannerInfo;
  }

  public mapHomeBannerInfo(homeItem: HomeItem): BannerInfo {
    const { titulo_landing, banner } = homeItem;
    return {
      image: {
        url: banner.data.full_url,
        alt: '',
      },
      icon: 'withdrawal',
      style: WITHDRAWAL_INFORMATION[GENERIC_WITHDRAWAL_NUMBER.FIRST].styleClass,
      firstLine: titulo_landing,
    };
  }

  public mapWithdrawalFormTextInfo(formInfoItems: WithdrawalFormInfoItem[], withdrawalNumber: WithdrawalType): WithdrawalFormInfo {
    const formInfoItem = formInfoItems.find(item => item.codigo_retiro === withdrawalNumber);
    if (formInfoItem) {
      const {
        codigo_retiro, mensaje_autoridad_afiliado, mensaje_autoridad_beneficiario, alerta_medio_de_pago, modal_alertas, alerta_cuenta_2
      } = formInfoItem;
      const modalAlerts = modal_alertas.map(alerta => {
        return { text: alerta.ret_textos_formulario_id.texto };
      });
      return {
        withdrawalCode: codigo_retiro as WithdrawalType,
        affiliateAuthorityMessage: { text: mensaje_autoridad_afiliado.texto },
        beneficiaryAuthorityMessage: { text: mensaje_autoridad_beneficiario.texto },
        paymentMethodAlert: { text: alerta_medio_de_pago.texto },
        secondaryAccountAlert: { text: alerta_cuenta_2.texto },
        modalAlerts
      };
    }
  }
}
