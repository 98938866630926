import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client/http-client.interceptor';
import { ContactReasonItem, ContactState } from '@interfaces/contact-form.interface';
import { ErrorUtils } from '@utils/error.utils';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CONTACT_MOCKUP, GET_REASONS_MOCKUP } from './mocks/contact.mock';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private baseUrl = `${environment.proxyApiUrl}contact/`;

  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils,
  ) { }

  public contact(data: FormData): Observable<ContactState> {
    const url = `${this.baseUrl}`;
    return this.http.post(url, data, CONTACT_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getReasons(): Observable<Array<ContactReasonItem>> {
    const url = `${this.baseUrl}reasons/`;
    return this.http.get(url, GET_REASONS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }
}
