import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {


  constructor(
    private router: Router,
  ) {
    this.subscribeUrl();
  }

  public registerPageView(page: string) {
    gtag('config', environment.googleAnalyticsId, { page_path: `${page}` });
  }

  private subscribeUrl() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.registerPageView(event.urlAfterRedirects));
  }
}
