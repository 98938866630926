import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormText } from '@interfaces/components.interface';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<InformationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { infoModalTexts: FormText[] }
  ) {}

  public closeDialog() {
    this.dialogRef.close();
  }

}
