// @ts-ignore
export const CONTACT_CENTER = {
  NUMBER: 6005000800,
  MASK: '600 5000 800',
};

export const PREFIX_VIRTUAL_ACCOUNT_BCI = '7770';
export const PREFIX_VIRTUAL_ACCOUNT_SANTANDER = '710';
export const PREFIX_VIRTUAL_ACCOUNT_RIPLEY = '404';

export const BCI_CODE = '016';
export const RIPLEY_CODE = '053';
export const SANTANDER_CODE = '037';

export const VIRTUAL_ACCOUNTS_BANKS = {
  [BCI_CODE]: PREFIX_VIRTUAL_ACCOUNT_BCI,
  [RIPLEY_CODE]: PREFIX_VIRTUAL_ACCOUNT_RIPLEY,
  [SANTANDER_CODE]: PREFIX_VIRTUAL_ACCOUNT_SANTANDER,
};

export const COMPENSATION_BOX_CODE = '303';
export const STATE_BANK_CODE = '012';
export const COOPEUCH_CODE = '672';

export const VISTA_ACCOUNT_ID = 2;
export const RUT_ACCOUNT_ID = 4;

export const CURENCY_ARRAY = [
  'AED',
  'AOA',
  'ARS',
  'AUD',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BOB',
  'BRL',
  'BSD',
  'BWP',
  'BZD',
  'CAD',
  'CHF',
  'CNY',
  'COP',
  'CRC',
  'CUC',
  'CZK',
  'DKK',
  'DOP',
  'DOP',
  'DZD',
  'EGP',
  'ETB',
  'EUR',
  'FJD',
  'GTQ',
  'GYD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KHR',
  'KRW',
  'KWD',
  'KZT',
  'LBP',
  'LKR',
  'LTL',
  'LYD',
  'MAD',
  'MNT',
  'MRU',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'SRD',
  'SVC',
  'SYP',
  'THB',
  'TND',
  'TRY',
  'TTD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'VES',
  'VND',
  'XAF',
  'XCD',
  'XOF',
  'ZWL',
];
