export const MAX_AMOUNT_SECOND_WITHDRAWAL = {
  title: '¿Cuánto dinero se podrá retirar?',
  description: 'Depende de cuánto hayas ahorrado en tu Cuenta Obligatoria y tu Cuenta de Afiliado Voluntario, si tienes.',
  table: {
    headerRow: [
      { column: 'Saldo en cuenta'},
      { column: 'Monto máximo de retiro'}
    ],
    bodyRow: [
      {
        row: [
          { column: 'Menos de 1 millón de pesos' },
          { column: '100% de mis ahorros' }
        ]
      },
      {
        row:  [
          { column: 'Entre 1 y 10 millones de pesos' },
          { column: '$1.000.000' }
        ]
      },
      {
        row: [
          { column: 'Entre 10 y 43 millones de pesos' },
          { column: '10% de mis ahorros' }
        ]
      },
      {
        row: [
          { column: 'Más de 43 millones de pesos' },
          { column: '$4.300.000' }
        ]
      }
    ]
  },
  disclaimer: 'Valores aproximados y calculados con el valor de la UF vigente al 27 de julio 2020, de $28.671,14'
};

export const MAX_AMOUNT_THIRD_WITHDRAWAL = {
  title: '¿Cuánto dinero se podrá retirar?',
  description: 'Depende de cuánto hayas ahorrado en tu Cuenta Obligatoria y tu Cuenta de Afiliado Voluntario, si tienes.<br><br>\
  El máximo de retiro es equivalente a 150 UF (alrededor de $4.422.000) y como mínimo 35 UF ($1.031.800).\
  Si el 10% de los ahorros es menor a 35 UF, se podrá retirar el total de los fondos.',
  table: {
    headerRow: [
      { column: 'Saldo en tu cuenta AFP'},
      { column: 'Máximo que podrás retirar'},
      { column: 'Plazo para el pago'}
    ],
    bodyRow: [
      {
        row: [
          { column: 'Hasta 35 UF (hasta $1.031.800)' },
          { column: 'Hasta el total de tus ahorros' },
          { column: '15 días hábiles para afiliados a las AFP' }
        ]
      },
      {
        row:  [
          { column: 'Entre 35 UF y 350 UF (de $1.031.800 a $10.318.000)' },
          { column: 'Máximo 35 UF (hasta $1.031.800)' },
          { column: '15 días hábiles para afiliados a las AFP' }
        ]
      },
      {
        row: [
          { column: 'Entre 350 UF y 1500 UF (de $10.318.000 a $44.220.000)' },
          { column: 'Hasta el 10% de tus ahorros' },
          { column: '15 días hábiles para afiliados a las AFP' }
        ]
      },
      {
        row: [
          { column: 'Sobre 1500 UF (desde $44.220.000)' },
          { column: 'Máximo 150 UF ($4.422.000)' },
          { column: '15 días hábiles para afiliados a las AFP' }
        ]
      }
    ]
  },
  disclaimer: 'Valores aproximados y calculados con el valor de la UF vigente al 3 de mayo 2021, de $29.505,91'
};
