export const enum WITHDRAWAL_BANK_CODE_TYPES {
  swift = 'swift',
  iban = 'iban',
  aba = 'aba',
  bsb = 'bsb',
}

export const OTHER_COUNTRY_NAME = 'OTRO';

export const COUNTRY_FLAGS_URL = 'https://flagcdn.com/48x36/';

export const WITHDRAWAL_COUNTRY_ARRAY = [
  {
    name: 'ALBANIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'AL35202111090000000001234567',
    code: 'AL',
  },
  {
    name: 'ALEMANIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'DE91100000000123456789',
    code: 'DE',
  },
  {
    name: 'ANDORRA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'AD1400080001001234567890',
    code: 'AD',
  },
  {
    name: 'ARABIA SAUDITA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'SA4420000001234567891234',
    code: 'SA',
  },
  {
    name: 'ARGENTINA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift
    ],
    code: 'AR',
  },
  {
    name: 'AUSTRALIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.bsb
    ],
    code: 'AU',
  },
  {
    name: 'AUSTRIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 20,
    example: 'AT483200000012345864',
    code: 'AT',
  },
  {
    name: 'AZERBAIYAN',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'AZ96AZEJ00000000001234567890',
    code: 'AZ',
  },
  {
    name: 'BAHREIN',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'BH02CITI00001077181611',
    code: 'BH',
  },
  {
    name: 'BELARUS',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'BY86AKBB10100000002966000000',
    code: 'BY',
  },
  {
    name: 'BELGICA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 16,
    example: 'BE71096123456769',
    code: 'BE',
  },
  {
    name: 'BOSNIA Y HERZEGOVINA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 20,
    example: 'BA275680000123456789',
    code: 'BA',
  },
  {
    name: 'BOLIVIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift
    ],
    code: 'BO',
  },
  {
    name: 'BRASIL',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 29,
    example: 'BR1500000000000010932840814P2',
    code: 'BR',
  },
  {
    name: 'BULGARIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'BG18RZBB91550123456789',
    code: 'BG',
  },
  {
    name: 'CANADA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'CA',
  },
  {
    name: 'CHEQUIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'CZ5508000000001234567899',
    code: 'CZ',
  },
  {
    name: 'CHIPRE',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'CY21002001950000357001234567',
    code: 'CY',
  },
  {
    name: 'COLOMBIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'CO',
  },
  {
    name: 'COSTA RICA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'CR37012600000123456789',
    code: 'CR',
  },
  {
    name: 'CROACIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 21,
    example: 'HR1723600001101234565',
    code: 'HR',
  },
  {
    name: 'DINAMARCA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 18,
    example: 'DK9520000123456789',
    code: 'DK',
  },
  {
    name: 'ECUADOR',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'EC',
  },
  {
    name: 'EL SALVADOR',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'SV43ACAT00000000000000123123',
    code: 'SV',
  },
  {
    name: 'EMIRATOS ÁRABES UNIDOS',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 23,
    example: 'AE460090000000123456789',
    code: 'AE',
  },
  {
    name: 'ESLOVENIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 19,
    example: 'SI56192001234567892',
    code: 'SI',
  },
  {
    name: 'ESPAÑA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'ES1000492352082414205416',
    code: 'ES',
  },
  {
    name: 'ESTONIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 20,
    example: 'EE471000001020145685',
    code: 'EE',
  },
  {
    name: 'ESTADOS UNIDOS',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.aba,
    ],
    code: 'US',
  },
  {
    name: 'FILIPINAS',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'PH',
  },
  {
    name: 'FINLANDIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 18,
    example: 'FI1410093000123458',
    code: 'FI',
  },
  {
    name: 'FRANCIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 27,
    example: 'FR7630006000011234567890189',
    code: 'FR',
  },
  {
    name: 'GEORGIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'GE60NB0000000123456789',
    code: 'GE',
  },
  {
    name: 'GIBRALTAR',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 23,
    example: 'GI04BARC000001234567890',
    code: 'GI',
  },
  {
    name: 'GRAN BRETAÑA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'GB98MIDL07009312345678',
    code: 'GB',
  },
  {
    name: 'GRECIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 27,
    example: 'GR9608100010000001234567890',
    code: 'GR',
  },
  {
    name: 'GROENLANDIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 18,
    example: 'GL8964710123456789',
    code: 'GL',
  },
  {
    name: 'GUATEMALA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'GT20AGRO00000000001234567890',
    code: 'GT',
  },
  {
    name: 'HONDURAS',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'HN',
  },
  {
    name: 'HUNGRÍA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'HU93116000060000000012345676',
    code: 'HU',
  },
  {
    name: 'INGLATERRA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'GB98MIDL07009312345678',
    code: 'GB',
  },
  {
    name: 'IRAK',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 23,
    example: 'IQ20CBIQ861800101010500',
    code: 'IQ',
  },
  {
    name: 'IRLANDA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'IE64IRCE92050112345678',
    code: 'IE',
  },
  {
    name: 'ISLANDIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 26,
    example: 'IS030001121234561234567890',
    code: 'IS',
  },
  {
    name: 'ISLAS FEROE',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 18,
    example: 'FO9264600123456789',
    code: 'FO',
  },
  {
    name: 'ISLAS VÍRGENES BRITÁNICAS',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'VG21PACG0000000123456789',
    code: 'VG',
  },
  {
    name: 'ISRAEL',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 23,
    example: 'IL170108000000012612345',
    code: 'IL',
  },
  {
    name: 'ITALIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 27,
    example: 'IT60X0542811101000000123456',
    code: 'IT',
  },
  {
    name: 'JORDANIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 30,
    example: 'JO71CBJO0000000000001234567890',
    code: 'JO',
  },
  {
    name: 'KATAR',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 29,
    example: 'QA54QNBA000000000000693123456',
    code: 'QA',
  },
  {
    name: 'KAZAJSTÁN',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 20,
    example: 'KZ563190000012344567',
    code: 'KZ',
  },
  {
    name: 'KOSOVO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 20,
    example: 'XK051212012345678906',
    code: 'XK',
  },
  {
    name: 'KUWAIT',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 30,
    example: 'KW81CBKU0000000000001234560101',
    code: 'KW',
  },
  {
    name: 'LETONIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 21,
    example: 'LV97HABA0012345678910',
    code: 'LV',
  },
  {
    name: 'LÍBANO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'LB92000700000000123123456123',
    code: 'LB',
  },
  {
    name: 'LIECHTENSTEIN',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 21,
    example: 'LI7408806123456789012',
    code: 'LI',
  },
  {
    name: 'LITUANIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 20,
    example: 'LT601010012345678901',
    code: 'LT',
  },
  {
    name: 'LUXEMBURGO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 20,
    example: 'LU120010001234567891',
    code: 'LU',
  },
  {
    name: 'MACEDONIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 19,
    example: 'MK07200002785123453',
    code: 'MK',
  },
  {
    name: 'MALTA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 31,
    example: 'MT31MALT01100000000000000000123',
    code: 'MT',
  },
  {
    name: 'MAURICIO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 30,
    example: 'MU43BOMM0101123456789101000MUR',
    code: 'MU',
  },
  {
    name: 'MAURITANIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 27,
    example: 'MR1300020001010000123456753',
    code: 'MR',
  },
  {
    name: 'MOLDAVIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'MD21EX000000000001234567',
    code: 'MD',
  },
  {
    name: 'MÓNACO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 27,
    example: 'MC5810096180790123456789085',
    code: 'MC',
  },
  {
    name: 'MONTENEGRO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'ME25505000012345678951',
    code: 'ME',
  },
  {
    name: 'MEXICO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    accountNumberLength: 18,
    code: 'MX',
  },
  {
    name: 'NICARAGUA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'NI',
  },
  {
    name: 'NORUEGA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 15,
    example: 'NO8330001234567',
    code: 'NO',
  },
  {
    name: 'NUEVA ZELANDA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'NZ',
  },
  {
    name: 'PAISES BAJOS',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 18,
    example: 'NL02ABNA0123456789',
    code: 'NL',
  },
  {
    name: 'PARAGUAY',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'PY',
  },
  {
    name: 'PERU',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    accountNumberLength: 20,
    code: 'PE',
  },
  {
    name: 'PAKISTÁN',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'PK36SCBL0000001123456702',
    code: 'PK',
  },
  {
    name: 'PALESTINA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 29,
    example: 'PS92PALS000000000400123456702',
    code: 'PS',
  },
  {
    name: 'POLONIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'PL10105000997603123456789123',
    code: 'PL',
  },
  {
    name: 'PORTUGAL',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 25,
    example: 'PT50002700000001234567833',
    code: 'PT',
  },
  {
    name: 'REINO UNIDO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'GB98MIDL07009312345678',
    code: 'GB',
  },
  {
    name: 'REPUBLICA DOMINICANA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 28,
    example: 'DO22ACAU00000000000123456789',
    code: 'DO',
  },
  {
    name: 'REPUBLICA ESLOVACA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'SK8975000000000012345671',
    code: 'SK',
  },
  {
    name: 'RUMANIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'RO09BCYP0000001234567890',
    code: 'RO',
  },
  {
    name: 'SAN MARINO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 27,
    example: 'SM76P0854009812123456789123',
    code: 'SM',
  },
  {
    name: 'SANTA LUCÍA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 32,
    example: 'LC14BOSL123456789012345678901234',
    code: 'LC',
  },
  {
    name: 'SANTO TOME Y PRINCIPE',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 25,
    example: 'ST23000200000289355710148',
    code: 'ST',
  },
  {
    name: 'SERBIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 22,
    example: 'RS35105008123123123173',
    code: 'RS',
  },
  {
    name: 'SEYCHELLES',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 31,
    example: 'SC52BAHL01031234567890123456USD',
    code: 'SC',
  },
  {
    name: 'SUECIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'SE1412345678901234567890',
    code: 'SE',
  },
  {
    name: 'SUIZA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 21,
    example: 'CH5604835012345678009',
    code: 'CH',
  },
  {
    name: 'TIMOR ORIENTAL',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 23,
    example: 'TL380080012345678910157',
    code: 'TL',
  },
  {
    name: 'TUNEZ',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 24,
    example: 'TN4401000067123456789123',
    code: 'TN',
  },
  {
    name: 'TURQUIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 26,
    example: 'TR320010009999901234567890',
    code: 'TR',
  },
  {
    name: 'UCRANIA',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
      WITHDRAWAL_BANK_CODE_TYPES.iban,
    ],
    ibanLength: 29,
    example: 'UA903052992990004149123456789',
    code: 'UA',
  },
  {
    name: 'URUGUAY',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
    code: 'UY',
  },
  {
    name: 'OTRO',
    bankCodeType: [
      WITHDRAWAL_BANK_CODE_TYPES.swift,
    ],
  },
];

export const enum BANK_CODE_TYPES {
  swift = 'SWIFT',
  iban = 'IBAN',
  bsb = 'BSB',
  all = 'IBAN/BIC/SWIFT/BSB'
}

export const COUNTRY_ARRAY = [
  {
    name: 'ALEMANIA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'ARGENTINA',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'AUSTRALIA',
    bankCodeType: BANK_CODE_TYPES.bsb
   },
  {
    name: 'BELGICA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'BOLIVIA',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'BRASIL',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'BUENOS AIRES',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'BULGARIA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'CANADA',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'COLOMBIA',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'COSTA RICA',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'DINAMARCA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'ECUADOR',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'ESPAÑA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'ESTADOS UNIDOS',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'FILIPINAS',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'FINLANDIA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'FRANCIA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'GRECIA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'HONDURAS',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'INGLATERRA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'ISRAEL',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'ITALIA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'MEXICO',
    bankCodeType: BANK_CODE_TYPES.swift,
   },
  {
    name: 'NICARAGUA',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'NORUEGA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'NUEVA ZELANDA',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'PAISES BAJOS',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'PARAGUAY',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'PERU',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'POLONIA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'PORTUGAL',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'REINO UNIDO',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'REPUBLICA DOMINICANA',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'SUECIA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'SUIZA',
    bankCodeType: BANK_CODE_TYPES.iban
   },
  {
    name: 'URUGUAY',
    bankCodeType: BANK_CODE_TYPES.swift
   },
  {
    name: 'OTRO ',
    bankCodeType: BANK_CODE_TYPES.all
   },
];

export const RESTRICTED_PAYMENT_COUNTRIES = [
  'VENEZUELA',
  'CUBA'
];
