// tslint:disable: max-line-length
import {
  WithdrawalRequestDetails, WithdrawalRequestResponse
} from '@interfaces/funds-withdrawal.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const GET_REQUESTS_MOCKUP: Mockup = {
  success: [{
    response: {
      withdrawals: [
        {
          status: 'En curso',
          statusCode: 'I',
          date: '2020-08-01T07:30:46-04:00',
          folio: '1234567812345678',
          amount: 1000000,
          percentage: 100,
          accountType: '30',
          accountNumber: '70313030',
          accounts: [
            'CCO'
          ],
          bankCode: '12',
          paymentMethod: 'B',
          installments: [
            {
              quantity: 5.51,
              fund: 'C'
            },
            {
              quantity: 3.23,
              fund: 'E'
            },
            {
              quantity: 0.29,
              fund: 'C'
            }
          ],
          installmentsDate: '2020-08-05T07:30:46-04:00',
          reason: null,
          reasonDescription: null,
          whatToDo: null,
          paymentDate: null,
        },
        {
          status: 'Rechazada',
          statusCode: 'R',
          date: '2020-07-30T07:30:46-04:00',
          folio: '1234567812345678',
          amount: 1000000,
          percentage: 100,
          accountType: '01',
          accountNumber: '70313030',
          accounts: [
            'CCO'
          ],
          bankCode: '303',
          paymentMethod: 'C',
          installments: [
            {
              quantity: 5.51,
              fund: 'C'
            },
            {
              quantity: 3.23,
              fund: 'E'
            }
          ],
          installmentsDate: '2020-08-05T07:30:46-04:00',
          reason: 'Afiliado fallecido',
          reasonDescription: 'Porque el afiliado por el cual estás solicitando el retiro de fondos está fallecido.',
          whatToDo: 'Debido al motivo por el cual tu solicitud fue rechazada, no podrás volver a realizar una nueva solicitud. Cualquier duda puedes escribirnos a infovital@planvital.cl o en nuestras Redes Sociales.',
          paymentDate: null,
        },
        {
          status: 'Anulada',
          statusCode: 'N',
          date: '2020-08-08T10:30:46-04:00',
          folio: '1234567812345678',
          amount: 1000000,
          percentage: 100,
          accountType: null,
          accountNumber: null,
          accounts: [
            'CCO'
          ],
          bankCode: null,
          paymentMethod: 'T',
          installments: [
            {
              quantity: 5.51,
              fund: 'C'
            },
            {
              quantity: 3.23,
              fund: 'E'
            }
          ],
          installmentsDate: '2020-08-05T07:30:46-04:00',
          reason: 'Yo no hice la solicitud',
          reasonDescription: null,
          whatToDo: null,
          paymentDate: null,
        },
        {
          status: 'Aceptada',
          statusCode: 'A',
          date: '2020-07-29T07:30:46-04:00',
          folio: '1234567812345678',
          amount: 1000000,
          percentage: 100,
          accountType: '30',
          accountNumber: '70313030',
          accounts: [
            'CCO'
          ],
          bankCode: '12',
          paymentMethod: 'B',
          installments: [
            {
              quantity: 5.51,
              fund: 'C'
            },
            {
              quantity: 3.23,
              fund: 'E'
            },
            {
              quantity: 0.29,
              fund: 'C'
            }
          ],
          installmentsDate: '2020-08-05T07:30:46-04:00',
          reason: null,
          reasonDescription: null,
          whatToDo: null,
          paymentDate: '2020-09-01T07:30:46-04:00',
          isNullable: true,
        },
      ] as Array<WithdrawalRequestDetails>,
      debtType: '2',
      debtInformation: {
        courtRit: 'Lorem',
        courtlName: 'Ipsum',
        debtorRetentionIndex: 'S', // S or N
        retentionStatus: 'PE' // AC or PA or PE
      }
    } as WithdrawalRequestResponse
  }],
  failures: [{
    response: {
      statusCode: 404,
      messageError: 'Not Found',
      path: '/withdrawal/clients/1-9',
    }
  }]
};
