// tslint:disable: ban-types
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalData } from '@interfaces/modal-data.interface';

@Injectable()
export class ModalService {
  private modal: MatDialogRef<ModalComponent, any>;

  constructor(
    public dialog: MatDialog,
  ) { }

  public openModal(modalData: ModalData): void {
    this.openDialog(modalData);
  }

  public openCustomModal(
    component: ComponentType<any> | TemplateRef<any>, data: any, cssClass = 'modal-default', callback?: (value?) => void
  ): void {
    if (Boolean(this.modal)) { return; }
    this.modal = this.dialog.open(component, { data, panelClass: cssClass, maxHeight: '100vh', maxWidth: '100vw' });

    this.modal.afterClosed().subscribe(() => {
      if (callback) { callback(); }
      this.modal = undefined;
    });
  }

  public closeModals(): void {
    this.dialog.closeAll();
    this.modal = null;
  }

  private openDialog(data: ModalData) {
    if (Boolean(this.modal)) { return; }

    this.modal = this.dialog.open(ModalComponent, { data, autoFocus: false, disableClose: true, maxHeight: '100vh', maxWidth: '100vw' });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
  }
}
