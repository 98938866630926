import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { InformationModalComponent } from '@components/information-modal/information-modal.component';
import { COUNTRY_FLAGS_URL } from '@constants/country.constants';
import * as WITHDRAWAL from '@constants/funds-withdrawal.constant';
import { ADDRESS_PATTERN, ALPHANUMERIC_SPACES_PATTERN, EMAIL_PATTERN, PHONE_PATTERN, VALID_INPUT_PATTERN } from '@constants/regex.constant';
import { FormText } from '@interfaces/components.interface';
import { SelectOption } from '@interfaces/general.interface';
import { Region } from '@interfaces/parameters.interface';
import { PersonalInfo } from '@interfaces/personal-info.interface';
import { Utils } from '@utils/utils';
import { mustMatch } from '@validators/must-match.validator';
import { UrlInStringValidator } from '@validators/url-in-string.validator';
import { getCountryDialCode } from 'country-flags-dial-code';
import * as isoCountries from 'i18n-iso-countries';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.scss'],
})
export class PersonalInfoFormComponent implements OnInit, OnChanges {

  @Input() public regions: Array<Region> = [];
  @Input() public selectedLoginFlow: string;
  @Input() public personalInfo: PersonalInfo;
  @Input() public infoModalTexts: FormText[];
  @Output() public confirmStep = new EventEmitter();

  public form: FormGroup;
  public communes: Array<SelectOption> = [];
  public phoneNumberLength = WITHDRAWAL.PHONE_NUMBER_LENGTH;
  public phoneNumberMin = WITHDRAWAL.PHONE_NUMBER_MIN;
  public phoneNumberMax = WITHDRAWAL.PHONE_NUMBER_MAX;
  public namesMaxLength = WITHDRAWAL.SECOND_WITHDRAWAL_NAMES_MAX_LENGTH;
  public lastnamesMaxLength = WITHDRAWAL.LASTNAMES_MAX_LENGTH;
  public todayDate = new Date();
  public minDate = WITHDRAWAL.MIN_DATE;
  public idMaxLength = WITHDRAWAL.ID_MAX_LENGTH;
  public isBeneficiary = false;
  public isForeign = false;
  public countries;

  public validators = {
    name: [
      Validators.required,
      Validators.maxLength(this.namesMaxLength),
      Validators.pattern(VALID_INPUT_PATTERN),
      UrlInStringValidator
    ],
    optionalName: [
      Validators.maxLength(this.namesMaxLength),
      Validators.pattern(VALID_INPUT_PATTERN),
      UrlInStringValidator
    ],
    address: [
      Validators.pattern(ADDRESS_PATTERN),
      Validators.pattern(ALPHANUMERIC_SPACES_PATTERN)
    ],
    email: [
      Validators.required,
      Validators.email,
      Validators.pattern(EMAIL_PATTERN)
    ],
    number: [
      Validators.required,
      Validators.minLength(this.phoneNumberLength),
      Validators.maxLength(this.phoneNumberLength),
      Validators.min(this.phoneNumberMin),
      Validators.max(this.phoneNumberMax),
      Validators.pattern(PHONE_PATTERN),
    ],
    repeatEmail: [
      Validators.required,
      Validators.email
    ],
    affiliateAddress: [
      Validators.required
    ],
    affiliateCity: [
      Validators.required
    ],
  };

  constructor(
    private utils: Utils,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  public get name(): AbstractControl { return this.form.get('name'); }

  public get fathersName(): AbstractControl { return this.form.get('fathersName'); }

  public get email(): AbstractControl { return this.form.get('email'); }

  public get repeatEmail(): AbstractControl { return this.form.get('repeatEmail'); }

  public get region(): AbstractControl { return this.form.get('region'); }

  public get commune(): AbstractControl { return this.form.get('commune'); }

  public get countryCode(): AbstractControl { return this.form.get('countryCode'); }

  public get phone(): AbstractControl { return this.form.get('phone'); }

  public get repeatPhone(): AbstractControl { return this.form.get('repeatPhone'); }

  public get authorizeGenerali(): AbstractControl { return this.form.get('authorizeGenerali'); }

  public get fullName() {
    const { name, fathersName, mothersName } = this.form.getRawValue();
    return `${name || ''} ${fathersName || ''} ${mothersName || ''}`;
  }

  public get formTitle() {
    return !this.isBeneficiary ? WITHDRAWAL.PERSONAL_INFO_TITLE.AFFILIATE : WITHDRAWAL.PERSONAL_INFO_TITLE.BENEFICIARY;
  }

  async ngOnInit() {
    this.form = this.createForm();
    switch (this.selectedLoginFlow) {
      case WITHDRAWAL.LOGIN_FLOW.AFFILIATE:
        this.regionSubscription();
        this.setBeneficiaryValidator();
        break;
      case WITHDRAWAL.LOGIN_FLOW.BENEFICIARY:
        this.isBeneficiary = true;
        this.regionSubscription();
        this.setBeneficiaryValidator();
        break;
      case WITHDRAWAL.LOGIN_FLOW.FOREIGN:
        this.isForeign = true;
        isoCountries.registerLocale(await import('i18n-iso-countries/langs/es.json'));
        this.countries = this.sortCountriesList(isoCountries.getNames('es', {select: 'official'}));
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.personalInfo && !changes.personalInfo.firstChange) {
      this.fillForm(this.personalInfo);
    }
  }

  public createForm(): FormGroup {

    const localPersonalInfoForm = this.formBuilder.group(
      {
        name: [null, this.validators.name],
        fathersName: [null, this.validators.name],
        mothersName: [null, this.validators.optionalName],
        region: [null],
        commune: [null],
        address: [null, this.validators.address],
        phone: [null, this.validators.number],
        repeatPhone: [null, this.validators.number],
        email: [null, this.validators.email],
        repeatEmail: [null, this.validators.repeatEmail],
        authorizeGenerali: [false, Validators.required],
      },
      {
        validator: [
          mustMatch('phone', 'repeatPhone'),
          mustMatch('email', 'repeatEmail'),
        ],
      });
    const foreignPersonalInfoForm = this.formBuilder.group(
      {
        name: [null, this.validators.name],
        fathersName: [null, this.validators.name],
        mothersName: [null, this.validators.optionalName],
        countryCode: [null, Validators.required],
        phone: [null, [Validators.required, Validators.pattern(PHONE_PATTERN)]],
        repeatPhone: [null, [Validators.required, Validators.pattern(PHONE_PATTERN)]],
        email: [null, this.validators.email],
        repeatEmail: [null, this.validators.repeatEmail],
        birthdate: [null, Validators.required],
        authorizeGenerali: [false, Validators.required],
        affiliateAddress: [null, this.validators.affiliateAddress],
        affiliateCity: [null, this.validators.affiliateCity],
      },
      {
        validator: [
          mustMatch('phone', 'repeatPhone'),
          mustMatch('email', 'repeatEmail'),
        ],
      }
    );

    switch (this.selectedLoginFlow) {
      case WITHDRAWAL.LOGIN_FLOW.AFFILIATE:
      case WITHDRAWAL.LOGIN_FLOW.BENEFICIARY:
        return localPersonalInfoForm;
      case WITHDRAWAL.LOGIN_FLOW.FOREIGN:
        return foreignPersonalInfoForm;
    }

  }

  public fillForm(personalInfo: PersonalInfo): void {
    const { region, commune } = personalInfo;
    this.form.setValue({ ... personalInfo });
    if (region) {
      this.region.setValue(region.toString());
    }
    if (commune) {
      this.commune.setValue(commune.toString());
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
  }

  public setBeneficiaryValidator(): void {
    this.isBeneficiary ? this.authorizeGenerali.disable() : this.authorizeGenerali.enable();
  }

  private regionSubscription() {
    this.region.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe(
        (regionCodeSelected) => {
          const regionSelected = this.regions.find(region => region.code === regionCodeSelected);
          this.commune.setValue('');
          this.communes = regionSelected ? regionSelected.communes : [];
          regionSelected ? this.commune.enable() : this.commune.disable();
        });
  }

  public confirm() {
    if (this.form.invalid) { return; }
    const params = this.getParsedData();
    this.confirmStep.emit(params);
  }

  private getParsedData(): PersonalInfo {
    const data = this.form.getRawValue();
    const { region, commune, countryCode, phone, repeatPhone, email } = data;
    if (region) { data.region = parseInt(region, 10); }
    if (commune) {
      data.commune = parseInt(commune, 10);
      data.city = this.utils.getCity(this.regions, region, commune);
    }
    data.phone = !this.isForeign ? phone.toString() : countryCode.concat(phone.toString());
    data.repeatPhone = !this.isForeign ? repeatPhone.toString() : countryCode.concat(repeatPhone.toString());
    data.email = email.toLowerCase();

    return data;
  }

  public requiredError(formControlName: string): boolean {
    return this.form.get(formControlName).hasError('required');
  }

  public patternError(formControlName: string): boolean {
    return this.form.get(formControlName).hasError('pattern');
  }

  public urlInStringError(formControlName: string): boolean {
    return this.form.get(formControlName).hasError('urlInString');
  }

  public minDateError(formControlName: string): boolean {
    return this.form.get(formControlName).hasError('matDatepickerMin');
  }

  public async validateEmailDomain() {
    await this.utils.validateEmailDomain(this.email);
  }

  public resetForm() {
    const fields = Object.keys(this.form.getRawValue());
    fields.forEach((field) => {
      if (field === 'authorizeGenerali') { return this.form.get('authorizeGenerali').reset(false); }
      this.form.get(field).reset();
    });
    this.utils.enableFields(this.form, fields);
  }

  public openInfoModal() {
    const data = { infoModalTexts: this.infoModalTexts };
    this.dialog.open(InformationModalComponent, { data, maxHeight: '100vh', maxWidth: '100vw', autoFocus: false });
  }

  public getCountryPhoneCode(countryIsoCode: string) {
    return getCountryDialCode(countryIsoCode);
  }

  public getCountrySvgFlag(countryIsoCode: string) {
    return `${COUNTRY_FLAGS_URL}${countryIsoCode.toLocaleLowerCase()}.png`;
  }

  private sortCountriesList(countries) {
    const tempCountries = Object.keys(countries).map((isoCode) => {
      return { isoCode, name: countries[isoCode] };
    });
    return tempCountries.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
  }
}
