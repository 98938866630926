
export const GENERAL_SUCCESSFUL_VOUCHER = {
  title: '¡Listo! Tu solicitud de retiro de fondos ha sido ingresada con éxito',
  descriptionFolio: 'Número de folio',
  description: 'Enviamos la información a tus datos de contacto por correo electrónico y/o SMS. <br> En un plazo máximo de 4 días ' +
    'hábiles te enviaremos una respuesta a tu solicitud.',
  buttonBack: 'Volver a <span class="highlight">AFP PlanVital</span>'
};

export const SECOND_WITHDRAWAL_INFORMATION_VOUCHER = {
  description: '¿Quiénes tendrán que pagar impuestos?',
  answerList: [
    { list: 'Para este segundo retiro del 10%, es el SII quien determinará el impuesto a aplicarse a cada caso, el que se realiza ' +
    'durante los meses de abril de cada año. No pagan impuesto quienes ganen menos de $1.500.000.'
    },
    { list: 'El impuesto considera todos los ingresos que la persona ha recibido durante el año.' }
  ]
};

export const BONUS_SUCCESSFUL_VOUCHER = {
  title: '¡Listo! Tu solicitud de Retiro de Bono de Cargo Fiscal ha sido ingresada con éxito',
  descriptionFolio: 'Número de folio',
  description: 'Enviamos la información a tus datos de contacto por correo electrónico y SMS. <br>En un plazo máximo de 4 días hábiles ' +
    'te enviaremos una respuesta a tu solicitud.',
  buttonBack: 'Volver a <span class="highlight">AFP PlanVital</span>'
};

export const WITHDRAWAL_IS_TAXED = {
  1: false,
  2: true,
  3: false,
  201: false,
};
