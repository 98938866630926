import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ExpressQuestionModalComponent } from '@components/express-question-modal/express-question-modal.component';
import {
  SerialNumberModalComponent
} from '@components/serial-number-modal/serial-number-modal.component';
import {
  BENEFICIARY_NOT_MATCH,
  CLIENT_INSUFFICIENT_BALANCE_ERROR,
  INVALIDATING_REJECTION_ERROR_CODE,
  INVALID_RUT_PASSWORD_REJECTION,
  NOT_FOUND_ERROR_CODE,
  NO_BALANCE_REJECTION,
  PRIVATE_RUT_ERROR_CODE,
  REQUEST_PENDING_ERROR_CODE
} from '@constants/error-codes.constant';
import {
  DEFAULT_ERROR_MODAL_PROPS,
  ERROR_MODAL_INVALIDATING_REJECTION,
  ERROR_MODAL_POLITICAL_AUTHORITIES,
  EXISTING_WITHDRAWAL_ERROR
} from '@constants/error-messages.constant';
import * as WITHDRAWAL from '@constants/funds-withdrawal.constant';
import { ALPHANUMERIC_PATTERN } from '@constants/regex.constant';
import { getCheckStatusRoute, getRequestRoute, WITHDRAWAL_NUMBERS_TO_KEYS } from '@constants/routes.constant';
import { WithdrawalFormInfo } from '@interfaces/components.interface';
import { BiometricDataRequest, WithdrawalKey, WithdrawalType } from '@interfaces/funds-withdrawal.interface';
import { Item, Section } from '@interfaces/general.interface';
import { LoginInfo } from '@interfaces/login-info.interface';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal';
import { NavigationService } from '@providers/navigation/navigation.service';
import { WithdrawalsService } from '@services/withdrawals/withdrawals.service';
import { Utils } from '@utils/utils';
import { mustMatch } from '@validators/must-match.validator';
import { ValidateRut } from '@validators/rut.validator';
import { distinctUntilChanged, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() public selectedLoginFlow;
  @Input() public withdrawalNumber: WithdrawalType;
  @Input() public cmsInformation: WithdrawalFormInfo;
  @Output() public setLoginInfoForm = new EventEmitter<any>();
  @Output() public fillExpressForm = new EventEmitter<any>();
  @Output() public resetParentForm = new EventEmitter<any>();

  public mainWithdrawalRoute: WithdrawalKey;
  public form: FormGroup;
  public isPoliticalAuthorityForm: FormGroup;
  public client;
  public isValidClient = false;
  public isPrivateRut = false;
  public errorMessage;
  public validationMessage;

  public constants = WITHDRAWAL;
  public validateOptions: Array<Item> = WITHDRAWAL.VALIDATE_OPTIONS;
  public idMaxLength = WITHDRAWAL.ID_MAX_LENGTH;
  public serialNumberMaxLength = WITHDRAWAL.SERIAL_NUMBER_MAX_LENGTH;
  public passwordMinLength = WITHDRAWAL.PASSWORD_MIN_LENGTH;
  public passwordMaxLength = WITHDRAWAL.PASSWORD_MAX_LENGTH;
  public privateRutDescription = WITHDRAWAL.PRIVATE_RUT_DESCRIPTION;
  public userType = 'beneficiario';
  public RUT_OPTION = 1;
  public NIC_OPTION = 2;
  public FOREIGN_OPTION = 3;
  public existingWithdrawalError: string;

  public validators = {
    rut: [
      Validators.required,
      ValidateRut,
      Validators.maxLength(this.idMaxLength),
    ],
    serialNumber: [
      Validators.required,
      Validators.maxLength(this.serialNumberMaxLength),
      Validators.pattern(ALPHANUMERIC_PATTERN),
    ],
    password: [
      Validators.required,
      Validators.minLength(this.passwordMinLength),
      Validators.maxLength(this.passwordMaxLength),
    ],
  };

  constructor(
    private utils: Utils,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private modalProvider: ModalService,
    private navigateService: NavigationService,
    private loadingService: LoadingService,
    private withdrawalsService: WithdrawalsService,
  ) {
    this.isPoliticalAuthorityForm = this.formBuilder.group({
      isPoliticalAuthority: [null, Validators.required],
    });
  }

  public get isAffiliateFlow(): boolean { return this.selectedLoginFlow === WITHDRAWAL.LOGIN_FLOW.AFFILIATE; }

  public get isBeneficiaryFlow(): boolean { return this.selectedLoginFlow === WITHDRAWAL.LOGIN_FLOW.BENEFICIARY; }

  public get isForeignFlow(): boolean { return this.selectedLoginFlow === WITHDRAWAL.LOGIN_FLOW.FOREIGN; }

  public get affiliateRut(): AbstractControl { return this.form.get('affiliateRut'); }

  public get applicantRut(): AbstractControl { return this.form.get('applicantRut'); }

  public get beneficiaryRut(): AbstractControl { return this.form.get('beneficiaryRut'); }

  public get isMinor(): AbstractControl { return this.form.get('isMinor'); }

  public get rut(): AbstractControl { return this.form.get('rut'); }

  public get serialNumber(): AbstractControl { return this.form.get('serialNumber'); }

  public get repeatSerialNumber(): AbstractControl { return this.form.get('repeatSerialNumber'); }

  public get nic(): AbstractControl { return this.form.get('nic'); }

  public get password(): AbstractControl { return this.form.get('password'); }

  public get validateOption(): AbstractControl { return this.form.get('validateOption'); }

  public get biometric(): FormGroup { return this.form.get('biometric') as FormGroup; }

  public get beneficiaryControl(): AbstractControl { return this.form.get('isBeneficiary'); }

  public get rutOptionSelected(): boolean { return this.validateOption.value === this.RUT_OPTION; }

  public get nicOptionSelected(): boolean { return this.validateOption.value === this.NIC_OPTION; }

  public get foreignOptionSelected(): boolean { return this.validateOption.value === this.FOREIGN_OPTION; }

  public get isPoliticalAuthority(): AbstractControl { return this.isPoliticalAuthorityForm.get('isPoliticalAuthority'); }

  public get isBiometric(): boolean { return this.route.snapshot.params.id === 'biotablet'; }

  public get isBeneficiary() {
    return (
      this.selectedLoginFlow === WITHDRAWAL.LOGIN_FLOW.BENEFICIARY
      || (this.selectedLoginFlow === WITHDRAWAL.LOGIN_FLOW.FOREIGN && this.beneficiaryControl.value)
    );
  }

  public get clientDisclaimer(): Section {
    const { AFILIATE_DESCRIPTION, CLIENT_DESCRIPTION, NOT_CLIENT_DESCRIPTION, PRIVATE_RUT_DESCRIPTION } = this.constants;

    switch (this.selectedLoginFlow) {
      case WITHDRAWAL.LOGIN_FLOW.AFFILIATE:
        if (this.client) {
          return this.isPrivateRut ? PRIVATE_RUT_DESCRIPTION : CLIENT_DESCRIPTION;
        } else {
          return NOT_CLIENT_DESCRIPTION;
        }
      case WITHDRAWAL.LOGIN_FLOW.BENEFICIARY:
        return this.client ? AFILIATE_DESCRIPTION : NOT_CLIENT_DESCRIPTION;
      case WITHDRAWAL.LOGIN_FLOW.FOREIGN:
        if (this.client) {
          return CLIENT_DESCRIPTION;
        } else {
          return this.isBeneficiary ? WITHDRAWAL.DECEASED_RUT_NOT_FOUND_DESCRIPTION : NOT_CLIENT_DESCRIPTION;
        }
    }
  }

  public get loginError() {
    let title;
    let description;

    if (this.isValidClient && !this.isPrivateRut) {
      title = this.clientDisclaimer.title;
      description = this.clientDisclaimer.description;
    } else if (!this.isValidClient && this.isPrivateRut) {
      title = this.privateRutDescription.title;
      description = this.privateRutDescription.description;
    } else if (!this.isValidClient && this.errorMessage) {
      title = this.errorMessage.message;
      description = this.errorMessage.messageDescription;
    }

    if (title || description) {
      return {
        title,
        description
      };
    }
    return false;
  }

  public get passwordError() {
    return this.errorMessage && this.errorMessage.code === INVALID_RUT_PASSWORD_REJECTION;
  }

  ngOnInit() {
    this.mainWithdrawalRoute = WITHDRAWAL_NUMBERS_TO_KEYS[this.withdrawalNumber] as WithdrawalKey;
    this.existingWithdrawalError = EXISTING_WITHDRAWAL_ERROR[this.withdrawalNumber];
    this.form = this.createForm();
    switch (this.selectedLoginFlow) {
      case WITHDRAWAL.LOGIN_FLOW.AFFILIATE:
        this.validateOptionSubscription();
        this.getBiometricData();
        break;

      case WITHDRAWAL.LOGIN_FLOW.BENEFICIARY:
        this.isMinorSubscription();
        break;

      case WITHDRAWAL.LOGIN_FLOW.FOREIGN:
        this.isBeneficiarySubscription();
        break;
    }
  }

  public createForm(): FormGroup {
    const beneficiaryLogin = this.formBuilder.group(
      {
        affiliateRut: [null, this.validators.rut],
        beneficiaryRut: [null, this.validators.rut],
        applicantRut: [null],
        isMinor: [false, Validators.required],
        serialNumber: [null, this.validators.serialNumber],
        repeatSerialNumber: [null, this.validators.serialNumber],
      }, {
        validator: [
          mustMatch('serialNumber', 'repeatSerialNumber'),
        ],
      }
    );

    const foreignLogin = this.formBuilder.group({
      rut: [null, this.validators.rut],
      password: [null, this.validators.password],
      serialNumber: [null, this.validators.serialNumber],
      isBeneficiary: [false, Validators.required],
      beneficiaryRut: [null],
    });

    const affiliateLogin = this.formBuilder.group(
      {
        validateOption: [1, Validators.required],
        rut: [null, this.validators.rut],
        serialNumber: [null, this.validators.serialNumber],
        repeatSerialNumber: [null, this.validators.serialNumber],
        nic: [null],
        password: [null],
        biometric: this.formBuilder.group({
          uid: [null],
          pageId: [null],
        })
      }, {
        validator: [
          mustMatch('serialNumber', 'repeatSerialNumber'),
        ],
      }
    );

    switch (this.selectedLoginFlow) {
      case WITHDRAWAL.LOGIN_FLOW.AFFILIATE:
        return affiliateLogin;

      case WITHDRAWAL.LOGIN_FLOW.BENEFICIARY:
        return beneficiaryLogin;

      case WITHDRAWAL.LOGIN_FLOW.FOREIGN:
        return foreignLogin;
    }
  }

  public requiredError(formControlName: string): boolean {
    return this.form.get(formControlName).hasError('required');
  }

  public patternError(formControlName: string): boolean {
    return this.form.get(formControlName).hasError('pattern');
  }

  public toUpperCase(controlName: string): void {
    let value = this.form.get(controlName).value;
    value = value ? value.toUpperCase() : '';
    this.form.get(controlName).setValue(value);
    this.form.updateValueAndValidity();
  }

  public openSerialNumberInfo(): void {
    this.dialog.open(SerialNumberModalComponent, { maxHeight: '100vh', maxWidth: '100vw', autoFocus: false });
  }

  public emitSetLoginInfoForm(): void {
    if (this.form.invalid) { return; }
    const params = {
      loginInfo: {
        ...this.form.value,
        isPoliticalAuthority: this.isPoliticalAuthority.value,
      } as LoginInfo,
      client: this.client
    };
    this.setLoginInfoForm.emit(params);
  }

  public resetForm(): void {
    this.isPoliticalAuthorityForm.reset();
    const fields = Object.keys(this.form.getRawValue());
    this.utils.enableFields(this.form, fields);

    fields.forEach((field) => {
      const oldValue = this.form.get(field).value;
      this.form.get(field).reset();
      if (
        field === 'validateOption' ||
        field === 'isBeneficiary' ||
        field === 'isMinor'
      ) {
        this.form.get(field).setValue(oldValue);
      }
    });
    this.isValidClient = false;
    this.resetParentForm.emit();
    this.getBiometricData();
  }

  public onValidate(): void {
    switch (this.selectedLoginFlow) {
      case WITHDRAWAL.LOGIN_FLOW.AFFILIATE:
        this.validateClient();
        break;

      case WITHDRAWAL.LOGIN_FLOW.BENEFICIARY:
        this.validateBeneficiaryClient();
        break;

      case WITHDRAWAL.LOGIN_FLOW.FOREIGN:
        this.validateForeignClient();
        break;
    }
  }

  public validateClient() {
    if (this.form.invalid) {
      return;
    }
    const { rut, nic, serialNumber, password } = this.form.value;
    if (this.isPoliticalAuthority.value) { return this.openPoliticalAuthorityModal(rut); }
    this.loadingService.showLoading();

    this.withdrawalsService.getClientGenericWithdrawal(this.withdrawalNumber, rut || nic, serialNumber, password)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response) => {
          if (response.finishedWithdrawal) {
            this.dialog.open(
              ExpressQuestionModalComponent,
              { maxHeight: '100vh', maxWidth: '100vw', disableClose: true }
            )
            .beforeClosed()
            .subscribe((value) => {
              if (value) {
                this.fillExpressForm.emit(response);
              } else {
                response.finishedWithdrawal = null;
              }
            });
          }
          this.client = response;
          this.isValidClient = true;
          this.isPrivateRut = false;
          if (!this.client.existingWithdrawalRequest) {
            this.emitSetLoginInfoForm();
          }
          this.disableFields();
        },
        (error) => {
          this.disableFields();
          if (error.statusCode !== NOT_FOUND_ERROR_CODE) { return this.handleError(error); }
          this.isValidClient = true;
        }
      );
  }

  public validateBeneficiaryClient(): void {
    if (this.form.invalid) {
      return;
    }
    const { applicantRut, beneficiaryRut, affiliateRut, isMinor } = this.form.value;
    const rut = isMinor ? applicantRut : beneficiaryRut;
    if (this.isPoliticalAuthority.value) { return this.openPoliticalAuthorityModal(rut); }
    this.loadingService.showLoading();

    this.withdrawalsService.searchBeneficiaryClientGenericWithdrawal(this.withdrawalNumber, rut, beneficiaryRut, affiliateRut, isMinor)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response) => {
          this.client = {
            type: 'B',
            accounts: [],
            existingWithdrawalRequest: false,
            finishedWithdrawal: { ...response },
          };
          this.isValidClient = true;
          this.isPrivateRut = false;
          if (!this.client.existingWithdrawalRequest) {
            this.emitSetLoginInfoForm();
          }
          this.disableFields();
        },
        (error) => {
          this.disableFields();
          if (error.statusCode !== NOT_FOUND_ERROR_CODE) { return this.handleError(error); }
          this.isValidClient = true;
        }
      );
  }

  public validateForeignClient(): void {
    if (this.form.invalid) {
      return;
    }
    const { rut, beneficiaryRut, serialNumber, password, isBeneficiary } = this.form.value;
    if (this.isPoliticalAuthority.value) { return this.openPoliticalAuthorityModal(rut); }
    this.loadingService.showLoading();

    if (!isBeneficiary) {
      this.withdrawalsService.getForeignClientGenericWithdrawal(this.withdrawalNumber, rut, serialNumber, password)
        .pipe(finalize(() => this.loadingService.hideLoading()))
        .subscribe(
          (response) => {
            this.client = response;
            this.isValidClient = true;
            this.isPrivateRut = false;
            this.setForeignValidationText();
            if (!this.client.existingWithdrawalRequest) {
              this.emitSetLoginInfoForm();
            }
            this.disableFields();
          },
          (error) => {
            this.disableFields();
            if (error.statusCode !== NOT_FOUND_ERROR_CODE) { return this.handleError(error); }
            this.isValidClient = true;
          }
        );
    } else {
      this.withdrawalsService.getBeneficiaryClientGenericWithdrawal(beneficiaryRut)
        .pipe(finalize(() => this.loadingService.hideLoading()))
        .subscribe(
          (response) => {
            this.client = response;
            this.isValidClient = true;
            this.isPrivateRut = false;
            if (!this.client.existingWithdrawalRequest) {
              this.emitSetLoginInfoForm();
            }
            this.disableFields();
          },
          (error) => {
            this.disableFields();
            if (error.statusCode !== NOT_FOUND_ERROR_CODE) { return this.handleError(error); }
            this.isValidClient = true;
          }
        );
    }
  }

  public setForeignValidationText() {
    if (!this.isBeneficiary) {
      if (this.client.validPassword) {
        this.validationMessage = this.client.validId
          ? WITHDRAWAL.PASSWORD_AND_SERIAL_NUMBER_VALID_TEXT
          : WITHDRAWAL.PASSWORD_VALID_AND_SERIAL_NUMBER_INVALID_TEXT;
      } else {
        this.validationMessage = this.client.validId
          ? WITHDRAWAL.PASSWORD_INVALID_AND_SERIAL_NUMBER_VALID_TEXT
          : WITHDRAWAL.PASSWORD_AND_SERIAL_NUMBER_INVALID_TEXT;
      }
    }
  }

  public goToRequestStatus() {
    this.navigateService.goTo(getCheckStatusRoute(this.mainWithdrawalRoute));
  }

  private validateOptionSubscription() {
    this.validateOption.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((value) => {
        switch (value) {
          case this.RUT_OPTION:
            this.utils.enableControlAndSetValidator(this.rut, this.validators.rut);
            this.utils.enableControlAndSetValidator(this.serialNumber, this.validators.serialNumber);
            this.utils.enableControlAndSetValidator(this.repeatSerialNumber, this.validators.serialNumber);

            this.nic.disable();
            this.password.disable();
            break;
          case this.NIC_OPTION:
            this.utils.enableControlAndSetValidator(this.nic, this.validators.rut);
            this.utils.enableControlAndSetValidator(this.password, this.validators.password);

            this.rut.disable();
            this.serialNumber.disable();
            this.repeatSerialNumber.disable();
            break;
          case this.FOREIGN_OPTION:
            this.utils.enableControlAndSetValidator(this.rut, this.validators.rut);
            this.utils.enableControlAndSetValidator(this.password, this.validators.password);

            this.nic.disable();
            this.serialNumber.disable();
            this.repeatSerialNumber.disable();
            break;
        }
        this.errorMessage = false;
      });
  }

  private isMinorSubscription() {
    this.isMinor.valueChanges
      .pipe(distinctUntilChanged((current, previous) => current === previous))
      .subscribe((value) => {
        if (value) {
          this.utils.enableControlAndSetValidator(this.applicantRut, this.validators.rut);
        } else {
          this.applicantRut.disable();
        }

        this.userType = value ? 'solicitante' : 'beneficiario';
        this.errorMessage = false;
      });
  }

  private isBeneficiarySubscription() {
    this.beneficiaryControl.valueChanges
      .pipe(distinctUntilChanged((current, previous) => current === previous))
      .subscribe((value) => {
        if (value) {
          this.utils.enableControlAndSetValidator(this.beneficiaryRut, this.validators.rut);

          this.password.disable();
          this.serialNumber.disable();
        } else {
          this.utils.enableControlAndSetValidator(this.password, this.validators.password);
          this.utils.enableControlAndSetValidator(this.serialNumber, this.validators.serialNumber);

          this.beneficiaryRut.disable();
        }
        this.errorMessage = false;
      });
  }

  private disableFields() {
    const formFields = Object.keys(this.form.getRawValue());
    formFields.forEach((field) => {
      this.form.get(field).disable({ onlySelf: true });
    });
  }

  private getBiometricData() {
    if (!this.route.snapshot.params.id) { return; }
    if (!this.isBiometric) { return this.navigateService.goTo(getRequestRoute(this.mainWithdrawalRoute)); }
    this.route.queryParams.subscribe(
      (biometric: BiometricDataRequest) => {
        const { page: pageId, index: uid } = biometric;
        if (!pageId || !uid) { return this.biometricError(); }
        this.utils.disableFields(this.form, ['serialNumber', 'repeatSerialNumber']);
        this.biometric.patchValue({ pageId, uid });
      },
    );
  }

  private biometricError() {
    const title = 'Error al validar datos biométricos';
    const modalData = { ...DEFAULT_ERROR_MODAL_PROPS, title };
    modalData.closeCallback = () => this.navigateService.goTo(getRequestRoute(this.mainWithdrawalRoute));
    this.modalProvider.openModal(modalData);
  }

  private openPoliticalAuthorityModal(rut) {
    const modalData = ERROR_MODAL_POLITICAL_AUTHORITIES;
    this.withdrawalsService.saveRejectionPoliticalPosition(this.withdrawalNumber, rut, true).subscribe();
    this.modalProvider.openModal(modalData);
  }

  private handleError(error) {
    this.resetForm();
    let modalData = DEFAULT_ERROR_MODAL_PROPS;
    if (error.error) {
      switch (error.error.code) {
        case PRIVATE_RUT_ERROR_CODE:
          this.isPrivateRut = true;
          return;
        case NO_BALANCE_REJECTION:
        case CLIENT_INSUFFICIENT_BALANCE_ERROR:
        case REQUEST_PENDING_ERROR_CODE:
        case INVALID_RUT_PASSWORD_REJECTION:
        case BENEFICIARY_NOT_MATCH:
          this.errorMessage = error.error;
          return;
        case INVALIDATING_REJECTION_ERROR_CODE:
          modalData = ERROR_MODAL_INVALIDATING_REJECTION;
          break;
      }
    }
    this.modalProvider.openModal(modalData);
  }
}
