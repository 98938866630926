export const NOT_FOUND_ERROR_CODE = 404;
export const BAD_REQUEST_CODE = 400;
export const REQUEST_PENDING_ERROR_CODE = 40141;
export const INVALIDATING_REJECTION_ERROR_CODE = 40144;

export const EXPIRED_ID_ERROR_CODE = 40145;
export const BLOCKED_ID_ERROR_CODE = 40137;
export const INVALID_ID_REJECTION = 40146;
export const INVALID_RUT_SECURITY_KEY_REJECTION = 40147;
export const INVALID_NIC_PASSWORD_REJECTION = 40148;
export const INVALID_RUT_PASSWORD_REJECTION = 40171;
export const NO_BALANCE_REJECTION = 40176;
export const CLIENT_INSUFFICIENT_BALANCE_ERROR = 40192;
export const INVALID_BIOMETRIC_ID_REJECTION = 40149;

export const PRIVATE_RUT_ERROR_CODE = 40173;
export const PRIVATE_RUT_WITHDRAWAL_REQUEST_ERROR_CODE = 40174;
export const ANNUL_LIMIT_DAYS_ERROR_CODE = 40186;
export const BENEFICIARY_NOT_MATCH = 40187;

export const INVALID_DOCUMENT_ID = 40124;
export const INVALID_EQUIFAX_REJECTION = 40193;

export const BLACKLIST_RUT_FOUND = 40209;
export const BONUS_BLACKLIST_RUT_FOUND = 40215;

export const CLIENT_NO_BONUS_VALIDATE = 40211;
export const BONUS_REQUEST_PENDING_ERROR_CODE = 40213;
export const CLIENT_PENDING_BONUS_VALIDATE = 40214;
export const WRONG_PASSWORD_ERROR_CODE = 40200;
export const BLOCKED_CREDENTIALS_ERROR_CODE = 40204;
