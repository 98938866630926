import { CONTACT_CENTER } from '@constants/general.constant';
import { SOCIAL_URL } from '@constants/url.constant';
import { Section } from '@interfaces/general.interface';

export const CONTACT_SOCIAL_URL = {
  ...SOCIAL_URL,
  whatsapp: 'https://api.whatsapp.com/send?phone=56222645200&text=Hola',
  email: 'mailto:infovital@planvital.cl'
};

export const BUTTON_TITLES: { [key: string]: string } = {
  contactCenter: 'Contact Center',
  branchOffices: 'Sucursales',
  callCenter: 'Callcenter',
  contact: 'Contacto',
  faqs: 'Preguntas',
  top: 'Subir',
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  youtube: 'Youtube',
  whatsapp: 'Whatsapp',
  email: 'Email',
};

export const CONTACT_FORM_FIELDS = {
  rut: 'RUT',
  fatherLastName: 'Apellido Paterno',
  motherLastName: 'Apellido Materno',
  name: 'Nombre',
  phoneNumber: 'Teléfono Celular',
  email: 'E-mail',
  reasonCode: 'Motivo',
  description: 'Comentarios',
  file: '¿Desea adjuntar un archivo?',
};

export const CONTACT_FORM_OPTIONS = {
  upload: 'Subir',
  update: 'Actualizar',
  yes: 'Sí',
  no: 'No',
  send: 'Enviar',
};

export const CALL_CENTER_MODAL_CONTENT = {
  title: 'Contact Center',
  button: `<p><strong>${CONTACT_CENTER.MASK}</strong></p>`
};

export const CONTACT_MODAL_TEXT: Section = {
  title: 'Formulario de Contacto',
  description: 'Completa el siguiente formulario de contacto y nuestros ejecutivos te responderán a \
  más tardar en un plazo de tres días hábiles.',
};

export const CONTACT_RESULT_MODAL = {
  success: {
    title: 'Su solicitud ha sido ingresada exitosamente.',
    description: 'Con Folio: {{ folio }} y fecha de solicitud {{ date }}. Daremos respuesta en un plazo no \
    más allá de tres días hábiles.',
  },
  error: {
    title: 'Su solicitud no ha sido ingresada.',
    description: 'Intentar más tarde.',
  },
};
