import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-political-information-modal',
  templateUrl: './political-information-modal.component.html',
  styleUrls: ['./political-information-modal.component.scss']
})
export class PoliticalInformationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<PoliticalInformationModalComponent>,
  ) {}

  public closeDialog() {
    this.dialogRef.close();
  }

}
