import { IChartistData, IPieChartOptions } from 'chartist';
import { ChartType } from 'ng-chartist';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() public isLinear = false;
  @Input() public steps: Array<string> = [];
  @Input() currentIndex = 0;
  @Output() public changedStep: EventEmitter<number> = new EventEmitter();
  public chartType: ChartType = 'Pie';
  public chartOptions: IPieChartOptions = {
    donut: true,
    showLabel: false,
    chartPadding: 0,
    height: '58px',
    width: '58px',
    donutWidth: '22px',
  };

  constructor() { }

  public get chartData(): IChartistData {
    return {
      series: [
        { value: this.currentStepPercentage, className: 'stepper complete' },
        { value: 100 - this.currentStepPercentage, className: 'stepper uncomplete' },
      ]
    };
  }

  public get currentStepPercentage(): number { return this.stepPercentage * this.currentIndex; }
  public get stepPercentage(): number { return Math.round(100 / (this.steps.length - 1)); }
  public get firstStep(): number { return 0; }
  public get lastStep(): number { return this.steps.length - 1; }
  public get unionLinesMargin(): number { return -100 / (this.steps.length * 2); }
  public isFirst(index: number): boolean { return index === this.firstStep; }
  public isLast(index: number): boolean { return index === this.lastStep; }
  public isCurrent(index: number): boolean { return index === this.currentIndex; }
  public isNextToCurrent(index: number): boolean { return index === this.currentIndex + 1; }
  public isBeforeCurrent(index: number): boolean { return index < this.currentIndex; }

  public nextStep() {
    if (this.currentIndex === this.lastStep) { return; }
    this.changeStep(this.currentIndex + 1);
  }

  public previousStep() {
    if (this.currentIndex === this.firstStep) { return; }
    this.changeStep(this.currentIndex - 1);
  }

  public onStepClicked(index: number) {
    if (this.isLinear) { return; }
    this.changeStep(index);
  }

  public changeStep(index: number) {
    if (index < this.firstStep || index > this.lastStep) { return; }
    this.currentIndex = index;
    this.changedStep.emit(index);
  }

}
