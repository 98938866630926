import { GENERIC_WITHDRAWAL_NUMBER } from '@constants/funds-withdrawal.constant';
import { MAIN_ROUTES } from '@constants/routes.constant';
import { FundsWithdrawal, WithdrawalInformationOptions } from '@interfaces/funds-withdrawal.interface';
import { HomeLanding, HomeSection, Item, Section } from '@interfaces/general.interface';

// tslint:disable:max-line-length
export const HOME_TITLE = 'Bienvenido al Ingreso de Solicitudes del 10% de los Fondos de Pensiones';
export const DESCRIPTION = 'Selecciona el retiro que quieres realizar';
export const SUBDESCRIPTION = 'Para mayor información e ingreso de solicitudes, selecciona el retiro que necesitas realizar.';

export const PAGE_TITLE = 'Proyecto retiro 10% de los Fondos de Pensiones';
export const FUNDS_WITHDRAWAL_CONTENT: FundsWithdrawal = {
  header: {
    id: 1,
    image: {
      url: 'assets/images/retiro_10.jpg',
      alt: 'alt',
    },
    icon: 'withdrawal',
    style: 'default',
    firstLine: `${ PAGE_TITLE }`,
  },
};

export const HOME_LANDING_WITHDRAWAL: HomeLanding = {
  homeSection: {
    title: `${ HOME_TITLE }`,
    description: `${ DESCRIPTION }`,
    subDescription: `${ SUBDESCRIPTION }`,
  },
  navTree: ['Home', 'Retiro del 10%'],
  moreInfoSection: {
    title: 'Información general',
    description: '',
  },
  faqSection: {
    title: '',
  },
};

export const TYPE_SEARCH_OPTIONS: Array<Item> = [
  { id: 1, name: 'Número de folio' },
  { id: 2, name: 'Celular' },
  { id: 3, name: 'Correo electrónico' },
];

export const CHECK_STATUS_CONTENT: HomeSection = {
  title: 'Consulta el estado de tu solicitud',
  description: 'Si ya ingresaste tu solicitud del {{retiro}}, puedes revisar aquí el estado con tu <strong>Rut</strong> y <strong>Número de folio</strong>, <strong>correo electrónico</strong> o <strong>celular</strong>.',
  subDescription: 'Ingresa tus datos para ver tus solicitudes cursadas del {{retiro}}'
};

export const CANCEL_REQUEST_CONTENT: HomeSection = {
  title: 'Anular solicitudes',
  description: 'Aquí podrás eliminar tu solicitud del {{retiro}}, cuando esta presente errores en los datos o no hayas sido tú quien la ingresó',
  subDescription: 'Ingresa tus datos para ver tus solicitudes cursadas del {{retiro}}'
};

export const SECOND_CHECK_STATUS_CONTENT: Section = {
  title: 'Consulta el estado de tu solicitud',
  description: 'Si ya ingresaste tu solicitud del segundo retiro de fondos del 10%, puedes revisar aquí el estado con tu <strong>Rut</strong> y <strong>Número de folio</strong>, <strong>correo electrónico</strong> o <strong>celular</strong>.'
};

export const SECOND_CANCEL_REQUEST_CONTENT: Section = {
  title: 'Anular solicitudes',
  description: 'Aquí podrás eliminar tu solicitud del segundo retiro de fondos, cuando esta presente errores en los datos o no hayas sido tú quien la ingresó',
};

export const FISCAL_BONUS_HOME_LANDING: Section = {
  title: 'Todo lo que necesitas saber sobre el <srtong>Bono de Cargo Fiscal</strong>',
  description: 'Este bono de hasta $200.000 es para quienes hayan quedado con saldo 0 en sus cuentas, producto de alguno de los primeros dos retiros, o que tengan saldo menor a $200.000 al 31 de marzo de 2021.'
};

export const WITHDRAWAL_INSTANCES = ['primer',  'segundo', 'tercer'];

export const WITHDRAWAL_INFORMATION: WithdrawalInformationOptions = {
  [GENERIC_WITHDRAWAL_NUMBER.FIRST]: {
    description: 'primer retiro de fondos del 10%',
    longDescription: '1er retiro de fondos de pensiones',
    abbreviatedName: 'primer retiro',
    abbreviatedNumberName: '1er retiro',
    styleClass: 'first-withdrawal'
  },
  [GENERIC_WITHDRAWAL_NUMBER.SECOND]: {
    description: 'segundo retiro de fondos del 10%',
    longDescription: '2do retiro de fondos de pensiones',
    abbreviatedName: 'segundo retiro',
    abbreviatedNumberName: '2do retiro',
    styleClass: 'second-withdrawal'
  },
  [GENERIC_WITHDRAWAL_NUMBER.THIRD]: {
    description: 'tercer retiro de fondos del 10%',
    longDescription: '3er retiro de fondos de pensiones',
    abbreviatedName: 'tercer retiro',
    abbreviatedNumberName: '3er retiro',
    styleClass: 'third-withdrawal'
  },
  [GENERIC_WITHDRAWAL_NUMBER.FIRST_BONUS]: {
    description: 'Bono de Cargo Fiscal',
    longDescription: 'Bono de Cargo Fiscal de hasta $200.000',
    abbreviatedName: 'Bono de Cargo Fiscal',
    abbreviatedNumberName: 'Bono de Cargo Fiscal',
    styleClass: 'third-withdrawal'
  }
};

export const INFO_BUTTON_FIRST_WITHDRAWAL = {
  color: '#c21b17',
  title: '1er',
  primaryText: 'Retiro 10%',
  secondaryText: 'de fondo de pensiones',
  deadline: 'Hasta el 31 de Julio 2021',
  pathToRedirect: MAIN_ROUTES.FIRST_WITHDRAWAL
};

export const INFO_BUTTON_SECOND_WITHDRAWAL = {
  color: '#9b2743',
  title: '2do',
  primaryText: 'Retiro 10%',
  secondaryText: 'de fondo de pensiones',
  deadline: 'Hasta el 10 de Dic. 2021',
  pathToRedirect: MAIN_ROUTES.SECOND_WITHDRAWAL
};

export const INFO_BUTTON_THIRD_WITHDRAWAL = {
  color: '#752127',
  title: '3er',
  primaryText: 'Retiro 10%',
  secondaryText: 'de fondo de pensiones',
  deadline: 'Hasta el 28 de Abril 2022',
  pathToRedirect: MAIN_ROUTES.THIRD_WITHDRAWAL
};
