import { environment } from '@environment';

export const QUALITY_SURVEY_URL = 'https://es.research.net/r/spensiones';
export const CONSULT_AFFILIATION_URL = 'https://www.spensiones.cl/apps/certificados/formConsultaAfiliacion.php';
export const BRANCH_OFFICES_URL = `${environment.publicSiteUrl}sucursales`;
export const PUBLIC_SITE_FAQS_URL = `${environment.publicSiteUrl}preguntas-frecuentes`;

export const SOCIAL_URL = {
  facebook: 'https://www.facebook.com/AFPPlanVital/?fref=ts',
  twitter: 'https://twitter.com/planvitalafp',
  instagram: 'https://www.instagram.com/planvitalafp/?hl=es-la',
  linkedin: 'https://www.linkedin.com/company/afp-planvital',
  youtube: 'https://www.youtube.com/channel/UCZOAjIwHUEJeBXaFXz5c-dA',
};

export const PENSIONS_URL = 'https://www.spensiones.cl';
export const LIAISON_BODYS_URL = 'https://www.spensiones.cl/portal/institucional/594/w3-propertyvalue-5936.html';

export const CMS_URL = {
  GENERAL_INFO: 'items/informacion_general/1',
  WITHDRAWALS: 'items/ret_retiros',
  HOME: 'items/ret_home',
  REQUEST_FORM_INFO: 'items/ret_info_formulario_solicitud',
};
