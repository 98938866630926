import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { DEFINITELY_UNAVAILABLE_WITHDRAWALS } from '@constants/funds-withdrawal.constant';
import { MAIN_ROUTES } from '@constants/routes.constant';
import { WithdrawalsService } from '@services/withdrawals/withdrawals.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EnabledWithdrawalGuard implements CanActivate {
  constructor(private router: Router, private withdrawalsService: WithdrawalsService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const withdrawalNumber = route.data.withdrawalNumber;
    let isWithdrawalAvailable: boolean;
    return this.withdrawalsService.checkIfWithdrawalIsEnabled(withdrawalNumber).pipe(
      map(response => {
        isWithdrawalAvailable = response && response.available;
        if (!isWithdrawalAvailable) { this.router.navigateByUrl(MAIN_ROUTES.HOME_LANDING); }
        return isWithdrawalAvailable;
      }),
      catchError((error) => {
        isWithdrawalAvailable = !DEFINITELY_UNAVAILABLE_WITHDRAWALS.includes(withdrawalNumber);
        if (!isWithdrawalAvailable) { this.router.navigateByUrl(MAIN_ROUTES.HOME_LANDING); }
        return throwError(error);
      })
    );
  }
}
