import { HttpErrorCodes } from '@interfaces/http-error-codes.interface';

export const httpErrorCodes: HttpErrorCodes = {
  unauthorized: {
    code: 401,
    message: 'No autorizado',
    messageDescription: 'Usuario sin autorización.'
  },
  internalServerError: {
    code: 500,
    message: 'Error',
    messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
  },
  badRequest: {
    code: 400,
    message: 'Bad request',
    messageDescription: 'Hubo un error en la petición al servidor.'
  },
};
