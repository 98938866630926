import { Mockup } from '@interfaces/mockup.interface';
import { PaymentMethods } from '@interfaces/parameters.interface';

export const PAYMENT_METHODS_MOCKUP: Mockup = {
  success: [{
    response: {
      banks: [
        {
          description: 'ABN AMRO BANK (CHILE)',
          code: '046'
        },
        {
          code: '028',
          description: 'BANCO BICE'
        },
        {
          description: 'BANCO DE CHILE',
          code: '001'
        },
        {
          description: 'BANCO DE CREDITO E INVERSIONES',
          code: '016'
        },
        {
          description: 'BANCO DEL DESARROLLO',
          code: '507'
        },
        {
          code: '012',
          description: 'BANCO DEL ESTADO DE CHILE'
        },
        {
          code: '051',
          description: 'BANCO FALABELLA'
        },
        {
          code: '009',
          description: 'BANCO INTERNACIONAL'
        },
        {
          description: 'BANCO ITAU CHILE',
          code: '039'
        },
        {
          description: 'BANCO RIPLEY',
          code: '053'
        },
        {
          description: 'BANCO SANTANDER-CHILE',
          code: '037'
        },
        {
          description: 'BANCO SECURITY',
          code: '049'
        },
        {
          description: 'CORPBANCA',
          code: '027'
        },
        {
          description: 'Caja de compensación Los Héroes',
          code: '303'
        },
        {
          description: 'Coopeuch (Cooperativa de Ahorro y Crédito)',
          code: '672'
        },
        {
          code: '054',
          description: 'HNS BANCO'
        },
        {
          description: 'SCOTIABANK',
          code: '014'
        }
      ],
      accountTypes: [
        {
          id: 3,
          description: 'Cuenta de ahorro',
          code: '02'
        },
        {
          id: 2,
          code: '01',
          description: 'Cuenta vista'
        },
        {
          id: 1,
          code: '01',
          description: 'Cuenta corriente'
        },
        {
          description: 'Cuenta rut',
          id: 4,
          code: '30'
        }
      ]
    } as PaymentMethods
  }],
  failures: [{
    response: {}
  }]
};
