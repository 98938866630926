import { Component, OnInit } from '@angular/core';
import * as WITHDRAWAL from '@constants/funds-withdrawal.constant';
import { ModalData } from '@interfaces/modal-data.interface';
import { ModalService } from '@providers/modal/modal';

@Component({
  selector: 'app-foreign-document-info',
  templateUrl: './foreign-document-info.component.html',
  styleUrls: ['./foreign-document-info.component.scss'],
})
export class ForeignDocumentInfoComponent implements OnInit {

  public fileList = WITHDRAWAL.REQUIERED_DOCUMENTS_FOR_FOREIGN_WITHDRAWAL;

  constructor(private modalProvider: ModalService) { }

  ngOnInit() {}

  public openDocumentInfoModal(id: number) {
    const modalData = {
      iconName: 'i-info',
      title: 'Información Adicional',
      message: WITHDRAWAL.REQUIERED_DOCUMENTS_FOR_FOREIGN_WITHDRAWAL_DESCRIPTIONS[id],
      firstBtnText: 'Volver',
      primaryCallback: () => { },
    } as ModalData;
    this.modalProvider.openModal(modalData);
  }

}
