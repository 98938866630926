import { httpErrorCodes } from '@constants/http.constant';
import { Observable, throwError } from 'rxjs';

export class ErrorUtils {

  public generalCatchError(error): Observable<never> {
    return (error.status === httpErrorCodes.badRequest.code) ?
      throwError(error) : this.handleError(error);
  }

  private handleError(error: any): Observable<never> {
    return throwError(this.getErrorMessage(error));
  }

  private getErrorMessage(error: any) {
    const responseObject = {
      statusCode: httpErrorCodes.internalServerError.code,
      message: httpErrorCodes.internalServerError.message,
      messageDescription: httpErrorCodes.internalServerError.messageDescription,
    };

    if (error && error.hasOwnProperty('status')) {
      responseObject.statusCode = error.status;
      if (error.status === httpErrorCodes.unauthorized.code) {
        responseObject.message = httpErrorCodes.unauthorized.message;
        responseObject.messageDescription = httpErrorCodes.unauthorized.messageDescription;
      }
    }
    return responseObject;
  }
}
