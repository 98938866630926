import { formatDate, formatNumber } from '@angular/common';
import { Injectable, SimpleChange } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NOT_ANNULABLE_WITHDRAWAL_ROUTES } from '@constants/routes.constant';
import { VIRTUAL_ACCOUNTS_BANKS } from '@constants/general.constant';
import {
  getCancelRoute, getCheckStatusRoute, getContactRoute, getFAQSRoute,
  getForeignFAQSRoute, getForeignRoute, MAIN_ROUTES, WITHDRAWAL_KEYS_TO_NUMBERS
} from '@constants/routes.constant';
import { FormFiles } from '@interfaces/form-files';
import { WithdrawalKey, WithdrawalType } from '@interfaces/funds-withdrawal.interface';
import { PrimaryOption } from '@interfaces/header.interface';
import { Region } from '@interfaces/parameters.interface';
import { EmailDomainValidator } from '@providers/email-domain-validator/email-domain-validator.service';
import * as fileSaver from 'file-saver';
import { clean, format as rutFormat } from 'rut.js';

@Injectable({
  providedIn: 'root'
})
export class Utils {

  constructor(
    private emailDomainValidator: EmailDomainValidator,
    private sanitizer: DomSanitizer,
  ) { }

  public downloadFile(url: string, title?: string) {
    return title ? fileSaver.saveAs(url, title) : fileSaver.saveAs(url);
  }

  public rutFormat(unformattedRut: string) {
    unformattedRut = clean(unformattedRut);
    return rutFormat(unformattedRut);
  }

  public rutClean(rut: string) {
    return clean(rut);
  }

  public xor(x: boolean, y: boolean) {
    return (!x && y) || (x && !y);
  }

  public disableFields(form: FormGroup, fieldNames: Array<string>, reset: boolean = true) {
    fieldNames.forEach((field) => {
      const control = form.get(field);
      if (reset) { control.reset(); }
      control.disable();
    });
  }

  public enableFields(form: FormGroup, fieldNames: Array<string>) {
    fieldNames.forEach((field) => {
      const control = form.get(field);
      control.enable();
    });
  }

  public async validateEmailDomain(email: AbstractControl) {
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email.value);
    if (!validDomain) { return email.setErrors({ invalidDomain: true }); }
  }

  public getFormattedDate(date: Date): string {
    return formatDate(date, 'dd-MM-yyyy', 'es-CL');
  }

  public getBankCodeBsb(bankCode: string): string {
    return String(bankCode).padStart(6, '0');
  }

  public inputHasChanged(value: SimpleChange): boolean {
    if (!value) { return false; }
    return JSON.stringify(value.previousValue) !== JSON.stringify(value.currentValue);
  }

  public onlyLettersAndNumbers(name: string): boolean {
    return new RegExp('^[a-zA-Z0-9]+$').test(name);
  }

  /**
   * @description Validate if the account is a virtual account.
   * If the bank is not in the list should return false.
   * If the prefix does not match with the list, should return false,
   * in other case should return true.
   *
   * @param accountNumber accountNumber
   * @param bankCode bankCode
   */
  public isVirtualAccount(accountNumber: string, bankCode: string): boolean {
    const prefix: string = VIRTUAL_ACCOUNTS_BANKS[bankCode];
    if (!prefix) { return false; }
    const accountNumberWithoutZero = accountNumber.replace(/^0+/, '');
    return accountNumberWithoutZero.startsWith(prefix);
  }

  public formatNumber(unformattedNumber: number, precision = 2): string {
    return formatNumber(unformattedNumber, 'es-CL', `1.${precision}-${precision}`);
  }

  public formatCurrency(amount: number, precision = 0): string {
    return '$' + this.formatNumber(amount, precision);
  }

  public createUrl(base64: string): string {
    const blob = this.base64toBlob(base64);
    return URL.createObjectURL(blob);
  }

  public createSecureUrl(base64: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.createUrl(base64));
  }

  public getDownloadPdfLink(base64: string, name: string = ''): HTMLAnchorElement {
    const link = document.createElement('a');
    link.href = this.createUrl(base64);
    link.download = this.getFileName(name);
    document.body.appendChild(link);
    return link;
  }

  public removeDiacritics(str: string): string {
    return str.normalize('NFD').replace(/\p{Diacritic}/gu, '');
  }

  private getFileName(name: string = ''): string {
    const safeName = name.replace(/[/\\?%*:|"<>]/g, '-');
    return `pv_${safeName}_${+new Date()}.pdf`;
  }

  public base64toBlob(base64Data: string, contentType: string = 'application/pdf', sliceSize: number = 512): Blob {
    const byteCharacters = atob(base64Data);

    const byteArrays = this.range(byteCharacters.length / sliceSize + 1)
      .map((digit: number) => digit * sliceSize)
      .map((offset: number) => byteCharacters.slice(offset, offset + sliceSize))
      .map((selectedBytes: string) => Array.from(selectedBytes).map(char => char.charCodeAt(0)))
      .map((byteNumbers: Array<number>) => new Uint8Array(byteNumbers));

    return new Blob(byteArrays, { type: contentType });
  }

  private range(length: number): Array<number> {
    return Array.from({ length }, (value, key) => key);
  }

  public createFormData(object: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();

    for (const property in object) {
      if (!object.hasOwnProperty(property) || this.isNullOrUndefined(object[property])) { continue; }

      const attribute = object[property];

      const formKey = namespace ? `${namespace}[${property}]` : property;

      if (this.isFormFiles(attribute)) {
        attribute.data.forEach((file: string) => formData.append(formKey, file));
      } else if (this.isObjectType(attribute) && !this.isFile(attribute)) {
        this.createFormData(attribute, formData, formKey);
      } else { formData.append(formKey, attribute); }
    }

    return formData;
  }

  public enableControlAndSetValidator(control: string | AbstractControl, validators: ValidatorFn[], form?: FormGroup) {
    if (typeof(control) === 'string') {
      control = form.get(control);
    }
    control.enable();
    control.setValidators(validators);
  }

  public clearControlValidators(control: string | AbstractControl, form?: FormGroup) {
    if (typeof(control) === 'string') {
      control = form.get(control);
    }
    control.clearValidators();
    control.updateValueAndValidity();
  }

  public getCity(regions: Array<Region>, region: string, commune: string): string {
    if (!commune) { return null; }
    const selectedRegion = regions.find(regionOption => regionOption.code === region);
    if (!selectedRegion) { return null; }
    const selectedCommune = selectedRegion.communes.find(communeOption => communeOption.code === commune);
    if (!selectedCommune) { return null; }
    return selectedCommune.city;
  }

  private isFormFiles(object: any): boolean {
    return object instanceof FormFiles;
  }

  private isFile(object: any): boolean {
    return object instanceof File;
  }

  private isObjectType(object: any): boolean {
    return typeof object === 'object';
  }

  private isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  public getWithdrawalMainRoute(url: string): WithdrawalKey {
    const splitPlace = url[0] === '/' ? 1 : 0;
    const withdrawalKey = url.split('/')[splitPlace] as WithdrawalKey;
    return withdrawalKey;
  }

  public getWithdrawalNumber(url: string): WithdrawalType {
    const withdrawalKey = this.getWithdrawalMainRoute(url);
    const withdrawalNumber = WITHDRAWAL_KEYS_TO_NUMBERS[withdrawalKey];
    return withdrawalNumber;
  }

  public getWithdrawalTabs(url: string): PrimaryOption[] {
    const isHomeUrl = url === '/' || url.includes(MAIN_ROUTES.HOME_LANDING);
    const mainWithdrawalRoute = isHomeUrl ? MAIN_ROUTES.FIRST_WITHDRAWAL : this.getWithdrawalMainRoute(url);
    const isForeignBonusUrl = url.includes(getForeignRoute(MAIN_ROUTES.FISCAL_BONUS));
    const faqsRoute = isForeignBonusUrl ? getForeignFAQSRoute(mainWithdrawalRoute) : getFAQSRoute(mainWithdrawalRoute);
    const tabOptions: PrimaryOption[] = [
      { label: 'Inicio', icon: 'i-home-white', route: MAIN_ROUTES.HOME_LANDING },
      { label: 'Consultar solicitud', icon: 'i-requests-white', route: getCheckStatusRoute(mainWithdrawalRoute) },
      { label: 'Anular solicitud', icon: 'i-requests-white', route: getCancelRoute(mainWithdrawalRoute) },
      { label: 'Preguntas frecuentes', icon: 'i-clipboard-white', route: faqsRoute },
      { label: 'Ayuda', icon: 'i-speechbubble-white', route: getContactRoute(mainWithdrawalRoute) },
    ];

    if (NOT_ANNULABLE_WITHDRAWAL_ROUTES.includes(mainWithdrawalRoute)) { tabOptions.splice(2, 1); }

    return tabOptions;
  }
}
