// tslint:disable: ban-types

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ModalData } from '@interfaces/modal-data.interface';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  public title: string;
  public message: string;
  public firstBtnText: string;
  public secondBtnText: string;
  private primaryCallback: Function;
  private secondaryCallback: Function;
  private closeCallback: Function;
  public showSecondaryButton = false;
  public iconName: string;
  public images: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
    if (!data) { return; }

    Object.keys(data).forEach(key => this[key] = data[key]);
    if (data.secondaryCallback) { this.showSecondaryButton = true; }
    if (data.images) { this.images = data.images; }
  }

  public primaryAction(): void {
    this.closeDialog();
    this.primaryCallback();
  }

  public secondaryAction(): void {
    this.closeDialog();
    this.secondaryCallback();
  }

  public closeDialog() {
    this.dialogRef.close();
    if (this.closeCallback) { this.closeCallback(); }
  }
}
