import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TITLE_MODAL_BONUS_CONFIRMATION } from '@constants/fiscal-bonus.constant';
import { ACCOUNTS_NAMES, PAYMENT_METHODS, TITLE_MODAL_CONFIRMATION, TRANSFER_ID } from '@constants/funds-withdrawal.constant';
import { ConfirmationData } from '@interfaces/funds-withdrawal.interface';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData,
  ) { }

  public get isTransferOption() {
    return this.data.paymentMethod === TRANSFER_ID;
  }

  public get clientId() {
    const { rut, nic } = this.data;
    return rut || nic;
  }

  public get labelId() {
    const { rut } = this.data;
    return rut ? 'RUT' : 'NIC';
  }

  public get isBeneficiary() {
    return this.data.isBeneficiary;
  }

  public get isWithdrawalAvailableBonus() {
    return this.data.origin === 'bono';
  }

  public get isExpressForm() {
    return this.data.isExpressForm;
  }

  public get origin() {
    const { accounts } = this.data;
    return `Cuenta ${ ACCOUNTS_NAMES[accounts[0]] }${ accounts.length === 2 ? ` y ${ ACCOUNTS_NAMES[accounts[1]] }` : '' }`;
  }

  public get transferDetails() {
    const { accountTypes } = this.data.paymentMethods;
    const { paymentMethod, accountType, bankName } = this.data;

    const methodName = PAYMENT_METHODS.find((method) => method.id === paymentMethod).name;
    const accountTypeName = accountType ? accountTypes.find((type) => {
      if (typeof (accountType) === 'string') { return type.code === accountType; }
      return type.id === accountType;
    }).description : '';
    const parsedBankName = bankName ? bankName.toLowerCase() : '';

    return { methodName, accountTypeName, bankName: parsedBankName };
  }

  public closeDialog(callback?: boolean) {
    this.dialogRef.close(callback);
  }

  public get titleModal() {
    return this.isWithdrawalAvailableBonus ? TITLE_MODAL_BONUS_CONFIRMATION : TITLE_MODAL_CONFIRMATION;
  }

}
