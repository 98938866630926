import { Injectable } from '@angular/core';
import * as cmsConstants from '@constants/cms.constant';
import { environment } from '@environment';
import { BannerInfo, FaqInfo, GeneralInfo, MoreInformationInfo, WithdrawalFormInfo } from '@interfaces/components.interface';
import { WithdrawalType } from '@interfaces/funds-withdrawal.interface';
import { CmsUtils } from '@utils/cms.utils';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {
  public cmsApiUrl: string = environment.cmsApiUrl;

  constructor(
    private cmsUtils: CmsUtils,
  ) { }

  public getGeneralInfo(language = 'es'): Observable<GeneralInfo> {
    return of(this.cmsUtils.mapGeneralInfo(cmsConstants.GENERAL_INFO_RESPONSE.data, language));
  }

  public getFaqInfo(withdrawalNumber: WithdrawalType): Observable<Array<FaqInfo>> {
    return of(this.cmsUtils.mapFaqInfo(cmsConstants.FAQ_INFO_RESPONSE.data, withdrawalNumber));
  }

  public getLandingFaqInfo(withdrawalNumber: WithdrawalType): Observable<Array<FaqInfo>> {
    return of(this.cmsUtils.mapLandingFaqs(cmsConstants.LANDING_FAQS_INFO_RESPONSE.data, withdrawalNumber));
  }

  public getMoreInfoByWithdrawal(withdrawalNumber): Observable<Array<MoreInformationInfo>> {
    return of(this.cmsUtils.mapWithdrawalMoreInformation(cmsConstants.MORE_INFO_BY_WITHDRAWAL_RESPONSE.data, withdrawalNumber));
  }

  public getHomeMoreInfo(): Observable<Array<MoreInformationInfo>> {
    return of(this.cmsUtils.mapHomeMoreInformation(cmsConstants.HOME_MORE_INFO_RESPONSE));
  }

  public getWithdrawalsBannerInfo(): Observable<BannerInfo[]> {
    return of(this.cmsUtils.mapBannerInfo(cmsConstants.WITHDRAWAL_BANNER_INFO_RESPONSE.data));
  }

  public getHomeBanner(): Observable<BannerInfo> {
    return of(this.cmsUtils.mapHomeBannerInfo(cmsConstants.HOME_BANNER_RESPONSE));
  }

  public getRequestFormInfo(withdrawalNumber: WithdrawalType): Observable<WithdrawalFormInfo> {
    return of(this.cmsUtils.mapWithdrawalFormTextInfo(cmsConstants.REQUEST_FORM_INFO_RESPONSE.data, withdrawalNumber));
  }
}

