import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalComponent } from '@components/modal/modal.component';
import { CONTACT_CENTER } from '@constants/general.constant';
import { CALL_CENTER_MODAL_CONTENT } from '@constants/pages-content/help-button.constant';
import { ModalData } from '@interfaces/modal-data.interface';
import { FontService } from '@providers/font/font.service';
import { ComponentsService } from '@services/cms/components.service';

@Component({
  selector: 'app-call-center-modal',
  templateUrl: './call-center-modal.component.html',
  styleUrls: ['./call-center-modal.component.scss'],
})
export class CallCenterModalComponent extends ModalComponent {
  public content = CALL_CENTER_MODAL_CONTENT;
  public contactCenterNumber = CONTACT_CENTER.NUMBER;
  public callCenterHours: string;

  constructor(
    dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: ModalData,
    public font: FontService,
    private componentsService: ComponentsService,
  ) {
    super(dialogRef, data);
    this.getCallCenterHours();
  }

  private getCallCenterHours() {
    this.componentsService.getGeneralInfo()
      .subscribe((response) => this.callCenterHours = response.callCenterHours);
  }
}
