import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PAYMENT_METHODS_FOREIGN } from '@constants/funds-withdrawal.constant';
import { ForeignConfirmationData } from '@interfaces/foreign-funds-withdrawal.interface';

@Component({
  selector: 'app-foreign-confirmation-modal',
  templateUrl: './foreign-confirmation-modal.component.html',
  styleUrls: ['./foreign-confirmation-modal.component.scss']
})
export class ForeignConfirmationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ForeignConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ForeignConfirmationData,
  ) { }

  public get paymentMethodName() {
    const { paymentMethod } = this.data;
    return PAYMENT_METHODS_FOREIGN.find((method) => method.id === paymentMethod).name;
  }

  public get bankCodes() {
    const { bankCodes } = this.data;
    const bankCodesArray = [];
    Object.keys(bankCodes).forEach((key) => {
      if (bankCodes[key]) {
        bankCodesArray.push({ code: key, value: bankCodes[key] });
      }
    });
    return bankCodesArray;
  }

  public closeDialog(callback?: boolean) {
    this.dialogRef.close(callback);
  }
}
