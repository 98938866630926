export const FEMALE_BALANCE_VALUES = [
  {
    id: 25,
    values: [
      '$399.956 y $799.912',
      '$799.912 y $1.199.867',
      '$1.199.867 y $1.599.823',
      '$1.599.823 y $1.999.779',
      '$1.999.779 y $2.399.735',
      '$2.399.735 y $2.799.691',
      '$2.799.691 y $3.199.647',
      '$3.199.647 y $3.599.602',
      '$3.599.602 y $3.999.558',
      '$3.999.558 y $4.999.448',
      '$4.999.448 y $5.999.337',
      '$5.999.337 y $6.999.227',
      '$6.999.227 y $7.999.116',
      '$7.999.116 y $9.998.896',
      '$9.998.896 y $11.998.675',
      '$11.998.675 y $13.998.454',
      '$13.998.454 y $15.998.233',
      '$15.998.233 y $17.694.524'
    ]
  },
  {
    id: 26,
    values: [
      '$384.425 y $768.850',
      '$768.850 y $1.153.275',
      '$1.153.275 y $1.537.700',
      '$1.537.700 y $1.922.125',
      '$1.922.125 y $2.306.550',
      '$2.306.550 y $2.690.975',
      '$2.690.975 y $3.075.400',
      '$3.075.400 y $3.459.825',
      '$3.459.825 y $3.844.251',
      '$3.844.251 y $4.805.313',
      '$4.805.313 y $5.766.376',
      '$5.766.376 y $6.727.438',
      '$6.727.438 y $7.688.501',
      '$7.688.501 y $9.610.626',
      '$9.610.626 y $11.532.752',
      '$11.532.752 y $13.454.877',
      '$13.454.877 y $15.377.002',
      '$15.377.002 y $17.007.424'
    ]
  },
  {
    id: 27,
    values: [
      '$369.497 y $738.995',
      '$738.995 y $1.108.492',
      '$1.108.492 y $1.477.989',
      '$1.477.989 y $1.847.487',
      '$1.847.487 y $2.216.984',
      '$2.216.984 y $2.586.481',
      '$2.586.481 y $2.955.979',
      '$2.955.979 y $3.325.476',
      '$3.325.476 y $3.694.974',
      '$3.694.974 y $4.618.717',
      '$4.618.717 y $5.542.460',
      '$5.542.460 y $6.466.204',
      '$6.466.204 y $7.389.947',
      '$7.389.947 y $9.237.434',
      '$9.237.434 y $11.084.921',
      '$11.084.921 y $12.932.407',
      '$12.932.407 y $14.779.894',
      '$14.779.894 y $16.347.005'
    ]
  },
  {
    id: 28,
    values: [
      '$355.149 y $710.299',
      '$710.299 y $1.065.448',
      '$1.065.448 y $1.420.597',
      '$1.420.597 y $1.775.747',
      '$1.775.747 y $2.130.896',
      '$2.130.896 y $2.486.045',
      '$2.486.045 y $2.841.195',
      '$2.841.195 y $3.196.344',
      '$3.196.344 y $3.551.493',
      '$3.551.493 y $4.439.367',
      '$4.439.367 y $5.327.240',
      '$5.327.240 y $6.215.113',
      '$6.215.113 y $7.102.986',
      '$7.102.986 y $8.878.733',
      '$8.878.733 y $10.654.480',
      '$10.654.480 y $12.430.226',
      '$12.430.226 y $14.205.973',
      '$14.205.973 y $15.712.231'
    ]
  },
  {
    id: 29,
    values: [
      '$341.358 y $682.717',
      '$682.717 y $1.024.075',
      '$1.024.075 y $1.365.434',
      '$1.365.434 y $1.706.792',
      '$1.706.792 y $2.048.151',
      '$2.048.151 y $2.389.509',
      '$2.389.509 y $2.730.868',
      '$2.730.868 y $3.072.226',
      '$3.072.226 y $3.413.584',
      '$3.413.584 y $4.266.981',
      '$4.266.981 y $5.120.377',
      '$5.120.377 y $5.973.773',
      '$5.973.773 y $6.827.169',
      '$6.827.169 y $8.533.961',
      '$8.533.961 y $10.240.753',
      '$10.240.753 y $11.947.546',
      '$11.947.546 y $13.654.338',
      '$13.654.338 y $15.102.105'
    ]
  },
  {
    id: 30,
    values: [
      '$328.103 y $656.206',
      '$656.206 y $984.309',
      '$984.309 y $1.312.412',
      '$1.312.412 y $1.640.515',
      '$1.640.515 y $1.968.618',
      '$1.968.618 y $2.296.722',
      '$2.296.722 y $2.624.825',
      '$2.624.825 y $2.952.928',
      '$2.952.928 y $3.281.031',
      '$3.281.031 y $4.101.288',
      '$4.101.288 y $4.921.546',
      '$4.921.546 y $5.741.804',
      '$5.741.804 y $6.562.062',
      '$6.562.062 y $8.202.577',
      '$8.202.577 y $9.843.092',
      '$9.843.092 y $11.483.608',
      '$11.483.608 y $13.124.123',
      '$13.124.123 y $14.515.672'
    ]
  },
  {
    id: 31,
    values: [
      '$315.362 y $630.725',
      '$630.725 y $946.087',
      '$946.087 y $1.261.450',
      '$1.261.450 y $1.576.812',
      '$1.576.812 y $1.892.175',
      '$1.892.175 y $2.207.537',
      '$2.207.537 y $2.522.899',
      '$2.522.899 y $2.838.262',
      '$2.838.262 y $3.153.624',
      '$3.153.624 y $3.942.030',
      '$3.942.030 y $4.730.437',
      '$4.730.437 y $5.518.843',
      '$5.518.843 y $6.307.249',
      '$6.307.249 y $7.884.061',
      '$7.884.061 y $9.460.873',
      '$9.460.873 y $11.037.685',
      '$11.037.685 y $12.614.497',
      '$12.614.497 y $13.952.011'
    ]
  },
  {
    id: 32,
    values: [
      '$303.117 y $606.233',
      '$606.233 y $909.350',
      '$909.350 y $1.212.466',
      '$1.212.466 y $1.515.583',
      '$1.515.583 y $1.818.699',
      '$1.818.699 y $2.121.816',
      '$2.121.816 y $2.424.932',
      '$2.424.932 y $2.728.049',
      '$2.728.049 y $3.031.165',
      '$3.031.165 y $3.788.957',
      '$3.788.957 y $4.546.748',
      '$4.546.748 y $5.304.539',
      '$5.304.539 y $6.062.331',
      '$6.062.331 y $7.577.913',
      '$7.577.913 y $9.093.496',
      '$9.093.496 y $10.609.079',
      '$10.609.079 y $12.124.661',
      '$12.124.661 y $13.410.237'
    ]
  },
  {
    id: 33,
    values: [
      '$291.346 y $582.692',
      '$582.692 y $874.038',
      '$874.038 y $1.165.385',
      '$1.165.385 y $1.456.731',
      '$1.456.731 y $1.748.077',
      '$1.748.077 y $2.039.423',
      '$2.039.423 y $2.330.769',
      '$2.330.769 y $2.622.115',
      '$2.622.115 y $2.913.461',
      '$2.913.461 y $3.641.827',
      '$3.641.827 y $4.370.192',
      '$4.370.192 y $5.098.558',
      '$5.098.558 y $5.826.923',
      '$5.826.923 y $7.283.654',
      '$7.283.654 y $8.740.384',
      '$8.740.384 y $10.197.115',
      '$10.197.115 y $11.653.846',
      '$11.653.846 y $12.889.502'
    ]
  },
  {
    id: 34,
    values: [
      '$280.033 y $560.066',
      '$560.066 y $840.098',
      '$840.098 y $1.120.131',
      '$1.120.131 y $1.400.164',
      '$1.400.164 y $1.680.197',
      '$1.680.197 y $1.960.230',
      '$1.960.230 y $2.240.263',
      '$2.240.263 y $2.520.295',
      '$2.520.295 y $2.800.328',
      '$2.800.328 y $3.500.410',
      '$3.500.410 y $4.200.492',
      '$4.200.492 y $4.900.574',
      '$4.900.574 y $5.600.656',
      '$5.600.656 y $7.000.820',
      '$7.000.820 y $8.400.985',
      '$8.400.985 y $9.801.149',
      '$9.801.149 y $11.201.313',
      '$11.201.313 y $12.388.987'
    ]
  },
  {
    id: 35,
    values: [
      '$269.159 y $538.318',
      '$538.318 y $807.476',
      '$807.476 y $1.076.635',
      '$1.076.635 y $1.345.794',
      '$1.345.794 y $1.614.953',
      '$1.614.953 y $1.884.112',
      '$1.884.112 y $2.153.270',
      '$2.153.270 y $2.422.429',
      '$2.422.429 y $2.691.588',
      '$2.691.588 y $3.364.485',
      '$3.364.485 y $4.037.382',
      '$4.037.382 y $4.710.279',
      '$4.710.279 y $5.383.176',
      '$5.383.176 y $6.728.970',
      '$6.728.970 y $8.074.764',
      '$8.074.764 y $9.420.558',
      '$9.420.558 y $10.766.352',
      '$10.766.352 y $11.907.907'
    ]
  },
  {
    id: 36,
    values: [
      '$258.707 y $517.414',
      '$517.414 y $776.121',
      '$776.121 y $1.034.828',
      '$1.034.828 y $1.293.535',
      '$1.293.535 y $1.552.242',
      '$1.552.242 y $1.810.949',
      '$1.810.949 y $2.069.656',
      '$2.069.656 y $2.328.363',
      '$2.328.363 y $2.587.070',
      '$2.587.070 y $3.233.838',
      '$3.233.838 y $3.880.606',
      '$3.880.606 y $4.527.373',
      '$4.527.373 y $5.174.141',
      '$5.174.141 y $6.467.676',
      '$6.467.676 y $7.761.211',
      '$7.761.211 y $9.054.746',
      '$9.054.746 y $10.348.282',
      '$10.348.282 y $11.445.509'
    ]
  },
  {
    id: 37,
    values: [
      '$248.661 y $497.322',
      '$497.322 y $745.983',
      '$745.983 y $994.645',
      '$994.645 y $1.243.306',
      '$1.243.306 y $1.491.967',
      '$1.491.967 y $1.740.628',
      '$1.740.628 y $1.989.289',
      '$1.989.289 y $2.237.950',
      '$2.237.950 y $2.486.611',
      '$2.486.611 y $3.108.264',
      '$3.108.264 y $3.729.917',
      '$3.729.917 y $4.351.570',
      '$4.351.570 y $4.973.223',
      '$4.973.223 y $6.216.528',
      '$6.216.528 y $7.459.834',
      '$7.459.834 y $8.703.140',
      '$8.703.140 y $9.946.445',
      '$9.946.445 y $11.001.065'
    ]
  },
  {
    id: 38,
    values: [
      '$239.005 y $478.011',
      '$478.011 y $717.016',
      '$717.016 y $956.021',
      '$956.021 y $1.195.027',
      '$1.195.027 y $1.434.032',
      '$1.434.032 y $1.673.037',
      '$1.673.037 y $1.912.043',
      '$1.912.043 y $2.151.048',
      '$2.151.048 y $2.390.053',
      '$2.390.053 y $2.987.566',
      '$2.987.566 y $3.585.080',
      '$3.585.080 y $4.182.593',
      '$4.182.593 y $4.780.106',
      '$4.780.106 y $5.975.133',
      '$5.975.133 y $7.170.159',
      '$7.170.159 y $8.365.186',
      '$8.365.186 y $9.560.213',
      '$9.560.213 y $10.573.881'
    ]
  },
  {
    id: 39,
    values: [
      '$229.724 y $459.449',
      '$459.449 y $689.173',
      '$689.173 y $918.898',
      '$918.898 y $1.148.622',
      '$1.148.622 y $1.378.347',
      '$1.378.347 y $1.608.071',
      '$1.608.071 y $1.837.796',
      '$1.837.796 y $2.067.520',
      '$2.067.520 y $2.297.244',
      '$2.297.244 y $2.871.556',
      '$2.871.556 y $3.445.867',
      '$3.445.867 y $4.020.178',
      '$4.020.178 y $4.594.489',
      '$4.594.489 y $5.743.111',
      '$5.743.111 y $6.891.733',
      '$6.891.733 y $8.040.356',
      '$8.040.356 y $9.188.978',
      '$9.188.978 y $10.163.284'
    ]
  },
  {
    id: 40,
    values: [
      '$220.804 y $441.608',
      '$441.608 y $662.412',
      '$662.412 y $883.216',
      '$883.216 y $1.104.020',
      '$1.104.020 y $1.324.824',
      '$1.324.824 y $1.545.628',
      '$1.545.628 y $1.766.432',
      '$1.766.432 y $1.987.236',
      '$1.987.236 y $2.208.040',
      '$2.208.040 y $2.760.050',
      '$2.760.050 y $3.312.059',
      '$3.312.059 y $3.864.069',
      '$3.864.069 y $4.416.079',
      '$4.416.079 y $5.520.099',
      '$5.520.099 y $6.624.119',
      '$6.624.119 y $7.728.139',
      '$7.728.139 y $8.832.159',
      '$8.832.159 y $9.768.631'
    ]
  },
  {
    id: 41,
    values: [
      '$212.230 y $424.460',
      '$424.460 y $636.690',
      '$636.690 y $848.920',
      '$848.920 y $1.061.149',
      '$1.061.149 y $1.273.379',
      '$1.273.379 y $1.485.609',
      '$1.485.609 y $1.697.839',
      '$1.697.839 y $1.910.069',
      '$1.910.069 y $2.122.299',
      '$2.122.299 y $2.652.873',
      '$2.652.873 y $3.183.448',
      '$3.183.448 y $3.714.023',
      '$3.714.023 y $4.244.598',
      '$4.244.598 y $5.305.747',
      '$5.305.747 y $6.366.896',
      '$6.366.896 y $7.428.046',
      '$7.428.046 y $8.489.195',
      '$8.489.195 y $9.389.303'
    ]
  },
  {
    id: 42,
    values: [
      '$203.989 y $407.977',
      '$407.977 y $611.966',
      '$611.966 y $815.955',
      '$815.955 y $1.019.944',
      '$1.019.944 y $1.223.932',
      '$1.223.932 y $1.427.921',
      '$1.427.921 y $1.631.910',
      '$1.631.910 y $1.835.899',
      '$1.835.899 y $2.039.887',
      '$2.039.887 y $2.549.859',
      '$2.549.859 y $3.059.831',
      '$3.059.831 y $3.569.803',
      '$3.569.803 y $4.079.775',
      '$4.079.775 y $5.099.718',
      '$5.099.718 y $6.119.662',
      '$6.119.662 y $7.139.606',
      '$7.139.606 y $8.159.549',
      '$8.159.549 y $9.024.705'
    ]
  },
  {
    id: 43,
    values: [
      '$196.068 y $392.135',
      '$392.135 y $588.203',
      '$588.203 y $784.270',
      '$784.270 y $980.338',
      '$980.338 y $1.176.406',
      '$1.176.406 y $1.372.473',
      '$1.372.473 y $1.568.541',
      '$1.568.541 y $1.764.608',
      '$1.764.608 y $1.960.676',
      '$1.960.676 y $2.450.845',
      '$2.450.845 y $2.941.014',
      '$2.941.014 y $3.431.183',
      '$3.431.183 y $3.921.352',
      '$3.921.352 y $4.901.690',
      '$4.901.690 y $5.882.028',
      '$5.882.028 y $6.862.366',
      '$6.862.366 y $7.842.704',
      '$7.842.704 y $8.674.265'
    ]
  },
  {
    id: 44,
    values: [
      '$188.454 y $376.908',
      '$376.908 y $565.362',
      '$565.362 y $753.816',
      '$753.816 y $942.270',
      '$942.270 y $1.130.724',
      '$1.130.724 y $1.319.178',
      '$1.319.178 y $1.507.632',
      '$1.507.632 y $1.696.087',
      '$1.696.087 y $1.884.541',
      '$1.884.541 y $2.355.676',
      '$2.355.676 y $2.826.811',
      '$2.826.811 y $3.297.946',
      '$3.297.946 y $3.769.081',
      '$3.769.081 y $4.711.351',
      '$4.711.351 y $5.653.622',
      '$5.653.622 y $6.595.892',
      '$6.595.892 y $7.538.162',
      '$7.538.162 y $8.337.433'
    ]
  },
  {
    id: 45,
    values: [
      '$181.136 y $362.272',
      '$362.272 y $543.408',
      '$543.408 y $724.545',
      '$724.545 y $905.681',
      '$905.681 y $1.086.817',
      '$1.086.817 y $1.267.953',
      '$1.267.953 y $1.449.089',
      '$1.449.089 y $1.630.225',
      '$1.630.225 y $1.811.362',
      '$1.811.362 y $2.264.202',
      '$2.264.202 y $2.717.042',
      '$2.717.042 y $3.169.883',
      '$3.169.883 y $3.622.723',
      '$3.622.723 y $4.528.404',
      '$4.528.404 y $5.434.085',
      '$5.434.085 y $6.339.766',
      '$6.339.766 y $7.245.446',
      '$7.245.446 y $8.013.680'
    ]
  },
  {
    id: 46,
    values: [
      '$174.102 y $348.205',
      '$348.205 y $522.307',
      '$522.307 y $696.410',
      '$696.410 y $870.512',
      '$870.512 y $1.044.615',
      '$1.044.615 y $1.218.717',
      '$1.218.717 y $1.392.819',
      '$1.392.819 y $1.566.922',
      '$1.566.922 y $1.741.024',
      '$1.741.024 y $2.176.280',
      '$2.176.280 y $2.611.536',
      '$2.611.536 y $3.046.792',
      '$3.046.792 y $3.482.048',
      '$3.482.048 y $4.352.561',
      '$4.352.561 y $5.223.073',
      '$5.223.073 y $6.093.585',
      '$6.093.585 y $6.964.097',
      '$6.964.097 y $7.702.499'
    ]
  },
  {
    id: 47,
    values: [
      '$167.342 y $334.684',
      '$334.684 y $502.025',
      '$502.025 y $669.367',
      '$669.367 y $836.709',
      '$836.709 y $1.004.051',
      '$1.004.051 y $1.171.393',
      '$1.171.393 y $1.338.734',
      '$1.338.734 y $1.506.076',
      '$1.506.076 y $1.673.418',
      '$1.673.418 y $2.091.773',
      '$2.091.773 y $2.510.127',
      '$2.510.127 y $2.928.482',
      '$2.928.482 y $3.346.836',
      '$3.346.836 y $4.183.545',
      '$4.183.545 y $5.020.254',
      '$5.020.254 y $5.856.963',
      '$5.856.963 y $6.693.672',
      '$6.693.672 y $7.403.402'
    ]
  },
  {
    id: 48,
    values: [
      '$160.844 y $321.687',
      '$321.687 y $482.531',
      '$482.531 y $643.375',
      '$643.375 y $804.219',
      '$804.219 y $965.062',
      '$965.062 y $1.125.906',
      '$1.125.906 y $1.286.750',
      '$1.286.750 y $1.447.594',
      '$1.447.594 y $1.608.437',
      '$1.608.437 y $2.010.547',
      '$2.010.547 y $2.412.656',
      '$2.412.656 y $2.814.765',
      '$2.814.765 y $3.216.874',
      '$3.216.874 y $4.021.093',
      '$4.021.093 y $4.825.312',
      '$4.825.312 y $5.629.530',
      '$5.629.530 y $6.433.749',
      '$6.433.749 y $7.115.919'
    ]
  },
  {
    id: 49,
    values: [
      '$154.598 y $309.196',
      '$309.196 y $463.794',
      '$463.794 y $618.392',
      '$618.392 y $772.990',
      '$772.990 y $927.588',
      '$927.588 y $1.082.186',
      '$1.082.186 y $1.236.784',
      '$1.236.784 y $1.391.382',
      '$1.391.382 y $1.545.980',
      '$1.545.980 y $1.932.475',
      '$1.932.475 y $2.318.970',
      '$2.318.970 y $2.705.464',
      '$2.705.464 y $3.091.959',
      '$3.091.959 y $3.864.949',
      '$3.864.949 y $4.637.939',
      '$4.637.939 y $5.410.929',
      '$5.410.929 y $6.183.919',
      '$6.183.919 y $6.839.599'
    ]
  },
  {
    id: 50,
    values: [
      '$148.595 y $297.189',
      '$297.189 y $445.784',
      '$445.784 y $594.379',
      '$594.379 y $742.974',
      '$742.974 y $891.568',
      '$891.568 y $1.040.163',
      '$1.040.163 y $1.188.758',
      '$1.188.758 y $1.337.353',
      '$1.337.353 y $1.485.947',
      '$1.485.947 y $1.857.434',
      '$1.857.434 y $2.228.921',
      '$2.228.921 y $2.600.408',
      '$2.600.408 y $2.971.895',
      '$2.971.895 y $3.714.868',
      '$3.714.868 y $4.457.842',
      '$4.457.842 y $5.200.816',
      '$5.200.816 y $5.943.790',
      '$5.943.790 y $6.574.009'
    ]
  },
  {
    id: 51,
    values: [
      '$142.825 y $285.649',
      '$285.649 y $428.474',
      '$428.474 y $571.298',
      '$571.298 y $714.123',
      '$714.123 y $856.948',
      '$856.948 y $999.772',
      '$999.772 y $1.142.597',
      '$1.142.597 y $1.285.422',
      '$1.285.422 y $1.428.246',
      '$1.428.246 y $1.785.308',
      '$1.785.308 y $2.142.369',
      '$2.142.369 y $2.499.431',
      '$2.499.431 y $2.856.492',
      '$2.856.492 y $3.570.616',
      '$3.570.616 y $4.284.739',
      '$4.284.739 y $4.998.862',
      '$4.998.862 y $5.712.985',
      '$5.712.985 y $6.318.732'
    ]
  },
  {
    id: 52,
    values: [
      '$137.279 y $274.557',
      '$274.557 y $411.836',
      '$411.836 y $549.114',
      '$549.114 y $686.393',
      '$686.393 y $823.671',
      '$823.671 y $960.950',
      '$960.950 y $1.098.229',
      '$1.098.229 y $1.235.507',
      '$1.235.507 y $1.372.786',
      '$1.372.786 y $1.715.982',
      '$1.715.982 y $2.059.179',
      '$2.059.179 y $2.402.375',
      '$2.402.375 y $2.745.571',
      '$2.745.571 y $3.431.964',
      '$3.431.964 y $4.118.357',
      '$4.118.357 y $4.804.750',
      '$4.804.750 y $5.491.143',
      '$5.491.143 y $6.073.368'
    ]
  },
  {
    id: 53,
    values: [
      '$131.948 y $263.896',
      '$263.896 y $395.844',
      '$395.844 y $527.792',
      '$527.792 y $659.739',
      '$659.739 y $791.687',
      '$791.687 y $923.635',
      '$923.635 y $1.055.583',
      '$1.055.583 y $1.187.531',
      '$1.187.531 y $1.319.479',
      '$1.319.479 y $1.649.348',
      '$1.649.348 y $1.979.218',
      '$1.979.218 y $2.309.088',
      '$2.309.088 y $2.638.958',
      '$2.638.958 y $3.298.697',
      '$3.298.697 y $3.958.436',
      '$3.958.436 y $4.618.176',
      '$4.618.176 y $5.277.915',
      '$5.277.915 y $5.837.532'
    ]
  },
  {
    id: 54,
    values: [
      '$126.824 y $253.648',
      '$253.648 y $380.473',
      '$380.473 y $507.297',
      '$507.297 y $634.121',
      '$634.121 y $760.945',
      '$760.945 y $887.769',
      '$887.769 y $1.014.593',
      '$1.014.593 y $1.141.418',
      '$1.141.418 y $1.268.242',
      '$1.268.242 y $1.585.302',
      '$1.585.302 y $1.902.363',
      '$1.902.363 y $2.219.423',
      '$2.219.423 y $2.536.484',
      '$2.536.484 y $3.170.604',
      '$3.170.604 y $3.804.725',
      '$3.804.725 y $4.438.846',
      '$4.438.846 y $5.072.967',
      '$5.072.967 y $5.610.853'
    ]
  },
  {
    id: 55,
    values: [
      '$121.899 y $243.799',
      '$243.799 y $365.698',
      '$365.698 y $487.598',
      '$487.598 y $609.497',
      '$609.497 y $731.397',
      '$731.397 y $853.296',
      '$853.296 y $975.196',
      '$975.196 y $1.097.095',
      '$1.097.095 y $1.218.994',
      '$1.218.994 y $1.523.743',
      '$1.523.743 y $1.828.492',
      '$1.828.492 y $2.133.240',
      '$2.133.240 y $2.437.989',
      '$2.437.989 y $3.047.486',
      '$3.047.486 y $3.656.983',
      '$3.656.983 y $4.266.480',
      '$4.266.480 y $4.875.978',
      '$4.875.978 y $5.392.977'
    ]
  },
  {
    id: 56,
    values: [
      '$117.166 y $234.332',
      '$234.332 y $351.498',
      '$351.498 y $468.664',
      '$468.664 y $585.830',
      '$585.830 y $702.996',
      '$702.996 y $820.162',
      '$820.162 y $937.328',
      '$937.328 y $1.054.493',
      '$1.054.493 y $1.171.659',
      '$1.171.659 y $1.464.574',
      '$1.464.574 y $1.757.489',
      '$1.757.489 y $2.050.404',
      '$2.050.404 y $2.343.319',
      '$2.343.319 y $2.929.148',
      '$2.929.148 y $3.514.978',
      '$3.514.978 y $4.100.808',
      '$4.100.808 y $4.686.638',
      '$4.686.638 y $5.183.561'
    ]
  },
  {
    id: 57,
    values: [
      '$112.616 y $225.232',
      '$225.232 y $337.849',
      '$337.849 y $450.465',
      '$450.465 y $563.081',
      '$563.081 y $675.697',
      '$675.697 y $788.314',
      '$788.314 y $900.930',
      '$900.930 y $1.013.546',
      '$1.013.546 y $1.126.162',
      '$1.126.162 y $1.407.703',
      '$1.407.703 y $1.689.244',
      '$1.689.244 y $1.970.784',
      '$1.970.784 y $2.252.325',
      '$2.252.325 y $2.815.406',
      '$2.815.406 y $3.378.487',
      '$3.378.487 y $3.941.568',
      '$3.941.568 y $4.504.650',
      '$4.504.650 y $4.982.277'
    ]
  },
  {
    id: 58,
    values: [
      '$108.243 y $216.486',
      '$216.486 y $324.730',
      '$324.730 y $432.973',
      '$432.973 y $541.216',
      '$541.216 y $649.459',
      '$649.459 y $757.703',
      '$757.703 y $865.946',
      '$865.946 y $974.189',
      '$974.189 y $1.082.432',
      '$1.082.432 y $1.353.040',
      '$1.353.040 y $1.623.648',
      '$1.623.648 y $1.894.256',
      '$1.894.256 y $2.164.864',
      '$2.164.864 y $2.706.080',
      '$2.706.080 y $3.247.296',
      '$3.247.296 y $3.788.513',
      '$3.788.513 y $4.329.729',
      '$4.329.729 y $4.788.809'
    ]
  },
  {
    id: 59,
    values: [
      '$104.040 y $208.080',
      '$208.080 y $312.120',
      '$312.120 y $416.160',
      '$416.160 y $520.200',
      '$520.200 y $624.240',
      '$624.240 y $728.280',
      '$728.280 y $832.320',
      '$832.320 y $936.360',
      '$936.360 y $1.040.400',
      '$1.040.400 y $1.300.500',
      '$1.300.500 y $1.560.600',
      '$1.560.600 y $1.820.700',
      '$1.820.700 y $2.080.800',
      '$2.080.800 y $2.601.000',
      '$2.601.000 y $3.121.200',
      '$3.121.200 y $3.641.400',
      '$3.641.400 y $4.161.600',
      '$4.161.600 y $4.602.854'
    ]
  },
  {
    id: 60,
    values: [
      '$100.000 y $200.000',
      '$200.000 y $300.000',
      '$300.000 y $400.000',
      '$400.000 y $500.000',
      '$500.000 y $600.000',
      '$600.000 y $700.000',
      '$700.000 y $800.000',
      '$800.000 y $900.000',
      '$900.000 y $1.000.000',
      '$1.000.000 y $1.250.000',
      '$1.250.000 y $1.500.000',
      '$1.500.000 y $1.750.000',
      '$1.750.000 y $2.000.000',
      '$2.000.000 y $2.500.000',
      '$2.500.000 y $3.000.000',
      '$3.000.000 y $3.500.000',
      '$3.500.000 y $4.000.000',
      '$4.000.000 y $4.424.120'
    ]
  },
];

export const FEMALE_PENSION_VALUES = [
  {
    id: 25,
    values: [
      '$1.501 y $3.002',
      '$3.002 y $4.503',
      '$4.503 y $6.004',
      '$6.004 y $7.505',
      '$7.505 y $9.006',
      '$9.006 y $10.508',
      '$10.508 y $12.009',
      '$12.009 y $13.510',
      '$13.510 y $15.011',
      '$15.011 y $18.763',
      '$18.763 y $22.516',
      '$22.516 y $26.269',
      '$26.269 y $30.021',
      '$30.021 y $37.527',
      '$37.527 y $45.032',
      '$45.032 y $52.538',
      '$52.538 y $60.043',
      '$60.043 y $66.409'
    ]
  },
  {
    id: 26,
    values: [
      '$1.445 y $2.890',
      '$2.890 y $4.335',
      '$4.335 y $5.780',
      '$5.780 y $7.225',
      '$7.225 y $8.671',
      '$8.671 y $10.116',
      '$10.116 y $11.561',
      '$11.561 y $13.006',
      '$13.006 y $14.451',
      '$14.451 y $18.064',
      '$18.064 y $21.676',
      '$21.676 y $25.289',
      '$25.289 y $28.902',
      '$28.902 y $36.127',
      '$36.127 y $43.353',
      '$43.353 y $50.578',
      '$50.578 y $57.804',
      '$57.804 y $63.932'
    ]
  },
  {
    id: 27,
    values: [
      '$1.391 y $2.782',
      '$2.782 y $4.174',
      '$4.174 y $5.565',
      '$5.565 y $6.956',
      '$6.956 y $8.347',
      '$8.347 y $9.739',
      '$9.739 y $11.130',
      '$11.130 y $12.521',
      '$12.521 y $13.912',
      '$13.912 y $17.390',
      '$17.390 y $20.868',
      '$20.868 y $24.346',
      '$24.346 y $27.824',
      '$27.824 y $34.780',
      '$34.780 y $41.737',
      '$41.737 y $48.693',
      '$48.693 y $55.649',
      '$55.649 y $61.549'
    ]
  },
  {
    id: 28,
    values: [
      '$1.339 y $2.679',
      '$2.679 y $4.018',
      '$4.018 y $5.358',
      '$5.358 y $6.697',
      '$6.697 y $8.036',
      '$8.036 y $9.376',
      '$9.376 y $10.715',
      '$10.715 y $12.055',
      '$12.055 y $13.394',
      '$13.394 y $16.742',
      '$16.742 y $20.091',
      '$20.091 y $23.439',
      '$23.439 y $26.788',
      '$26.788 y $33.485',
      '$33.485 y $40.182',
      '$40.182 y $46.879',
      '$46.879 y $53.576',
      '$53.576 y $59.256'
    ]
  },
  {
    id: 29,
    values: [
      '$1.290 y $2.579',
      '$2.579 y $3.869',
      '$3.869 y $5.158',
      '$5.158 y $6.448',
      '$6.448 y $7.737',
      '$7.737 y $9.027',
      '$9.027 y $10.316',
      '$10.316 y $11.606',
      '$11.606 y $12.895',
      '$12.895 y $16.119',
      '$16.119 y $19.343',
      '$19.343 y $22.567',
      '$22.567 y $25.790',
      '$25.790 y $32.238',
      '$32.238 y $38.686',
      '$38.686 y $45.133',
      '$45.133 y $51.581',
      '$51.581 y $57.050'
    ]
  },
  {
    id: 30,
    values: [
      '$1.242 y $2.483',
      '$2.483 y $3.725',
      '$3.725 y $4.966',
      '$4.966 y $6.208',
      '$6.208 y $7.449',
      '$7.449 y $8.691',
      '$8.691 y $9.932',
      '$9.932 y $11.174',
      '$11.174 y $12.415',
      '$12.415 y $15.519',
      '$15.519 y $18.623',
      '$18.623 y $21.727',
      '$21.727 y $24.831',
      '$24.831 y $31.038',
      '$31.038 y $37.246',
      '$37.246 y $43.454',
      '$43.454 y $49.661',
      '$49.661 y $54.927'
    ]
  },
  {
    id: 31,
    values: [
      '$1.195 y $2.391',
      '$2.391 y $3.586',
      '$3.586 y $4.781',
      '$4.781 y $5.977',
      '$5.977 y $7.172',
      '$7.172 y $8.368',
      '$8.368 y $9.563',
      '$9.563 y $10.758',
      '$10.758 y $11.954',
      '$11.954 y $14.942',
      '$14.942 y $17.930',
      '$17.930 y $20.919',
      '$20.919 y $23.907',
      '$23.907 y $29.884',
      '$29.884 y $35.861',
      '$35.861 y $41.838',
      '$41.838 y $47.814',
      '$47.814 y $52.884'
    ]
  },
  {
    id: 32,
    values: [
      '$1.151 y $2.302',
      '$2.302 y $3.453',
      '$3.453 y $4.604',
      '$4.604 y $5.755',
      '$5.755 y $6.906',
      '$6.906 y $8.057',
      '$8.057 y $9.207',
      '$9.207 y $10.358',
      '$10.358 y $11.509',
      '$11.509 y $14.387',
      '$14.387 y $17.264',
      '$17.264 y $20.141',
      '$20.141 y $23.019',
      '$23.019 y $28.773',
      '$28.773 y $34.528',
      '$34.528 y $40.283',
      '$40.283 y $46.037',
      '$46.037 y $50.919'
    ]
  },
  {
    id: 33,
    values: [
      '$1.108 y $2.216',
      '$2.216 y $3.325',
      '$3.325 y $4.433',
      '$4.433 y $5.541',
      '$5.541 y $6.649',
      '$6.649 y $7.757',
      '$7.757 y $8.865',
      '$8.865 y $9.974',
      '$9.974 y $11.082',
      '$11.082 y $13.852',
      '$13.852 y $16.623',
      '$16.623 y $19.393',
      '$19.393 y $22.164',
      '$22.164 y $27.704',
      '$27.704 y $33.245',
      '$33.245 y $38.786',
      '$38.786 y $44.327',
      '$44.327 y $49.027'
    ]
  },
  {
    id: 34,
    values: [
      '$1.067 y $2.134',
      '$2.134 y $3.201',
      '$3.201 y $4.268',
      '$4.268 y $5.335',
      '$5.335 y $6.402',
      '$6.402 y $7.469',
      '$7.469 y $8.536',
      '$8.536 y $9.603',
      '$9.603 y $10.670',
      '$10.670 y $13.338',
      '$13.338 y $16.006',
      '$16.006 y $18.673',
      '$18.673 y $21.341',
      '$21.341 y $26.676',
      '$26.676 y $32.011',
      '$32.011 y $37.346',
      '$37.346 y $42.682',
      '$42.682 y $47.207'
    ]
  },
  {
    id: 35,
    values: [
      '$1.027 y $2.055',
      '$2.055 y $3.082',
      '$3.082 y $4.110',
      '$4.110 y $5.137',
      '$5.137 y $6.165',
      '$6.165 y $7.192',
      '$7.192 y $8.220',
      '$8.220 y $9.247',
      '$9.247 y $10.275',
      '$10.275 y $12.843',
      '$12.843 y $15.412',
      '$15.412 y $17.980',
      '$17.980 y $20.549',
      '$20.549 y $25.686',
      '$25.686 y $30.824',
      '$30.824 y $35.961',
      '$35.961 y $41.098',
      '$41.098 y $45.456'
    ]
  },
  {
    id: 36,
    values: [
      '$989 y $1.979',
      '$1.979 y $2.968',
      '$2.968 y $3.957',
      '$3.957 y $4.947',
      '$4.947 y $5.936',
      '$5.936 y $6.926',
      '$6.926 y $7.915',
      '$7.915 y $8.904',
      '$8.904 y $9.894',
      '$9.894 y $12.367',
      '$12.367 y $14.840',
      '$14.840 y $17.314',
      '$17.314 y $19.787',
      '$19.787 y $24.734',
      '$24.734 y $29.681',
      '$29.681 y $34.628',
      '$34.628 y $39.575',
      '$39.575 y $43.771'
    ]
  },
  {
    id: 37,
    values: [
      '$953 y $1.905',
      '$1.905 y $2.858',
      '$2.858 y $3.811',
      '$3.811 y $4.764',
      '$4.764 y $5.716',
      '$5.716 y $6.669',
      '$6.669 y $7.622',
      '$7.622 y $8.574',
      '$8.574 y $9.527',
      '$9.527 y $11.909',
      '$11.909 y $14.291',
      '$14.291 y $16.672',
      '$16.672 y $19.054',
      '$19.054 y $23.818',
      '$23.818 y $28.581',
      '$28.581 y $33.345',
      '$33.345 y $38.108',
      '$38.108 y $42.149'
    ]
  },
  {
    id: 38,
    values: [
      '$917 y $1.835',
      '$1.835 y $2.752',
      '$2.752 y $3.670',
      '$3.670 y $4.587',
      '$4.587 y $5.505',
      '$5.505 y $6.422',
      '$6.422 y $7.339',
      '$7.339 y $8.257',
      '$8.257 y $9.174',
      '$9.174 y $11.468',
      '$11.468 y $13.761',
      '$13.761 y $16.055',
      '$16.055 y $18.349',
      '$18.349 y $22.936',
      '$22.936 y $27.523',
      '$27.523 y $32.110',
      '$32.110 y $36.697',
      '$36.697 y $40.588'
    ]
  },
  {
    id: 39,
    values: [
      '$883 y $1.767',
      '$1.767 y $2.650',
      '$2.650 y $3.534',
      '$3.534 y $4.417',
      '$4.417 y $5.301',
      '$5.301 y $6.184',
      '$6.184 y $7.068',
      '$7.068 y $7.951',
      '$7.951 y $8.835',
      '$8.835 y $11.044',
      '$11.044 y $13.252',
      '$13.252 y $15.461',
      '$15.461 y $17.670',
      '$17.670 y $22.087',
      '$22.087 y $26.505',
      '$26.505 y $30.922',
      '$30.922 y $35.339',
      '$35.339 y $39.086'
    ]
  },
  {
    id: 40,
    values: [
      '$851 y $1.702',
      '$1.702 y $2.552',
      '$2.552 y $3.403',
      '$3.403 y $4.254',
      '$4.254 y $5.105',
      '$5.105 y $5.956',
      '$5.956 y $6.807',
      '$6.807 y $7.657',
      '$7.657 y $8.508',
      '$8.508 y $10.635',
      '$10.635 y $12.762',
      '$12.762 y $14.889',
      '$14.889 y $17.016',
      '$17.016 y $21.270',
      '$21.270 y $25.525',
      '$25.525 y $29.779',
      '$29.779 y $34.033',
      '$34.033 y $37.641'
    ]
  },
  {
    id: 41,
    values: [
      '$819 y $1.639',
      '$1.639 y $2.458',
      '$2.458 y $3.278',
      '$3.278 y $4.097',
      '$4.097 y $4.916',
      '$4.916 y $5.736',
      '$5.736 y $6.555',
      '$6.555 y $7.374',
      '$7.374 y $8.194',
      '$8.194 y $10.242',
      '$10.242 y $12.291',
      '$12.291 y $14.339',
      '$14.339 y $16.388',
      '$16.388 y $20.484',
      '$20.484 y $24.581',
      '$24.581 y $28.678',
      '$28.678 y $32.775',
      '$32.775 y $36.250'
    ]
  },
  {
    id: 42,
    values: [
      '$789 y $1.578',
      '$1.578 y $2.367',
      '$2.367 y $3.156',
      '$3.156 y $3.946',
      '$3.946 y $4.735',
      '$4.735 y $5.524',
      '$5.524 y $6.313',
      '$6.313 y $7.102',
      '$7.102 y $7.891',
      '$7.891 y $9.864',
      '$9.864 y $11.837',
      '$11.837 y $13.810',
      '$13.810 y $15.782',
      '$15.782 y $19.728',
      '$19.728 y $23.674',
      '$23.674 y $27.619',
      '$27.619 y $31.565',
      '$31.565 y $34.912'
    ]
  },
  {
    id: 43,
    values: [
      '$760 y $1.520',
      '$1.520 y $2.280',
      '$2.280 y $3.040',
      '$3.040 y $3.800',
      '$3.800 y $4.560',
      '$4.560 y $5.320',
      '$5.320 y $6.080',
      '$6.080 y $6.840',
      '$6.840 y $7.600',
      '$7.600 y $9.500',
      '$9.500 y $11.400',
      '$11.400 y $13.300',
      '$13.300 y $15.200',
      '$15.200 y $19.000',
      '$19.000 y $22.800',
      '$22.800 y $26.600',
      '$26.600 y $30.400',
      '$30.400 y $33.624'
    ]
  },
  {
    id: 44,
    values: [
      '$732 y $1.464',
      '$1.464 y $2.196',
      '$2.196 y $2.928',
      '$2.928 y $3.660',
      '$3.660 y $4.392',
      '$4.392 y $5.124',
      '$5.124 y $5.856',
      '$5.856 y $6.588',
      '$6.588 y $7.320',
      '$7.320 y $9.150',
      '$9.150 y $10.980',
      '$10.980 y $12.810',
      '$12.810 y $14.640',
      '$14.640 y $18.300',
      '$18.300 y $21.960',
      '$21.960 y $25.619',
      '$25.619 y $29.279',
      '$29.279 y $32.384'
    ]
  },
  {
    id: 45,
    values: [
      '$705 y $1.410',
      '$1.410 y $2.115',
      '$2.115 y $2.820',
      '$2.820 y $3.525',
      '$3.525 y $4.230',
      '$4.230 y $4.935',
      '$4.935 y $5.640',
      '$5.640 y $6.345',
      '$6.345 y $7.050',
      '$7.050 y $8.813',
      '$8.813 y $10.575',
      '$10.575 y $12.338',
      '$12.338 y $14.100',
      '$14.100 y $17.625',
      '$17.625 y $21.150',
      '$21.150 y $24.675',
      '$24.675 y $28.201',
      '$28.201 y $31.191'
    ]
  },
  {
    id: 46,
    values: [
      '$679 y $1.358',
      '$1.358 y $2.037',
      '$2.037 y $2.716',
      '$2.716 y $3.395',
      '$3.395 y $4.074',
      '$4.074 y $4.753',
      '$4.753 y $5.432',
      '$5.432 y $6.112',
      '$6.112 y $6.791',
      '$6.791 y $8.488',
      '$8.488 y $10.186',
      '$10.186 y $11.884',
      '$11.884 y $13.581',
      '$13.581 y $16.976',
      '$16.976 y $20.372',
      '$20.372 y $23.767',
      '$23.767 y $27.162',
      '$27.162 y $30.042'
    ]
  },
  {
    id: 47,
    values: [
      '$654 y $1.308',
      '$1.308 y $1.962',
      '$1.962 y $2.616',
      '$2.616 y $3.270',
      '$3.270 y $3.924',
      '$3.924 y $4.579',
      '$4.579 y $5.233',
      '$5.233 y $5.887',
      '$5.887 y $6.541',
      '$6.541 y $8.176',
      '$8.176 y $9.811',
      '$9.811 y $11.446',
      '$11.446 y $13.082',
      '$13.082 y $16.352',
      '$16.352 y $19.622',
      '$19.622 y $22.893',
      '$22.893 y $26.163',
      '$26.163 y $28.937'
    ]
  },
  {
    id: 48,
    values: [
      '$630 y $1.260',
      '$1.260 y $1.890',
      '$1.890 y $2.520',
      '$2.520 y $3.150',
      '$3.150 y $3.780',
      '$3.780 y $4.410',
      '$4.410 y $5.040',
      '$5.040 y $5.670',
      '$5.670 y $6.300',
      '$6.300 y $7.875',
      '$7.875 y $9.450',
      '$9.450 y $11.026',
      '$11.026 y $12.601',
      '$12.601 y $15.751',
      '$15.751 y $18.901',
      '$18.901 y $22.051',
      '$22.051 y $25.201',
      '$25.201 y $27.873'
    ]
  },
  {
    id: 49,
    values: [
      '$607 y $1.214',
      '$1.214 y $1.821',
      '$1.821 y $2.428',
      '$2.428 y $3.034',
      '$3.034 y $3.641',
      '$3.641 y $4.248',
      '$4.248 y $4.855',
      '$4.855 y $5.462',
      '$5.462 y $6.069',
      '$6.069 y $7.586',
      '$7.586 y $9.103',
      '$9.103 y $10.621',
      '$10.621 y $12.138',
      '$12.138 y $15.172',
      '$15.172 y $18.207',
      '$18.207 y $21.241',
      '$21.241 y $24.276',
      '$24.276 y $26.850'
    ]
  },
  {
    id: 50,
    values: [
      '$585 y $1.169',
      '$1.169 y $1.754',
      '$1.754 y $2.338',
      '$2.338 y $2.923',
      '$2.923 y $3.508',
      '$3.508 y $4.092',
      '$4.092 y $4.677',
      '$4.677 y $5.262',
      '$5.262 y $5.846',
      '$5.846 y $7.308',
      '$7.308 y $8.769',
      '$8.769 y $10.231',
      '$10.231 y $11.692',
      '$11.692 y $14.615',
      '$14.615 y $17.538',
      '$17.538 y $20.462',
      '$20.462 y $23.385',
      '$23.385 y $25.864'
    ]
  },
  {
    id: 51,
    values: [
      '$563 y $1.126',
      '$1.126 y $1.690',
      '$1.690 y $2.253',
      '$2.253 y $2.816',
      '$2.816 y $3.379',
      '$3.379 y $3.942',
      '$3.942 y $4.505',
      '$4.505 y $5.069',
      '$5.069 y $5.632',
      '$5.632 y $7.040',
      '$7.040 y $8.448',
      '$8.448 y $9.856',
      '$9.856 y $11.264',
      '$11.264 y $14.079',
      '$14.079 y $16.895',
      '$16.895 y $19.711',
      '$19.711 y $22.527',
      '$22.527 y $24.916'
    ]
  },
  {
    id: 52,
    values: [
      '$543 y $1.085',
      '$1.085 y $1.628',
      '$1.628 y $2.170',
      '$2.170 y $2.713',
      '$2.713 y $3.255',
      '$3.255 y $3.798',
      '$3.798 y $4.340',
      '$4.340 y $4.883',
      '$4.883 y $5.425',
      '$5.425 y $6.782',
      '$6.782 y $8.138',
      '$8.138 y $9.494',
      '$9.494 y $10.851',
      '$10.851 y $13.563',
      '$13.563 y $16.276',
      '$16.276 y $18.989',
      '$18.989 y $21.702',
      '$21.702 y $24.003'
    ]
  },
  {
    id: 53,
    values: [
      '$523 y $1.045',
      '$1.045 y $1.568',
      '$1.568 y $2.091',
      '$2.091 y $2.613',
      '$2.613 y $3.136',
      '$3.136 y $3.659',
      '$3.659 y $4.181',
      '$4.181 y $4.704',
      '$4.704 y $5.227',
      '$5.227 y $6.533',
      '$6.533 y $7.840',
      '$7.840 y $9.147',
      '$9.147 y $10.453',
      '$10.453 y $13.067',
      '$13.067 y $15.680',
      '$15.680 y $18.294',
      '$18.294 y $20.907',
      '$20.907 y $23.124'
    ]
  },
  {
    id: 54,
    values: [
      '$504 y $1.007',
      '$1.007 y $1.511',
      '$1.511 y $2.014',
      '$2.014 y $2.518',
      '$2.518 y $3.021',
      '$3.021 y $3.525',
      '$3.525 y $4.028',
      '$4.028 y $4.532',
      '$4.532 y $5.036',
      '$5.036 y $6.294',
      '$6.294 y $7.553',
      '$7.553 y $8.812',
      '$8.812 y $10.071',
      '$10.071 y $12.589',
      '$12.589 y $15.107',
      '$15.107 y $17.624',
      '$17.624 y $20.142',
      '$20.142 y $22.278'
    ]
  },
  {
    id: 55,
    values: [
      '$485 y $970',
      '$970 y $1.455',
      '$1.455 y $1.941',
      '$1.941 y $2.426',
      '$2.426 y $2.911',
      '$2.911 y $3.396',
      '$3.396 y $3.881',
      '$3.881 y $4.366',
      '$4.366 y $4.851',
      '$4.851 y $6.064',
      '$6.064 y $7.277',
      '$7.277 y $8.490',
      '$8.490 y $9.703',
      '$9.703 y $12.129',
      '$12.129 y $14.554',
      '$14.554 y $16.980',
      '$16.980 y $19.406',
      '$19.406 y $21.464'
    ]
  },
  {
    id: 56,
    values: [
      '$467 y $935',
      '$935 y $1.402',
      '$1.402 y $1.870',
      '$1.870 y $2.337',
      '$2.337 y $2.805',
      '$2.805 y $3.272',
      '$3.272 y $3.739',
      '$3.739 y $4.207',
      '$4.207 y $4.674',
      '$4.674 y $5.843',
      '$5.843 y $7.011',
      '$7.011 y $8.180',
      '$8.180 y $9.349',
      '$9.349 y $11.686',
      '$11.686 y $14.023',
      '$14.023 y $16.360',
      '$16.360 y $18.697',
      '$18.697 y $20.680'
    ]
  },
  {
    id: 57,
    values: [
      '$450 y $901',
      '$901 y $1.351',
      '$1.351 y $1.802',
      '$1.802 y $2.252',
      '$2.252 y $2.702',
      '$2.702 y $3.153',
      '$3.153 y $3.603',
      '$3.603 y $4.053',
      '$4.053 y $4.504',
      '$4.504 y $5.630',
      '$5.630 y $6.756',
      '$6.756 y $7.882',
      '$7.882 y $9.008',
      '$9.008 y $11.259',
      '$11.259 y $13.511',
      '$13.511 y $15.763',
      '$15.763 y $18.015',
      '$18.015 y $19.925'
    ]
  },
  {
    id: 58,
    values: [
      '$434 y $868',
      '$868 y $1.302',
      '$1.302 y $1.736',
      '$1.736 y $2.170',
      '$2.170 y $2.604',
      '$2.604 y $3.038',
      '$3.038 y $3.472',
      '$3.472 y $3.906',
      '$3.906 y $4.340',
      '$4.340 y $5.424',
      '$5.424 y $6.509',
      '$6.509 y $7.594',
      '$7.594 y $8.679',
      '$8.679 y $10.849',
      '$10.849 y $13.019',
      '$13.019 y $15.189',
      '$15.189 y $17.358',
      '$17.358 y $19.199'
    ]
  },
  {
    id: 59,
    values: [
      '$418 y $836',
      '$836 y $1.254',
      '$1.254 y $1.673',
      '$1.673 y $2.091',
      '$2.091 y $2.509',
      '$2.509 y $2.927',
      '$2.927 y $3.345',
      '$3.345 y $3.763',
      '$3.763 y $4.182',
      '$4.182 y $5.227',
      '$5.227 y $6.272',
      '$6.272 y $7.318',
      '$7.318 y $8.363',
      '$8.363 y $10.454',
      '$10.454 y $12.545',
      '$12.545 y $14.635',
      '$14.635 y $16.726',
      '$16.726 y $18.500'
    ]
  },
  {
    id: 60,
    values: [
      '$403 y $806',
      '$806 y $1.209',
      '$1.209 y $1.612',
      '$1.612 y $2.015',
      '$2.015 y $2.418',
      '$2.418 y $2.821',
      '$2.821 y $3.224',
      '$3.224 y $3.626',
      '$3.626 y $4.029',
      '$4.029 y $5.037',
      '$5.037 y $6.044',
      '$6.044 y $7.051',
      '$7.051 y $8.059',
      '$8.059 y $10.073',
      '$10.073 y $12.088',
      '$12.088 y $14.103',
      '$14.103 y $16.118',
      '$16.118 y $17.827'
    ]
  }
];
