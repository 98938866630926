import { Routes } from '@angular/router';
import {
  getAccessConsultRoute, getBeneficiaryRequestRoute, getCancelRoute, getCheckStatusRoute, getContactRoute,
  getFAQSRoute, getForeignFAQSRoute, getForeignRequestRoute, getForeignRoute, getRequestRoute, MAIN_ROUTES
} from './routes.constant';

export const OLD_ROUTES_REDIRECTIONS: Routes = [
  { path: 'segundo-retiro-fondos', redirectTo: getRequestRoute(MAIN_ROUTES.SECOND_WITHDRAWAL), pathMatch: 'full' },
  { path: 'tercer-retiro-fondos', redirectTo: getRequestRoute(MAIN_ROUTES.THIRD_WITHDRAWAL), pathMatch: 'full' },
  { path: 'solicitudbonocargofiscal', redirectTo: getRequestRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },

  { path: 'segundo-retiro-fondos-extranjero', redirectTo: getForeignRequestRoute(MAIN_ROUTES.SECOND_WITHDRAWAL), pathMatch: 'full' },
  { path: 'tercer-retiro-fondos-extranjero', redirectTo: getForeignRequestRoute(MAIN_ROUTES.THIRD_WITHDRAWAL), pathMatch: 'full' },
  { path: 'solicitudbonocargofiscalextranjero', redirectTo: getForeignRequestRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },

  { path: 'segundo-retiro-fondos-beneficiario', redirectTo: getBeneficiaryRequestRoute(MAIN_ROUTES.SECOND_WITHDRAWAL), pathMatch: 'full' },
  { path: 'tercer-retiro-fondos-beneficiario', redirectTo: getBeneficiaryRequestRoute(MAIN_ROUTES.THIRD_WITHDRAWAL), pathMatch: 'full' },
  { path: 'solicitudbonocargofiscal-beneficiario', redirectTo: getBeneficiaryRequestRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },

  { path: 'consultabonocargofiscal', redirectTo: getAccessConsultRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },

  { path: 'faqs-1er', redirectTo: getFAQSRoute(MAIN_ROUTES.FIRST_WITHDRAWAL), pathMatch: 'full' },
  { path: 'faqs-2do', redirectTo: getFAQSRoute(MAIN_ROUTES.SECOND_WITHDRAWAL), pathMatch: 'full' },
  { path: 'faqs-3er', redirectTo: getFAQSRoute(MAIN_ROUTES.THIRD_WITHDRAWAL), pathMatch: 'full' },
  { path: 'faqs-bono', redirectTo: getFAQSRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },
  { path: 'faqs-bono-extranjero', redirectTo: getForeignFAQSRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },

  { path: 'consulta-estado-solicitud', redirectTo: getCheckStatusRoute(MAIN_ROUTES.FIRST_WITHDRAWAL), pathMatch: 'full' },
  { path: 'consulta-estado-solicitud-segundo-retiro', redirectTo: getCheckStatusRoute(MAIN_ROUTES.SECOND_WITHDRAWAL), pathMatch: 'full' },
  { path: 'consulta-estado-solicitud-tercer-retiro', redirectTo: getCheckStatusRoute(MAIN_ROUTES.THIRD_WITHDRAWAL), pathMatch: 'full' },
  { path: 'consultaestadosolictudbonocargofiscal', redirectTo: getCheckStatusRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },

  { path: 'anular-solicitud-tercer-retiro', redirectTo: getCancelRoute(MAIN_ROUTES.THIRD_WITHDRAWAL), pathMatch: 'full' },
  { path: 'anularsolicitudbonocargofiscal', redirectTo: getCancelRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },

  { path: 'contacto-1er', redirectTo: getContactRoute(MAIN_ROUTES.FIRST_WITHDRAWAL), pathMatch: 'full' },
  { path: 'contacto-2do', redirectTo: getContactRoute(MAIN_ROUTES.SECOND_WITHDRAWAL), pathMatch: 'full' },
  { path: 'contacto-3er', redirectTo: getContactRoute(MAIN_ROUTES.THIRD_WITHDRAWAL), pathMatch: 'full' },
  { path: 'contacto-bono', redirectTo: getContactRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },

  { path: 'bonocargofiscal', redirectTo: MAIN_ROUTES.FISCAL_BONUS, pathMatch: 'full' },

  { path: 'segundo-retiro-extranjero', redirectTo: getForeignRoute(MAIN_ROUTES.SECOND_WITHDRAWAL), pathMatch: 'full' },
  { path: 'tercero-retiro-extranjero', redirectTo: getForeignRoute(MAIN_ROUTES.THIRD_WITHDRAWAL), pathMatch: 'full' },
  { path: 'bonofiscalretiroextranjero', redirectTo: getForeignRoute(MAIN_ROUTES.FISCAL_BONUS), pathMatch: 'full' },
];
