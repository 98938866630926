import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  private loadingRequestSource: Subject<boolean> = new Subject<boolean>();
  public loadingRequestIntercepted: Observable<boolean> = this.loadingRequestSource.asObservable();

  constructor() { }

  public getLoadingRequestIntercepted() {
    return this.loadingRequestIntercepted;
  }

  public hideLoading() {
    return this.loadingRequestSource.next(false);
  }

  public showLoading() {
    return this.loadingRequestSource.next(true);
  }
}
