import { Component, Input } from '@angular/core';
import { Faq } from '@interfaces/general.interface';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-accordion-list',
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss'],
})
export class AccordionListComponent {
  @Input() list: Array<Faq> = [];

  constructor(
    public font: FontService,
    protected navService: NavigationService,
  ) {}

  public goTo(link: string) {
    if (!link) { return; }
    this.navService.goTo(link);
  }

}
