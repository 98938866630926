import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CONTACT_FORM_FIELDS, CONTACT_FORM_REASONS,
  SEND_CONTACT_FORM_SUCCESS_MODAL
} from '@constants/contact-form.constant';
import { DEFAULT_ERROR_MODAL_PROPS } from '@constants/error-messages.constant';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@constants/regex.constant';
import { MAIN_ROUTES } from '@constants/routes.constant';
import {
  ContactFormLabels, ContactState
} from '@interfaces/contact-form.interface';
import { ModalData } from '@interfaces/modal-data.interface';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal';
import { NavigationService } from '@providers/navigation/navigation.service';
import { ContactService } from '@services/contact/contact.service';
import { Utils } from '@utils/utils';
import { ValidateRut } from '@validators/rut.validator';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  @Input() public contentTree: Array<string>;
  @Input() public selectedReason: string;

  public fieldNames: ContactFormLabels = CONTACT_FORM_FIELDS;
  public contactReasons = CONTACT_FORM_REASONS;
  public form: FormGroup;

  public get name(): AbstractControl { return this.form.get('name'); }
  public get fatherLastName(): AbstractControl { return this.form.get('fatherLastName'); }
  public get rut(): AbstractControl { return this.form.get('rut'); }
  public get email(): AbstractControl { return this.form.get('email'); }
  public get description(): AbstractControl { return this.form.get('description'); }
  public get reasonCode(): AbstractControl { return this.form.get('reasonCode'); }

  constructor(
    private contactService: ContactService,
    private modalProvider: ModalService,
    private navigateService: NavigationService,
    private utils: Utils,
    private loadingService: LoadingService,
  ) {
    this.form = new FormGroup({
      reasonCode: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      fatherLastName: new FormControl('', [Validators.required]),
      motherLastName: new FormControl(''),
      rut: new FormControl('', [ValidateRut, Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]),
      phoneNumber: new FormControl('', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern(PHONE_PATTERN)]),
      description: new FormControl('', [Validators.required]),
    });
  }

  public ngOnInit(): void {
    this.updateReasonCode();
  }

  public sendFormData(): void {
    if (this.form.invalid) { return; }
    this.loadingService.showLoading();
    const formData = new FormData();
    Object.keys(this.form.controls).forEach((key) => this.formDataAppendHandler(formData, key, this.form.get(key).value));
    this.contactService.contact(formData)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response) => { this.handleSuccess(response); },
        () => { this.handleError(); }
      );
  }

  private formDataAppendHandler(formData: FormData, key: string, value: any): void {
    if (!value) { return; }
    if (key === 'rut') { value = this.utils.rutClean(value); }
    formData.append(key, value);
  }

  private updateReasonCode(): void {
    this.form.controls['reasonCode'].setValue(this.selectedReason);
    this.form.updateValueAndValidity();
  }

  private handleError(): void {
    const modalData: ModalData = DEFAULT_ERROR_MODAL_PROPS;
    return this.modalProvider.openModal(modalData);
  }

  private handleSuccess(response: ContactState): void {
    const { folio } = response;
    const date = this.utils.getFormattedDate(new Date());
    const { iconName, title, firstBtnText } = SEND_CONTACT_FORM_SUCCESS_MODAL;
    this.modalProvider.openModal({
      iconName,
      title,
      message: `Con Folio: ${folio} y fecha de solicitud ${date}. Daremos respuesta en un plazo no más allá de tres días hábiles.`,
      firstBtnText,
      primaryCallback: () => this.navigateService.goTo(MAIN_ROUTES.HOME_LANDING),
    });
  }

  public originalOrder(): number {
    return 0;
  }
}
