export const FILE_VALID_MIME_TYPES = ['application/pdf', 'application/vnd.ms-excel',
  'image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/bmp', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessing'];

export const FILE_EXTENSIONS = ['PDF', 'DOC', 'DOCX', 'JPG', 'PNG', 'BMP'];

export const FILE_MAX_SIZE = 3145728;

export const ATTACH_VALIDATORS_CONTENT = {
  attachValidators: {
    attachFormat: 'Formato PDF, DOC, DOCX, JPG, PNG, BMP.',
    attachExample: 'El nombre del archivo solo acepta letras y números (sin caracteres especiales).',
    attachMaxSize: 'Tamaño máximo total 3 MB.',
    attachDifferentFiles: 'Archivos no pueden ser iguales (mismo nombre).'
  }
};
