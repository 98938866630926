import { AbstractControl, ValidationErrors } from '@angular/forms';
import { RESTRICTED_PAYMENT_COUNTRIES } from '@constants/country.constants';
import { Utils } from '@utils/utils';

const utils = new Utils(null, null);

export function restrictedCountryValidator(control: AbstractControl): ValidationErrors | null {
  const countryName = control.value ? control.value.toUpperCase() : '';
  const normalizedCountryName = utils.removeDiacritics(countryName);
  const isCountryRestricted = RESTRICTED_PAYMENT_COUNTRIES.includes(normalizedCountryName);
  return isCountryRestricted ? { restrictedCountry: { value: normalizedCountryName } } : null;
}
