import * as _ from 'lodash';

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  ACCOUNTS_NAMES, CLIENT_WITH_DEBT_TYPES, COMPENSATION_BOX_ID, FOREIGN_BANK_ID, MANDATE_ID,
  PAYMENT_METHODS, PAYMENT_METHODS_FOREIGN, REQUEST_STATUS, TRANSFER_BENEFICIARY_ID, TRANSFER_ID,
  VOUCHER_BENEFICIARY_ID, VOUCHER_ID
} from '@constants/funds-withdrawal.constant';
import { PENSIONS_URL } from '@constants/url.constant';
import { ForeignWithdrawalRequest } from '@interfaces/foreign-funds-withdrawal.interface';
import {
  RequestDetailData, TransferDetails, WithdrawalInstallment
} from '@interfaces/funds-withdrawal.interface';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-check-status-modal',
  templateUrl: './check-status-modal.component.html',
  styleUrls: ['./check-status-modal.component.scss']
})
export class CheckStatusModalComponent {
  public doubleAccountCode = '01';

  constructor(
    public dialogRef: MatDialogRef<CheckStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RequestDetailData,
    protected navService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public foreignData: ForeignWithdrawalRequest,

  ) { }

  public get isFirstWithdrawal(): boolean { return this.data.withdrawalType === 1; }

  public get isSecondWithdrawal(): boolean { return this.data.withdrawalType === 2; }

  public get isThirdWithdrawal(): boolean { return this.data.withdrawalType === 3; }

  public get isFirstBonus(): boolean { return this.data.withdrawalType === 201; }

  public get isInProgress(): boolean { return this.data.request.statusCode === REQUEST_STATUS.inProgress; }

  public get isRejected(): boolean { return this.data.request.statusCode === REQUEST_STATUS.rejected; }

  public get isAnnulled(): boolean { return this.data.request.statusCode === REQUEST_STATUS.annulled; }

  public get isAccepted(): boolean { return this.data.request.statusCode === REQUEST_STATUS.accepted; }

  public get isFinished(): boolean { return this.data.request.statusCode === REQUEST_STATUS.finished; }

  public get isTransferOption(): boolean {
    return this.data.request.paymentMethod === TRANSFER_ID ||
      this.data.request.paymentMethod === TRANSFER_BENEFICIARY_ID;
  }

  public get isVoucherOption(): boolean { return this.data.request.paymentMethod === VOUCHER_ID; }

  public get isCompensationBoxOption(): boolean { return this.data.request.paymentMethod === COMPENSATION_BOX_ID; }

  public get isForeign(): boolean { return [FOREIGN_BANK_ID, MANDATE_ID].includes(this.data.request.paymentMethod); }

  public get isMandate(): boolean { return this.isForeign && this.data.request.paymentMethod === MANDATE_ID; }

  public get isDebtor(): boolean { return this.data.debtType !== CLIENT_WITH_DEBT_TYPES.HAS_NOT_DEBT_TYPE; }

  public get mandateName(): string { return this.isMandate ? this.data.request.mandateName : ''; }

  public get mandateRut(): string { return this.isMandate ? this.data.request.mandateRut : ''; }

  public get foreignCountry(): string { return !this.isMandate ? this.data.request.country : ''; }

  public get foreignAccountNumber(): string { return !this.isMandate ? this.data.request.accountNumber : ''; }

  public get foreignCurrency(): string { return !this.isMandate ? this.data.request.currency : ''; }

  public get foreignBankCodes() {
    if (this.isMandate) { return []; }
    const bankCodes = [];
    Object.keys(this.data.request.bankCodes).forEach((key) => {
      if (this.data.request.bankCodes[key]) {
        bankCodes.push({ code: key, value: this.data.request.bankCodes[key] });
      }
    });
    return bankCodes;
  }

  public get foreignBankCode(): string {
    return !this.isMandate ? this.data.request.bankCode : '';
  }

  public get paymentDate(): string { return this.data.request.paymentDate; }

  public get statusIcon(): string { return this.isRejected || this.isAnnulled ? 'i-rejected' : 'i-clock-circle'; }

  public get origin(): string {
    const { accounts } = this.data.request;
    if (accounts.length === 0) { return 'Cuenta Obligatoria'; }
    return `Cuenta ${ACCOUNTS_NAMES[accounts[0]]}${accounts.length === 2 ? ` y ${ACCOUNTS_NAMES[accounts[1]]}` : ''}`;
  }

  public get finishedMessage(): string {
    return this.isFinished ? ' y enviada a la entidad bancaria para pago.' : '.';
  }

  public get mergedInstallments(): Array<WithdrawalInstallment> {
    return _.chain(this.data.request.installments)
      .groupBy('fund')
      .map((installments: Array<WithdrawalInstallment>, fund: string) => {
        return {
          fund,
          quantity: _.sumBy(installments, 'quantity'),
        };
      }).value();
  }

  public get transferDetails(): TransferDetails {
    const { banks, accountTypes } = this.data.paymentMethods;
    const { paymentMethod, accountType: accountTypeCode, bankCode } = this.data.request;

    const methodName = [
      ...PAYMENT_METHODS,
      ...PAYMENT_METHODS_FOREIGN,
      { id: COMPENSATION_BOX_ID, name: 'Caja de Compensación Los Héroes' },
      { id: TRANSFER_BENEFICIARY_ID, name: 'Transferencia' },
      { id: VOUCHER_BENEFICIARY_ID, name: 'Otro a informar por la AFP' }
    ].find((method) => method.id === paymentMethod).name;

    const bank = bankCode ? banks.find(bankOption => Number(bankOption.code) === Number(bankCode)) : '';
    const bankName = bank ? bank.description.toLowerCase() : '';

    const accountType = accountTypeCode ? accountTypes.find(accountTypeOption => accountTypeOption.code === accountTypeCode) : '';
    let accountTypeName = accountType ? accountType.description : '';
    if (accountTypeCode === this.doubleAccountCode) { accountTypeName = 'Cuenta corriente/Cuenta vista'; }

    return { methodName, bankName, accountTypeName };
  }

  public closeDialog(sendVoucher = false): void { this.dialogRef.close(sendVoucher); }

  public goToPensions(): void { this.navService.goTo(PENSIONS_URL); }

  public sendVoucher(): void { this.closeDialog(true); }

  public getDebtMessage(): string {
    if (!this.isAcceptedOrInProgress()) { return; }
    switch (this.data.debtType) {
      case CLIENT_WITH_DEBT_TYPES.HAS_NOT_DEBT_TYPE: {
        return;
      }
      case CLIENT_WITH_DEBT_TYPES.HAS_DEBT_WITHOUT_BENEFICIARY_TYPE: {
        return 'Estamos a la espera que el Tribunal de Familia envíe la liquidación a la alimentaria para dejar sin efecto la retención' +
         ' del 1er o 2do retiro.';
      }
      case CLIENT_WITH_DEBT_TYPES.HAS_DEBT_WITH_BENEFICIARY_TYPE: {
        return this.getBeneficiaryDebtMessage();
      }
    }
    return;
  }

  private isAcceptedOrInProgress(): boolean {
    return this.isAccepted || this.isInProgress;
  }

  private getBeneficiaryDebtMessage(): string {
    const {
      debtorRetentionIndex,
      retentionStatus
    } = this.data.debtInformation;

    if (['PA', 'AC'].includes(retentionStatus)) {
      return debtorRetentionIndex === 'N' ? 'Se ha retenido el monto del 10% para pagar la liquidación a la alimentaria. Debido a lo ' +
      'anterior, el Tribunal dejó sin efecto la medida cautelar. Si aún tienes saldo en la AFP, podrás solicitar el 2do retiro del 10%.' :
        'El pago de la retención se realizó correctamente a la alimentaria. Para continuar con tu solicitud de retiro, ' +
        'es necesario presentar en la AFP el documento donde el Tribunal deja sin efecto la medida cautelar.';
    } else {
      return 'Tiene más de una medida cautelar en su contra. Estamos a la espera de que el tribunal entregue las liquidaciones de ' +
    'las alimentarias para realizar el pago del 1er o 2do retiro.';
    }
  }

  public get businessDays(): string {
    if (this.isFirstWithdrawal || this.isSecondWithdrawal) {
      return '10';
    } else if (this.isThirdWithdrawal || this.isFirstBonus) {
      return '15';
    }
  }
}
