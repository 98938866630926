import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { MAIN_ROUTES } from '@constants/routes.constant';
import { environment } from '@environment';
import { PrimaryOption } from '@interfaces/header.interface';
import { MenuController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { WithdrawalsService } from '@services/withdrawals/withdrawals.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public menuOptions: Array<PrimaryOption>;

  constructor(
    public font: FontService,
    protected utils: Utils,
    protected navService: NavigationService,
    protected menu: MenuController,
    protected router: Router,
    protected withdrawalsService: WithdrawalsService,
  ) { }

  public ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof RouterEvent) { this.setMenuOptions(event.url); }
    });
    this.navService.subscribeToReloadOnGoToBack();
  }

  public redirectLogo(): void {
    const currentUrl = this.router.url.substring(1);
    if (currentUrl === MAIN_ROUTES.HOME_LANDING) {
      window.open(environment.publicSiteUrl, '_self');
    } else if (Object.values(MAIN_ROUTES).includes(currentUrl)) {
      this.navService.goTo(MAIN_ROUTES.HOME_LANDING);
    } else {
      this.defaultRedirect(currentUrl);
    }
  }

  public goTo(url: string): void {
    this.menu.close();
    this.navService.goTo(url);
  }

  public changeFontSize(change: 'bigger' | 'smaller'): void {
    this.font.setSize(change);
  }

  public setMenuOptions(route?: string): void {
    if (!route) { return; }
    this.menuOptions = this.utils.getWithdrawalTabs(route);
  }

  public openMenu(): void {
    this.menu.open();
  }

  public selectOption(option: PrimaryOption): void {
    const { route } = option;
    if (!route) { return; }
    this.goTo(route);
    this.setMenuOptions(route);
  }

  private defaultRedirect(currentUrl: string): void {
    const withdrawalMainRoute = this.utils.getWithdrawalMainRoute(currentUrl);
    this.navService.goTo(withdrawalMainRoute);
  }
}
