import { Component, Input } from '@angular/core';
import { PrimaryOption } from '@interfaces/header.interface';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { RouterService } from '@providers/router/router.service';

@Component({
  selector: 'app-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss'],
})
export class TabNavigationComponent {
  @Input() public tabs: Array<PrimaryOption>;

  constructor(
    public font: FontService,
    private navService: NavigationService,
    public routerService: RouterService,
  ) { }

  public tabClicked(event: any, url: string): void {
    event.preventDefault();
    this.goTo(url);
  }

  public goTo(url: string): void {
    if (!url) { return; }
    this.navService.goTo(url);
  }

  public get currentUrl(): string {
    return this.routerService ? this.routerService.currentUrl : '';
  }
}
