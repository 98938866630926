import { Component } from '@angular/core';
import { CONTACT_CENTER } from '@constants/general.constant';
import { QUALITY_SURVEY_URL, SOCIAL_URL } from '@constants/url.constant';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public qualitySurveyUrl = QUALITY_SURVEY_URL;
  public socialUrls = SOCIAL_URL;
  public socialNetworks = Object.keys(this.socialUrls);
  public contactCenter = CONTACT_CENTER;

  constructor(
    public font: FontService,
    private navService: NavigationService,
  ) { }

  public goTo(url: string) {
    this.navService.goTo(url);
  }

  public callContactCenter() {
    document.location.href = `tel:${this.contactCenter.NUMBER}`;
  }

}
