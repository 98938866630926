import { ComponentType } from '@angular/cdk/portal';
import { Component } from '@angular/core';
import {
  CallCenterModalComponent
} from '@components/call-center-modal/call-center-modal.component';
import { ContactModalComponent } from '@components/contact-modal/contact-modal.component';
import { BUTTON_TITLES, CONTACT_SOCIAL_URL } from '@constants/pages-content/help-button.constant';
import { BRANCH_OFFICES_URL, PUBLIC_SITE_FAQS_URL } from '@constants/url.constant';
import { ModalType } from '@interfaces/help-button.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
})
export class HelpButtonComponent {
  public optionsOpened = false;
  public buttonTitles = BUTTON_TITLES;
  public socialNetworks: Array<string>;

  constructor(
    public font: FontService,
    private navService: NavigationService,
    private modalService: ModalService,
  ) {
    const socialNetworks = Object.keys(CONTACT_SOCIAL_URL);
    this.socialNetworks = socialNetworks.filter(
            (socialNetwork) => (socialNetwork !== 'whatsapp' && socialNetwork !== 'email')
    );
  }

  public get publicSiteBranchOfficesRoute(): string { return BRANCH_OFFICES_URL; }
  public get publicSiteFaqsRoute(): string { return PUBLIC_SITE_FAQS_URL; }

  public socialNetworkIcon(socialNetwork: string): string {
    return `i-${socialNetwork}`;
  }

  public toggleOptions(): void {
    this.optionsOpened = !this.optionsOpened;
  }

  public openModal(type: ModalType): void {
    this.toggleOptions();
    const isContactModal = type === 'contact';
    const component = isContactModal ? ContactModalComponent : CallCenterModalComponent;
    this.modalService.openCustomModal(component as ComponentType<any>, null, 'modal-default');
  }

  public goToUrl(url: string): void {
    this.navService.goTo(url);
    this.toggleOptions();
  }

  public goToEmail(): void {
    this.navService.windowOpen(CONTACT_SOCIAL_URL['email'], '_self');
  }

  public goToSocialNetwork(type: string): void {
    this.goToUrl(CONTACT_SOCIAL_URL[type]);
  }

  public goToTop(): void {
    const pages = document.querySelectorAll('ion-content');
    const page = pages.item(pages.length - 1);
    const banner = page.querySelector('app-banner');
    banner.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }

}
