import { Component, Output, EventEmitter, Input } from '@angular/core';
import { LinkModal } from '@interfaces/components.interface';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-information-alert',
  templateUrl: './information-alert.component.html',
  styleUrls: ['./information-alert.component.scss'],
})
export class InformationAlertComponent {
  @Input() public data: LinkModal;
  @Output() private clickCloseInformation = new EventEmitter();

  constructor(
    public font: FontService,
    private navService: NavigationService,
  ) { }

  public clickCloseButton() {
    return this.clickCloseInformation.emit();
  }

  public goToUrl() {
    this.navService.goTo(this.data.button.route);
  }

}
