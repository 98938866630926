import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as WITHDRAWAL from '@constants/funds-withdrawal.constant';
import { ForeignFiles } from '@interfaces/foreign-files.interface';
import { ModalData } from '@interfaces/modal-data.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal';

@Component({
  selector: 'app-foreign-files',
  templateUrl: './foreign-files.component.html',
  styleUrls: ['./foreign-files.component.scss'],
})
export class ForeignFilesComponent implements OnInit, OnChanges {

  @Input() public validId: boolean;
  @Input() public validPassword: boolean;
  @Input() public isMandate: boolean;
  @Output() public confirmStep = new EventEmitter();
  @Output() goToBack = new EventEmitter();

  public form: FormGroup;
  public validFiles = WITHDRAWAL.VALID_WITHDRAWAL_FILES;
  public validExtensions = WITHDRAWAL.VALID_WITHDRAWAL_FILE_EXTENSIONS;
  public maxFileSize = WITHDRAWAL.WITHRAWAL_FILE_MAX_SIZE;
  public simpleStatementText: string;

  constructor(
    public formBuilder: FormBuilder,
    public font: FontService,
    private modalProvider: ModalService,
  ) { }

  public get identificationFile(): AbstractControl { return this.form.get('identificationFile'); }

  public get residenceCertificateFile(): AbstractControl { return this.form.get('residenceCertificateFile'); }

  public get accountCertificateFile(): AbstractControl { return this.form.get('accountCertificateFile'); }

  public get facePhotoFile(): AbstractControl { return this.form.get('facePhotoFile'); }

  public get simpleStatement(): AbstractControl { return this.form.get('simpleStatement'); }

  public get hasFacePhoto(): AbstractControl { return this.form.get('hasFacePhoto'); }

  public get sendVideoCallEmail(): AbstractControl { return this.form.get('sendVideoCallEmail'); }

  public get countryDocumentId(): string { return this.validId ? '' : 'o documento de identificación del país'; }

  public ngOnInit(): void {
    this.form = this.createForm();
    this.setValidatorsFiles();
    this.setStatementText();
  }

  public ngOnChanges(changes): void {
    if (!changes.validId.firstChange && !changes.validPassword.firstChange) {
      this.setHasFacePhoto();
      this.setSendVideoCallEmail();
    }
  }

  public createForm() {
    return this.formBuilder.group({
      identificationFile: [null],
      residenceCertificateFile: [null],
      accountCertificateFile: [null],
      facePhotoFile: [null],
      simpleStatement: [false, Validators.requiredTrue],
      hasFacePhoto: [false],
      sendVideoCallEmail: [false],
    });
  }

  public setValidatorsFiles(): void {
    if (!this.isMandate) {
      this.identificationFile.setValidators([Validators.required]);
      this.residenceCertificateFile.setValidators([Validators.required]);
      this.accountCertificateFile.setValidators([Validators.required]);
    }
  }

  public setSendVideoCallEmail(): void {
    this.sendVideoCallEmail.setValue(!(this.validId || this.validPassword));
  }

  public setStatementText(): void {
    this.simpleStatementText = this.isMandate ? WITHDRAWAL.MANDATE_STATEMENT_TEXT : WITHDRAWAL.SIMPLE_STATEMENT_TEXT;
  }

  public setHasFacePhoto(): void {
    const facePhotoRequired = this.validId && !this.validPassword;
    this.hasFacePhoto.setValue(facePhotoRequired);
    if (facePhotoRequired) {
      this.facePhotoFile.setValidators([Validators.required]);
      this.facePhotoFile.updateValueAndValidity();
    }
  }

  public confirm(): void {
    if (this.form.invalid) { return; }
    const params = {
      ...this.form.value
    } as ForeignFiles;
    this.confirmStep.emit(params);
  }

  public previousStep(): void {
    this.goToBack.emit();
  }

  public handleFileInput(files: FileList, fileType: string): void {
    const file = files.item(0);
    if (this.isFileInvalid(file, fileType)) { return; }
    this.form.controls[fileType].setValue(file);
  }

  private isFileInvalid(file: File, fileType: string): boolean {
    return !(this.isValidSize(file) && this.isValidFileType(fileType) && this.isValidFileExtensions(file));
  }

  private isValidSize(file: File): boolean {
    return file.size <= this.maxFileSize;
  }

  private isValidFileType(fileType: string): boolean {
    return this.validFiles.some((element) => element === fileType);
  }

  private isValidFileExtensions(file: File): boolean {
    const extension = this.getFileExtension(file);
    return this.validExtensions.some((element) => element === extension);
  }

  private getFileExtension(file: File): string {
    return file.name.split('.').pop().toLowerCase();
  }

  public openDocumentInfoModal(id: number): void {
    const modalData = {
      iconName: 'i-info',
      title: 'Información Adicional',
      message: WITHDRAWAL.REQUIERED_DOCUMENTS_FOR_FOREIGN_WITHDRAWAL_DESCRIPTIONS[id],
      firstBtnText: 'Volver',
      primaryCallback: () => { },
    } as ModalData;
    this.modalProvider.openModal(modalData);
  }

}
