import { Component, Input, OnInit } from '@angular/core';
import { GENERIC_WITHDRAWAL_NUMBER } from '@constants/funds-withdrawal.constant';
import {
  BONUS_SUCCESSFUL_VOUCHER, GENERAL_SUCCESSFUL_VOUCHER, SECOND_WITHDRAWAL_INFORMATION_VOUCHER, WITHDRAWAL_IS_TAXED
} from '@constants/voucher.constants';
import { environment } from '@environment';
import { WithdrawalType } from '@interfaces/funds-withdrawal.interface';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit {
  @Input() public folio: string;
  @Input() public isMinorBonus = false;
  @Input() public withdrawalNumber: WithdrawalType;
  public isTaxed = false;
  public voucherContent: any;
  public additionalInformation: any;

  constructor(
    protected navService: NavigationService,
  ) {}

  public ngOnInit() {
    this.voucherContent = this.withdrawalNumber === GENERIC_WITHDRAWAL_NUMBER.FIRST_BONUS
    ? BONUS_SUCCESSFUL_VOUCHER : GENERAL_SUCCESSFUL_VOUCHER;
    this.isTaxed = WITHDRAWAL_IS_TAXED[this.withdrawalNumber];
    this.setAdditionalInformation();
  }

  public goToPublicSite() {
    this.navService.goTo(environment.publicSiteUrl);
  }

  public setAdditionalInformation(): void {
    if (this.isTaxed) {
      this.additionalInformation = SECOND_WITHDRAWAL_INFORMATION_VOUCHER;
    }
  }
}
