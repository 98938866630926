import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ONLY_NUMBERS_PATTERN } from '@constants/regex.constant';
import * as WITHDRAWAL from '@constants/funds-withdrawal.constant';
import { WithdrawalClient } from '@interfaces/funds-withdrawal.interface';
import { Element } from '@interfaces/general.interface';
import { distinctUntilChanged } from 'rxjs/operators';
import { NavigationService } from '@providers/navigation/navigation.service';
import { MatDialog } from '@angular/material';
import { environment } from '@environment';
import { FontService } from '@providers/font/font.service';
import { InformationModalComponent } from '@components/information-modal/information-modal.component';
import { MaxAmountModalComponent } from '@components/max-amount-modal/max-amount-modal.component';
import { SimulationModalComponent } from '@components/simulation-modal/simulation-modal.component';
import { PERCENTAGE_ERROR_MESSAGE } from '@constants/error-messages.constant';
import { WithdrawalAmountInfo } from '@interfaces/withdrawal-amount-info.interface';
import { FormText } from '@interfaces/components.interface';

@Component({
  selector: 'app-withdrawal-amount',
  templateUrl: './withdrawal-amount.component.html',
  styleUrls: ['./withdrawal-amount.component.scss'],
})
export class WithdrawalAmountComponent implements OnInit {

  @Input() public client: WithdrawalClient;
  @Input() public selectedLoginFlow: string;
  @Input() public isBeneficiary: string;
  @Input() public infoModalTexts: FormText[];
  @Output() public confirmStep = new EventEmitter();
  @Output() goToBack = new EventEmitter();

  public form: FormGroup;
  public percentageMinValue = WITHDRAWAL.PERCENTAGE_MIN_VALUE;
  public percentageMaxValue = WITHDRAWAL.PERCENTAGE_MAX_VALUE;
  public type: Element;
  public isForeign = false;


  public validators = {
    percentage: [
      Validators.required,
      Validators.min(this.percentageMinValue),
      Validators.max(this.percentageMaxValue),
      Validators.pattern(ONLY_NUMBERS_PATTERN)
    ]
  };

  public get maxAmount(): AbstractControl { return this.form.get('maxAmount'); }

  public get percentage(): AbstractControl { return this.form.get('percentage'); }

  public get clientType(): AbstractControl { return this.form.get('clientType'); }

  public get lessAmount(): AbstractControl { return this.form.get('lessAmount'); }

  public get formTitle(): string {
    return !this.isForeign ? WITHDRAWAL.WITHDRAWAL_AMOUNT_TITLE.NATIONAL : WITHDRAWAL.WITHDRAWAL_AMOUNT_TITLE.FOREIGN; }

  constructor(
    private formBuilder: FormBuilder,
    private navService: NavigationService,
    public font: FontService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.form = this.createForm();
    this.maxAmountSubscription();
    this.percentageSubscription();
    if (this.selectedLoginFlow !== WITHDRAWAL.LOGIN_FLOW.FOREIGN) {
      this.setClientType();
    }
  }

  public createForm(): FormGroup {

    const withdrawalAmountForm = this.formBuilder.group(
      {
        maxAmount: [true, Validators.required],
        percentage: [this.percentageMaxValue, this.validators.percentage],
        lessAmount: [''],
        clientType: ['', Validators.required],
        alimonyDebt: ['', Validators.required],
        impactConsent: [false, Validators.requiredTrue],
        legalConsent: [false, Validators.requiredTrue],
      }
    );

    const foreignWithdrawalAmountForm = this.formBuilder.group(
      {
        maxAmount: [true, Validators.required],
        percentage: [this.percentageMaxValue, this.validators.percentage],
        alimonyDebt: ['', Validators.required],
        impactConsent: [false, Validators.requiredTrue],
        percentageTotal: [null, Validators.requiredTrue],
        paymentMethod: [WITHDRAWAL.FOREIGN_BANK_ID],
        legalConsent: [false, Validators.requiredTrue],
        lessAmount: [''],
      }
    );

    switch (this.selectedLoginFlow) {
      case WITHDRAWAL.LOGIN_FLOW.AFFILIATE:
      case WITHDRAWAL.LOGIN_FLOW.BENEFICIARY:
        return withdrawalAmountForm;
      case WITHDRAWAL.LOGIN_FLOW.FOREIGN:
        this.isForeign = true;
        return foreignWithdrawalAmountForm;
    }

  }

  private setClientType(): void {
    this.clientType.setValue(this.client.type);
    const clientType = WITHDRAWAL.CLIENT_TYPES.find((type) => type.id === this.clientType.value);
    const { accounts } = this.client;
    const accountsNames = `${WITHDRAWAL.ACCOUNTS_NAMES[accounts[0]]}${accounts.length === 2 ?
      ` y ${WITHDRAWAL.ACCOUNTS_NAMES[accounts[1]]}.` : '.'}`;
    clientType.description = clientType.description.replace('$accounts', accountsNames);
    this.type = clientType;
  }

  private maxAmountSubscription(): void {
    this.maxAmount.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((maxAmount: boolean) => {
        this.percentage.setValue(maxAmount ? 100 : 99);
        if (maxAmount === false) {
          return this.lessAmount.setValidators(Validators.requiredTrue);
        }
        this.percentage.setValue(100);
        this.lessAmount.clearValidators();
        this.lessAmount.updateValueAndValidity();
      });
  }

  private percentageSubscription(): void {
    this.percentage.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((percentage: number) => { if (percentage === 100) { this.maxAmount.setValue(true); } });
  }

  public goToBalance(): void {
    this.navService.goTo(environment.balanceUrl);
  }

  public confirm(): void {
    if (this.form.invalid) { return; }
    const params = {
      ...this.form.value
    } as WithdrawalAmountInfo;
    params.percentage = Number(params.percentage);
    this.confirmStep.emit(params);
  }

  public get percentageErrorMessage(): string {
    if (this.percentage.hasError('max')) { return PERCENTAGE_ERROR_MESSAGE.MAXIMUM_PERCENTAGE; }
    if (this.percentage.hasError('min')) { return PERCENTAGE_ERROR_MESSAGE.MINIMUM_PERCENTAGE; }
    if (this.percentage.hasError('pattern')) { return PERCENTAGE_ERROR_MESSAGE.PATTERN; }
    return '';
  }

  public openMaxAmountInfo() {
    this.dialog.open(MaxAmountModalComponent, { data: 'third', maxHeight: '100vh', maxWidth: '100vw', autoFocus: false });
  }

  public openInfoModal(): void {
    const data = { infoModalTexts: this.infoModalTexts };
    this.dialog.open(InformationModalComponent, { data, maxHeight: '100vh', maxWidth: '100vw', autoFocus: false });
  }

  public openSimulatorModal(): void {
    this.dialog.open(SimulationModalComponent, { maxHeight: '100vh', maxWidth: '100vw', autoFocus: false });
  }

  public previousStep(): void {
    this.goToBack.emit();
  }

}
