import { Component, Input } from '@angular/core';
import { PillButtonInterface } from '@interfaces/pill-button.interface';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-pill-button',
  templateUrl: './pill-button.component.html',
  styleUrls: ['./pill-button.component.scss'],
})
export class PillButtonComponent {
  @Input() public infoButton: PillButtonInterface;

  constructor(
    protected navService: NavigationService,
  ) { }

  public redirect(event: any, path: string) {
    event.preventDefault();
    return this.navService.goTo(path);
  }
}
