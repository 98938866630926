import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { EXPRESS_QUESTION_MODAL } from '@constants/funds-withdrawal.constant';

@Component({
  selector: 'app-express-question-modal',
  templateUrl: './express-question-modal.component.html',
  styleUrls: ['./express-question-modal.component.scss']
})
export class ExpressQuestionModalComponent {
  public iconName = 'i-check';
  public title = EXPRESS_QUESTION_MODAL.title;
  public subtitle = EXPRESS_QUESTION_MODAL.subtitle;
  public message = EXPRESS_QUESTION_MODAL.message;

  constructor(
    public dialogRef: MatDialogRef<ExpressQuestionModalComponent>,
  ) { }

  public closeDialog(callback: boolean) {
    this.dialogRef.close(callback);
  }

  public primaryAction(): void {
    this.closeDialog(true);
  }

  public secondaryAction(): void {
    this.closeDialog(false);
  }
}
