export const GENDER_CONSTANTS = [
  { id: 0, description: 'Femenino' },
  { id: 1, description: 'Masculino' }
];

export const AMOUNT_CONSTANTS = [
  { id: 0, description: '$100.000 - $200.000' },
  { id: 1, description: '$200.000 - $300.000' },
  { id: 2, description: '$300.000 - $400.000' },
  { id: 3, description: '$400.000 - $500.000' },
  { id: 4, description: '$500.000 - $600.000' },
  { id: 5, description: '$600.000 - $700.000' },
  { id: 6, description: '$700.000 - $800.000' },
  { id: 7, description: '$800.000 - $900.000' },
  { id: 8, description: '$900.000 - $1.000.000' },
  { id: 9, description: '$1.000.000 - $1.250.000' },
  { id: 10, description: '$1.250.000 - $1.500.000' },
  { id: 11, description: '$1.500.000 - $1.750.000' },
  { id: 12, description: '$1.750.000 - $2.000.000' },
  { id: 13, description: '$2.000.000 - $2.500.000' },
  { id: 14, description: '$2.500.000 - $3.000.000' },
  { id: 15, description: '$3.000.000 - $3.500.000' },
  { id: 16, description: '$3.500.000 - $4.000.000' },
  { id: 17, description: '$4.000.000 - $4.424.120' },
];

export const MIN_AGE_CONSTANT = 25;
export const MAX_AGE_FEMALE_CONSTANT = 60;
export const MAX_AGE_MALE_CONSTANT = 65;

