import { GENERIC_WITHDRAWAL_NUMBER } from './funds-withdrawal.constant';

export const MAIN_ROUTES = {
  HOME_LANDING: 'home-retiro',
  FIRST_WITHDRAWAL: 'primer-retiro',
  SECOND_WITHDRAWAL: 'segundo-retiro',
  THIRD_WITHDRAWAL: 'tercer-retiro',
  FISCAL_BONUS: 'bono-cargo-fiscal',
};

export const WITHDRAWAL_KEYS_TO_NUMBERS = {
  [MAIN_ROUTES.FIRST_WITHDRAWAL]: GENERIC_WITHDRAWAL_NUMBER.FIRST,
  [MAIN_ROUTES.SECOND_WITHDRAWAL]: GENERIC_WITHDRAWAL_NUMBER.SECOND,
  [MAIN_ROUTES.THIRD_WITHDRAWAL]: GENERIC_WITHDRAWAL_NUMBER.THIRD,
  [MAIN_ROUTES.FISCAL_BONUS]: GENERIC_WITHDRAWAL_NUMBER.FIRST_BONUS,
};

export const WITHDRAWAL_NUMBERS_TO_KEYS = {
  [GENERIC_WITHDRAWAL_NUMBER.FIRST]: MAIN_ROUTES.FIRST_WITHDRAWAL,
  [GENERIC_WITHDRAWAL_NUMBER.SECOND]: MAIN_ROUTES.SECOND_WITHDRAWAL,
  [GENERIC_WITHDRAWAL_NUMBER.THIRD]: MAIN_ROUTES.THIRD_WITHDRAWAL,
  [GENERIC_WITHDRAWAL_NUMBER.FIRST_BONUS]: MAIN_ROUTES.FISCAL_BONUS,
};

export const URL_KEYWORDS = {
  ACCESS_CONSULT: 'consulta-derecho',
  BENEFICIARY: 'beneficiario',
  CANCEL: 'anular-solicitud',
  CHECK_STATUS: 'consulta-estado-solicitud',
  CONTACT: 'contacto',
  FAQS: 'preguntas-frecuentes',
  FOREIGN: 'extranjero',
  REQUEST: 'solicitud',
};

export function getBeneficiaryRequestRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.BENEFICIARY}/${URL_KEYWORDS.REQUEST}`;
}

export function getRequestRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.REQUEST}`;
}

export function getForeignRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.FOREIGN}`;
}

export function getForeignRequestRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.FOREIGN}/${URL_KEYWORDS.REQUEST}`;
}

export function getContactRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.CONTACT}`;
}

export function getCheckStatusRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.CHECK_STATUS}`;
}

export function getCancelRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.CANCEL}`;
}

export function getFAQSRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.FAQS}`;
}

export function getForeignFAQSRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.FOREIGN}/${URL_KEYWORDS.FAQS}`;
}

export function getAccessConsultRoute(mainRoute: string): string {
  return `${mainRoute}/${URL_KEYWORDS.ACCESS_CONSULT}`;
}

export const CAFEXT_FIRST_WITHDRAWAL_FORM_URL = 'https://cafext.portalafp.cl/formulario/';
export const CAFEXT_SECOND_WITHDRAWAL_FORM_URL = 'https://cafext.portalafp.cl/formulario2/';
export const CAFEXT_THIRD_WITHDRAWAL_FORM_URL = 'https://cafext.portalafp.cl/formulario3/';
export const CAFEXT_BONUS_WITHDRAWAL_FORM_URL = 'https://cafext.portalafp.cl/formulario200/';

export const NOT_ANNULABLE_WITHDRAWAL_ROUTES = [
  MAIN_ROUTES.FIRST_WITHDRAWAL,
  MAIN_ROUTES.SECOND_WITHDRAWAL
];
