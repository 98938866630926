import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-serial-number-modal',
  templateUrl: './serial-number-modal.component.html',
  styleUrls: ['./serial-number-modal.component.scss']
})
export class SerialNumberModalComponent {

  constructor(
    public dialogRef: MatDialogRef<SerialNumberModalComponent>,
  ) {}

  public closeDialog() {
    this.dialogRef.close();
  }

}
