import { DigitsOnlyDirective } from 'src/app/directives/digits-only/digits-only.directive';

import { NgModule } from '@angular/core';

import { BlockCopyDirective } from './block-copy/block-copy.directive';
import { BlockPasteDirective } from './block-paste/block-paste.directive';
import { ForeignPhoneDirective } from './foreign-phone/foreign-phone.directive';
import { FormatRutDirective } from './format-rut/format-rut.directive';

@NgModule({
  exports: [
    FormatRutDirective,
    BlockCopyDirective,
    BlockPasteDirective,
    DigitsOnlyDirective,
    ForeignPhoneDirective
  ],
  declarations: [
    FormatRutDirective,
    BlockCopyDirective,
    BlockPasteDirective,
    DigitsOnlyDirective,
    ForeignPhoneDirective
  ],
})
export class DirectivesModule {}
