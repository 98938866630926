// tslint:disable: ban-types

import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BONUS_CERTIFICATE_NAME } from '@constants/fiscal-bonus.constant';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@constants/regex.constant';
import { getFAQSRoute, getRequestRoute, MAIN_ROUTES } from '@constants/routes.constant';
import { environment } from '@environment';
import { FiscalBonusData } from '@interfaces/fiscal-bonus.interface';
import { NavigationService } from '@providers/navigation/navigation.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-bonus-modal',
  templateUrl: './bonus-modal.component.html',
  styleUrls: ['./bonus-modal.component.scss']

})
export class BonusModalComponent {
  public form: FormGroup;
  public bonusClassifier: string;
  public withdrawalAvailable: string;
  public bonusAmount: number;
  public bonusFaqs = getFAQSRoute(MAIN_ROUTES.FISCAL_BONUS);
  public balanceUrl = environment.balanceUrl;
  public bonusRequestUrl = `retirounico.planvital.cl/${getRequestRoute(MAIN_ROUTES.FISCAL_BONUS)}`;
  public hrefBonusRequestUrl = `https://${this.bonusRequestUrl}`;
  private link: HTMLAnchorElement;

  public get email(): AbstractControl { return this.form.get('email'); }
  public get phoneNumber(): AbstractControl { return this.form.get('phoneNumber'); }
  public get isBonusBeneficiary(): boolean { return this.isBonusClassifierIndicator && this.withdrawalAvailable === 'S'; }
  public get isProcessedBonusBeneficiary(): boolean { return this.isBonusClassifierIndicator && this.withdrawalAvailable === 'P'; }
  public get isPendingBonusBeneficiary(): boolean { return this.bonusClassifier === 'P'; }
  public get isNotBonusBeneficiary(): boolean {
    return !this.isBonusBeneficiary && !this.isPendingBonusBeneficiary && !this.isProcessedBonusBeneficiary;
  }
  public get isBonusClassifierIndicator(): boolean { return ['A', 'B'].includes(this.bonusClassifier); }

  constructor(
    public dialogRef: MatDialogRef<BonusModalComponent>,
    public utils: Utils,
    private navService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public data: FiscalBonusData,
  ) {
    const { withdrawalAvailable, bonusAmount, bonusClassifier, email, phoneNumber, file } = data;
    this.bonusClassifier = bonusClassifier;
    this.withdrawalAvailable = withdrawalAvailable;
    this.bonusAmount = bonusAmount;
    if (this.isBonusBeneficiary && file) { this.link = this.utils.getDownloadPdfLink(file, BONUS_CERTIFICATE_NAME); }
    this.form = new FormGroup({
      email: new FormControl(email, [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]),
      phoneNumber: new FormControl(phoneNumber, [Validators.minLength(9), Validators.maxLength(9), Validators.pattern(PHONE_PATTERN)]),
    });
  }

  public closeDialog(callback?: boolean) {
    this.dialogRef.close(callback);
  }

  public primaryAction(): void {
    this.closeDialog(true);
  }

  public secondaryAction(): void {
    this.closeDialog(true);
  }

  public goBonusQuestions(): void {
    this.navService.goTo(getFAQSRoute(MAIN_ROUTES.FISCAL_BONUS), );
  }

  public downloadCertificate(): void {
    if (!this.link) { return; }
    this.link.click();
    this.link.remove();
  }
}
