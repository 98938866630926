import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCardModule, MatCheckboxModule, MatDatepickerModule, MatExpansionModule, MatFormFieldModule,
  MatIconModule,
  MatInputModule, MatListModule, MatNativeDateModule, MatRadioModule, MatSelectModule, MatStepperModule
} from '@angular/material';
import { DirectivesModule } from '@directives/directives.module';
import { IonicModule } from '@ionic/angular';
import { ChartistModule } from 'ng-chartist';
import {
  ExpressQuestionModalComponent
} from 'src/app/components/express-question-modal/express-question-modal.component';
import { AccordionListComponent } from './accordion-list/accordion-list.component';
import { AttachFilesComponent } from './attach-files/attach-files.component';
import { BannerComponent } from './banner/banner.component';
import { BonusModalComponent } from './bonus-modal/bonus-modal.component';
import { CallCenterModalComponent } from './call-center-modal/call-center-modal.component';
import {
  CancelGenericRequestModalComponent
} from './cancel-generic-request-modal/cancel-generic-request-modal.component';
import { CheckStatusModalComponent } from './check-status-modal/check-status-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { ContactResultModalComponent } from './contact-result-modal/contact-result-modal.component';
import { FooterComponent } from './footer/footer.component';
import {
  ForeignConfirmationModalComponent
} from './foreign-confirmation-modal/foreign-confirmation-modal.component';
import { ForeignFilesComponent } from './withdrawal-forms/foreign-files/foreign-files.component';
import { HeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { HeaderComponent } from './header/header.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { InfoBannerComponent } from './info-banner/info-banner.component';
import { InformationAlertComponent } from './information-alert/information-alert.component';
import { InformationModalComponent } from './information-modal/information-modal.component';
import { MaxAmountModalComponent } from './max-amount-modal/max-amount-modal.component';
import { ModalComponent } from './modal/modal.component';
import { PasswordModalComponent } from './password-modal/password-modal.component';
import { PillButtonComponent } from './pill-button/pill-button.component';
import {
  PoliticalInformationModalComponent
} from './political-information-modal/political-information-modal.component';
import { SerialNumberModalComponent } from './serial-number-modal/serial-number-modal.component';
import { SimulationModalComponent } from './simulation-modal/simulation-modal.component';
import { StepperComponent } from './stepper/stepper.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { VoucherComponent } from './voucher/voucher.component';
import { ForeignDocumentInfoComponent } from './withdrawal-forms/foreign-document-info/foreign-document-info.component';
import { ForeignPaymentOptionsComponent } from './withdrawal-forms/foreign-payment-options/foreign-payment-options.component';
import { LoginComponent } from './withdrawal-forms/login/login.component';
import { PaymentInfoFormComponent } from './withdrawal-forms/payment-info-form/payment-info-form.component';
import { PersonalInfoFormComponent } from './withdrawal-forms/personal-info-form/personal-info-form.component';
import { WithdrawalAmountComponent } from './withdrawal-forms/withdrawal-amount/withdrawal-amount.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatRadioModule,
    MatExpansionModule,
    MatCardModule,
    MatSelectModule,
    MatListModule,
    MatIconModule,
    ChartistModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
  ],
  declarations: [
    AccordionListComponent,
    BannerComponent,
    CheckStatusModalComponent,
    ConfirmationModalComponent,
    ContactFormComponent,
    ForeignConfirmationModalComponent,
    ExpressQuestionModalComponent,
    FooterComponent,
    HeaderComponent,
    HeaderDesktopComponent,
    InformationAlertComponent,
    InfoBannerComponent,
    LoginComponent,
    MaxAmountModalComponent,
    ModalComponent,
    PillButtonComponent,
    SerialNumberModalComponent,
    StepperComponent,
    TabNavigationComponent,
    VoucherComponent,
    PasswordModalComponent,
    CancelGenericRequestModalComponent,
    InformationModalComponent,
    ForeignDocumentInfoComponent,
    ForeignFilesComponent,
    PaymentInfoFormComponent,
    PersonalInfoFormComponent,
    PoliticalInformationModalComponent,
    SimulationModalComponent,
    BonusModalComponent,
    AttachFilesComponent,
    HelpButtonComponent,
    CallCenterModalComponent,
    ContactModalComponent,
    ContactResultModalComponent,
    ForeignPaymentOptionsComponent,
    WithdrawalAmountComponent
  ],
  exports: [
    AccordionListComponent,
    BannerComponent,
    ContactFormComponent,
    FooterComponent,
    HeaderComponent,
    HeaderDesktopComponent,
    InformationAlertComponent,
    InfoBannerComponent,
    LoginComponent,
    ModalComponent,
    PillButtonComponent,
    StepperComponent,
    TabNavigationComponent,
    VoucherComponent,
    InformationModalComponent,
    ForeignDocumentInfoComponent,
    ForeignFilesComponent,
    PaymentInfoFormComponent,
    PersonalInfoFormComponent,
    PoliticalInformationModalComponent,
    SimulationModalComponent,
    BonusModalComponent,
    AttachFilesComponent,
    HelpButtonComponent,
    CallCenterModalComponent,
    ContactModalComponent,
    ContactResultModalComponent,
    ForeignPaymentOptionsComponent,
    WithdrawalAmountComponent
  ],
  entryComponents: [
    CheckStatusModalComponent,
    ConfirmationModalComponent,
    ExpressQuestionModalComponent,
    ForeignConfirmationModalComponent,
    SerialNumberModalComponent,
    ModalComponent,
    MaxAmountModalComponent,
    PasswordModalComponent,
    CancelGenericRequestModalComponent,
    InformationModalComponent,
    PoliticalInformationModalComponent,
    SimulationModalComponent,
    BonusModalComponent,
    CallCenterModalComponent,
    ContactModalComponent,
    ContactResultModalComponent,
    ForeignPaymentOptionsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
