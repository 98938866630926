export const MALE_BALANCE_VALUES = [
  {
    id: 25,
    values: [
      '$487.544 y $975.088',
      '$975.088 y $1.462.632',
      '$1.462.632 y $1.950.176',
      '$1.950.176 y $2.437.720',
      '$2.437.720 y $2.925.263',
      '$2.925.263 y $3.412.807',
      '$3.412.807 y $3.900.351',
      '$3.900.351 y $4.387.895',
      '$4.387.895 y $4.875.439',
      '$4.875.439 y $6.094.299',
      '$6.094.299 y $7.313.159',
      '$7.313.159 y $8.532.019',
      '$8.532.019 y $9.750.878',
      '$9.750.878 y $12.188.598',
      '$12.188.598 y $14.626.317',
      '$14.626.317 y $17.064.037',
      '$17.064.037 y $19.501.757',
      '$19.501.757 y $21.569.525'
    ]
  },
  {
    id: 26,
    values: [
      '$468.612 y $937.224',
      '$937.224 y $1.405.836',
      '$1.405.836 y $1.874.448',
      '$1.874.448 y $2.343.060',
      '$2.343.060 y $2.811.672',
      '$2.811.672 y $3.280.284',
      '$3.280.284 y $3.748.896',
      '$3.748.896 y $4.217.508',
      '$4.217.508 y $4.686.120',
      '$4.686.120 y $5.857.650',
      '$5.857.650 y $7.029.180',
      '$7.029.180 y $8.200.710',
      '$8.200.710 y $9.372.240',
      '$9.372.240 y $11.715.300',
      '$11.715.300 y $14.058.360',
      '$14.058.360 y $16.401.420',
      '$16.401.420 y $18.744.480',
      '$18.744.480 y $20.731.954'
    ]
  },
  {
    id: 27,
    values: [
      '$450.415 y $900.830',
      '$900.830 y $1.351.246',
      '$1.351.246 y $1.801.661',
      '$1.801.661 y $2.252.076',
      '$2.252.076 y $2.702.491',
      '$2.702.491 y $3.152.907',
      '$3.152.907 y $3.603.322',
      '$3.603.322 y $4.053.737',
      '$4.053.737 y $4.504.152',
      '$4.504.152 y $5.630.190',
      '$5.630.190 y $6.756.228',
      '$6.756.228 y $7.882.266',
      '$7.882.266 y $9.008.304',
      '$9.008.304 y $11.260.380',
      '$11.260.380 y $13.512.456',
      '$13.512.456 y $15.764.533',
      '$15.764.533 y $18.016.609',
      '$18.016.609 y $19.926.907'
    ]
  },
  {
    id: 28,
    values: [
      '$432.925 y $865.850',
      '$865.850 y $1.298.775',
      '$1.298.775 y $1.731.700',
      '$1.731.700 y $2.164.625',
      '$2.164.625 y $2.597.550',
      '$2.597.550 y $3.030.475',
      '$3.030.475 y $3.463.400',
      '$3.463.400 y $3.896.325',
      '$3.896.325 y $4.329.250',
      '$4.329.250 y $5.411.563',
      '$5.411.563 y $6.493.876',
      '$6.493.876 y $7.576.188',
      '$7.576.188 y $8.658.501',
      '$8.658.501 y $10.823.126',
      '$10.823.126 y $12.987.751',
      '$12.987.751 y $15.152.377',
      '$15.152.377 y $17.317.002',
      '$17.317.002 y $19.153.121'
    ]
  },
  {
    id: 29,
    values: [
      '$416.114 y $832.228',
      '$832.228 y $1.248.342',
      '$1.248.342 y $1.664.456',
      '$1.664.456 y $2.080.570',
      '$2.080.570 y $2.496.684',
      '$2.496.684 y $2.912.798',
      '$2.912.798 y $3.328.912',
      '$3.328.912 y $3.745.026',
      '$3.745.026 y $4.161.140',
      '$4.161.140 y $5.201.425',
      '$5.201.425 y $6.241.711',
      '$6.241.711 y $7.281.996',
      '$7.281.996 y $8.322.281',
      '$8.322.281 y $10.402.851',
      '$10.402.851 y $12.483.421',
      '$12.483.421 y $14.563.991',
      '$14.563.991 y $16.644.562',
      '$16.644.562 y $18.409.382'
    ]
  },
  {
    id: 30,
    values: [
      '$399.956 y $799.912',
      '$799.912 y $1.199.867',
      '$1.199.867 y $1.599.823',
      '$1.599.823 y $1.999.779',
      '$1.999.779 y $2.399.735',
      '$2.399.735 y $2.799.691',
      '$2.799.691 y $3.199.647',
      '$3.199.647 y $3.599.602',
      '$3.599.602 y $3.999.558',
      '$3.999.558 y $4.999.448',
      '$4.999.448 y $5.999.337',
      '$5.999.337 y $6.999.227',
      '$6.999.227 y $7.999.116',
      '$7.999.116 y $9.998.896',
      '$9.998.896 y $11.998.675',
      '$11.998.675 y $13.998.454',
      '$13.998.454 y $15.998.233',
      '$15.998.233 y $17.694.524'
    ]
  },
  {
    id: 31,
    values: [
      '$384.425 y $768.850',
      '$768.850 y $1.153.275',
      '$1.153.275 y $1.537.700',
      '$1.537.700 y $1.922.125',
      '$1.922.125 y $2.306.550',
      '$2.306.550 y $2.690.975',
      '$2.690.975 y $3.075.400',
      '$3.075.400 y $3.459.825',
      '$3.459.825 y $3.844.251',
      '$3.844.251 y $4.805.313',
      '$4.805.313 y $5.766.376',
      '$5.766.376 y $6.727.438',
      '$6.727.438 y $7.688.501',
      '$7.688.501 y $9.610.626',
      '$9.610.626 y $11.532.752',
      '$11.532.752 y $13.454.877',
      '$13.454.877 y $15.377.002',
      '$15.377.002 y $17.007.424'
    ]
  },
  {
    id: 32,
    values: [
      '$369.497 y $738.995',
      '$738.995 y $1.108.492',
      '$1.108.492 y $1.477.989',
      '$1.477.989 y $1.847.487',
      '$1.847.487 y $2.216.984',
      '$2.216.984 y $2.586.481',
      '$2.586.481 y $2.955.979',
      '$2.955.979 y $3.325.476',
      '$3.325.476 y $3.694.974',
      '$3.694.974 y $4.618.717',
      '$4.618.717 y $5.542.460',
      '$5.542.460 y $6.466.204',
      '$6.466.204 y $7.389.947',
      '$7.389.947 y $9.237.434',
      '$9.237.434 y $11.084.921',
      '$11.084.921 y $12.932.407',
      '$12.932.407 y $14.779.894',
      '$14.779.894 y $16.347.005'
    ]
  },
  {
    id: 33,
    values: [
      '$355.149 y $710.299',
      '$710.299 y $1.065.448',
      '$1.065.448 y $1.420.597',
      '$1.420.597 y $1.775.747',
      '$1.775.747 y $2.130.896',
      '$2.130.896 y $2.486.045',
      '$2.486.045 y $2.841.195',
      '$2.841.195 y $3.196.344',
      '$3.196.344 y $3.551.493',
      '$3.551.493 y $4.439.367',
      '$4.439.367 y $5.327.240',
      '$5.327.240 y $6.215.113',
      '$6.215.113 y $7.102.986',
      '$7.102.986 y $8.878.733',
      '$8.878.733 y $10.654.480',
      '$10.654.480 y $12.430.226',
      '$12.430.226 y $14.205.973',
      '$14.205.973 y $15.712.231'
    ]
  },
  {
    id: 34,
    values: [
      '$341.358 y $682.717',
      '$682.717 y $1.024.075',
      '$1.024.075 y $1.365.434',
      '$1.365.434 y $1.706.792',
      '$1.706.792 y $2.048.151',
      '$2.048.151 y $2.389.509',
      '$2.389.509 y $2.730.868',
      '$2.730.868 y $3.072.226',
      '$3.072.226 y $3.413.584',
      '$3.413.584 y $4.266.981',
      '$4.266.981 y $5.120.377',
      '$5.120.377 y $5.973.773',
      '$5.973.773 y $6.827.169',
      '$6.827.169 y $8.533.961',
      '$8.533.961 y $10.240.753',
      '$10.240.753 y $11.947.546',
      '$11.947.546 y $13.654.338',
      '$13.654.338 y $15.102.105'
    ]
  },
  {
    id: 35,
    values: [
      '$328.103 y $656.206',
      '$656.206 y $984.309',
      '$984.309 y $1.312.412',
      '$1.312.412 y $1.640.515',
      '$1.640.515 y $1.968.618',
      '$1.968.618 y $2.296.722',
      '$2.296.722 y $2.624.825',
      '$2.624.825 y $2.952.928',
      '$2.952.928 y $3.281.031',
      '$3.281.031 y $4.101.288',
      '$4.101.288 y $4.921.546',
      '$4.921.546 y $5.741.804',
      '$5.741.804 y $6.562.062',
      '$6.562.062 y $8.202.577',
      '$8.202.577 y $9.843.092',
      '$9.843.092 y $11.483.608',
      '$11.483.608 y $13.124.123',
      '$13.124.123 y $14.515.672'
    ]
  },
  {
    id: 36,
    values: [
      '$315.362 y $630.725',
      '$630.725 y $946.087',
      '$946.087 y $1.261.450',
      '$1.261.450 y $1.576.812',
      '$1.576.812 y $1.892.175',
      '$1.892.175 y $2.207.537',
      '$2.207.537 y $2.522.899',
      '$2.522.899 y $2.838.262',
      '$2.838.262 y $3.153.624',
      '$3.153.624 y $3.942.030',
      '$3.942.030 y $4.730.437',
      '$4.730.437 y $5.518.843',
      '$5.518.843 y $6.307.249',
      '$6.307.249 y $7.884.061',
      '$7.884.061 y $9.460.873',
      '$9.460.873 y $11.037.685',
      '$11.037.685 y $12.614.497',
      '$12.614.497 y $13.952.011'
    ]
  },
  {
    id: 37,
    values: [
      '$303.117 y $606.233',
      '$606.233 y $909.350',
      '$909.350 y $1.212.466',
      '$1.212.466 y $1.515.583',
      '$1.515.583 y $1.818.699',
      '$1.818.699 y $2.121.816',
      '$2.121.816 y $2.424.932',
      '$2.424.932 y $2.728.049',
      '$2.728.049 y $3.031.165',
      '$3.031.165 y $3.788.957',
      '$3.788.957 y $4.546.748',
      '$4.546.748 y $5.304.539',
      '$5.304.539 y $6.062.331',
      '$6.062.331 y $7.577.913',
      '$7.577.913 y $9.093.496',
      '$9.093.496 y $10.609.079',
      '$10.609.079 y $12.124.661',
      '$12.124.661 y $13.410.237'
    ]
  },
  {
    id: 38,
    values: [
      '$291.346 y $582.692',
      '$582.692 y $874.038',
      '$874.038 y $1.165.385',
      '$1.165.385 y $1.456.731',
      '$1.456.731 y $1.748.077',
      '$1.748.077 y $2.039.423',
      '$2.039.423 y $2.330.769',
      '$2.330.769 y $2.622.115',
      '$2.622.115 y $2.913.461',
      '$2.913.461 y $3.641.827',
      '$3.641.827 y $4.370.192',
      '$4.370.192 y $5.098.558',
      '$5.098.558 y $5.826.923',
      '$5.826.923 y $7.283.654',
      '$7.283.654 y $8.740.384',
      '$8.740.384 y $10.197.115',
      '$10.197.115 y $11.653.846',
      '$11.653.846 y $12.889.502'
    ]
  },
  {
    id: 39,
    values: [
      '$280.033 y $560.066',
      '$560.066 y $840.098',
      '$840.098 y $1.120.131',
      '$1.120.131 y $1.400.164',
      '$1.400.164 y $1.680.197',
      '$1.680.197 y $1.960.230',
      '$1.960.230 y $2.240.263',
      '$2.240.263 y $2.520.295',
      '$2.520.295 y $2.800.328',
      '$2.800.328 y $3.500.410',
      '$3.500.410 y $4.200.492',
      '$4.200.492 y $4.900.574',
      '$4.900.574 y $5.600.656',
      '$5.600.656 y $7.000.820',
      '$7.000.820 y $8.400.985',
      '$8.400.985 y $9.801.149',
      '$9.801.149 y $11.201.313',
      '$11.201.313 y $12.388.987'
    ]
  },
  {
    id: 40,
    values: [
      '$269.159 y $538.318',
      '$538.318 y $807.476',
      '$807.476 y $1.076.635',
      '$1.076.635 y $1.345.794',
      '$1.345.794 y $1.614.953',
      '$1.614.953 y $1.884.112',
      '$1.884.112 y $2.153.270',
      '$2.153.270 y $2.422.429',
      '$2.422.429 y $2.691.588',
      '$2.691.588 y $3.364.485',
      '$3.364.485 y $4.037.382',
      '$4.037.382 y $4.710.279',
      '$4.710.279 y $5.383.176',
      '$5.383.176 y $6.728.970',
      '$6.728.970 y $8.074.764',
      '$8.074.764 y $9.420.558',
      '$9.420.558 y $10.766.352',
      '$10.766.352 y $11.907.907'
    ]
  },
  {
    id: 41,
    values: [
      '$258.707 y $517.414',
      '$517.414 y $776.121',
      '$776.121 y $1.034.828',
      '$1.034.828 y $1.293.535',
      '$1.293.535 y $1.552.242',
      '$1.552.242 y $1.810.949',
      '$1.810.949 y $2.069.656',
      '$2.069.656 y $2.328.363',
      '$2.328.363 y $2.587.070',
      '$2.587.070 y $3.233.838',
      '$3.233.838 y $3.880.606',
      '$3.880.606 y $4.527.373',
      '$4.527.373 y $5.174.141',
      '$5.174.141 y $6.467.676',
      '$6.467.676 y $7.761.211',
      '$7.761.211 y $9.054.746',
      '$9.054.746 y $10.348.282',
      '$10.348.282 y $11.445.509'
    ]
  },
  {
    id: 42,
    values: [
      '$248.661 y $497.322',
      '$497.322 y $745.983',
      '$745.983 y $994.645',
      '$994.645 y $1.243.306',
      '$1.243.306 y $1.491.967',
      '$1.491.967 y $1.740.628',
      '$1.740.628 y $1.989.289',
      '$1.989.289 y $2.237.950',
      '$2.237.950 y $2.486.611',
      '$2.486.611 y $3.108.264',
      '$3.108.264 y $3.729.917',
      '$3.729.917 y $4.351.570',
      '$4.351.570 y $4.973.223',
      '$4.973.223 y $6.216.528',
      '$6.216.528 y $7.459.834',
      '$7.459.834 y $8.703.140',
      '$8.703.140 y $9.946.445',
      '$9.946.445 y $11.001.065'
    ]
  },
  {
    id: 43,
    values: [
      '$239.005 y $478.011',
      '$478.011 y $717.016',
      '$717.016 y $956.021',
      '$956.021 y $1.195.027',
      '$1.195.027 y $1.434.032',
      '$1.434.032 y $1.673.037',
      '$1.673.037 y $1.912.043',
      '$1.912.043 y $2.151.048',
      '$2.151.048 y $2.390.053',
      '$2.390.053 y $2.987.566',
      '$2.987.566 y $3.585.080',
      '$3.585.080 y $4.182.593',
      '$4.182.593 y $4.780.106',
      '$4.780.106 y $5.975.133',
      '$5.975.133 y $7.170.159',
      '$7.170.159 y $8.365.186',
      '$8.365.186 y $9.560.213',
      '$9.560.213 y $10.573.881'
    ]
  },
  {
    id: 44,
    values: [
      '$229.724 y $459.449',
      '$459.449 y $689.173',
      '$689.173 y $918.898',
      '$918.898 y $1.148.622',
      '$1.148.622 y $1.378.347',
      '$1.378.347 y $1.608.071',
      '$1.608.071 y $1.837.796',
      '$1.837.796 y $2.067.520',
      '$2.067.520 y $2.297.244',
      '$2.297.244 y $2.871.556',
      '$2.871.556 y $3.445.867',
      '$3.445.867 y $4.020.178',
      '$4.020.178 y $4.594.489',
      '$4.594.489 y $5.743.111',
      '$5.743.111 y $6.891.733',
      '$6.891.733 y $8.040.356',
      '$8.040.356 y $9.188.978',
      '$9.188.978 y $10.163.284'
    ]
  },
  {
    id: 45,
    values: [
      '$220.804 y $441.608',
      '$441.608 y $662.412',
      '$662.412 y $883.216',
      '$883.216 y $1.104.020',
      '$1.104.020 y $1.324.824',
      '$1.324.824 y $1.545.628',
      '$1.545.628 y $1.766.432',
      '$1.766.432 y $1.987.236',
      '$1.987.236 y $2.208.040',
      '$2.208.040 y $2.760.050',
      '$2.760.050 y $3.312.059',
      '$3.312.059 y $3.864.069',
      '$3.864.069 y $4.416.079',
      '$4.416.079 y $5.520.099',
      '$5.520.099 y $6.624.119',
      '$6.624.119 y $7.728.139',
      '$7.728.139 y $8.832.159',
      '$8.832.159 y $9.768.631'
    ]
  },
  {
    id: 46,
    values: [
      '$212.230 y $424.460',
      '$424.460 y $636.690',
      '$636.690 y $848.920',
      '$848.920 y $1.061.149',
      '$1.061.149 y $1.273.379',
      '$1.273.379 y $1.485.609',
      '$1.485.609 y $1.697.839',
      '$1.697.839 y $1.910.069',
      '$1.910.069 y $2.122.299',
      '$2.122.299 y $2.652.873',
      '$2.652.873 y $3.183.448',
      '$3.183.448 y $3.714.023',
      '$3.714.023 y $4.244.598',
      '$4.244.598 y $5.305.747',
      '$5.305.747 y $6.366.896',
      '$6.366.896 y $7.428.046',
      '$7.428.046 y $8.489.195',
      '$8.489.195 y $9.389.303'
    ]
  },
  {
    id: 47,
    values: [
      '$203.989 y $407.977',
      '$407.977 y $611.966',
      '$611.966 y $815.955',
      '$815.955 y $1.019.944',
      '$1.019.944 y $1.223.932',
      '$1.223.932 y $1.427.921',
      '$1.427.921 y $1.631.910',
      '$1.631.910 y $1.835.899',
      '$1.835.899 y $2.039.887',
      '$2.039.887 y $2.549.859',
      '$2.549.859 y $3.059.831',
      '$3.059.831 y $3.569.803',
      '$3.569.803 y $4.079.775',
      '$4.079.775 y $5.099.718',
      '$5.099.718 y $6.119.662',
      '$6.119.662 y $7.139.606',
      '$7.139.606 y $8.159.549',
      '$8.159.549 y $9.024.705'
    ]
  },
  {
    id: 48,
    values: [
      '$196.068 y $392.135',
      '$392.135 y $588.203',
      '$588.203 y $784.270',
      '$784.270 y $980.338',
      '$980.338 y $1.176.406',
      '$1.176.406 y $1.372.473',
      '$1.372.473 y $1.568.541',
      '$1.568.541 y $1.764.608',
      '$1.764.608 y $1.960.676',
      '$1.960.676 y $2.450.845',
      '$2.450.845 y $2.941.014',
      '$2.941.014 y $3.431.183',
      '$3.431.183 y $3.921.352',
      '$3.921.352 y $4.901.690',
      '$4.901.690 y $5.882.028',
      '$5.882.028 y $6.862.366',
      '$6.862.366 y $7.842.704',
      '$7.842.704 y $8.674.265'
    ]
  },
  {
    id: 49,
    values: [
      '$188.454 y $376.908',
      '$376.908 y $565.362',
      '$565.362 y $753.816',
      '$753.816 y $942.270',
      '$942.270 y $1.130.724',
      '$1.130.724 y $1.319.178',
      '$1.319.178 y $1.507.632',
      '$1.507.632 y $1.696.087',
      '$1.696.087 y $1.884.541',
      '$1.884.541 y $2.355.676',
      '$2.355.676 y $2.826.811',
      '$2.826.811 y $3.297.946',
      '$3.297.946 y $3.769.081',
      '$3.769.081 y $4.711.351',
      '$4.711.351 y $5.653.622',
      '$5.653.622 y $6.595.892',
      '$6.595.892 y $7.538.162',
      '$7.538.162 y $8.337.433'
    ]
  },
  {
    id: 50,
    values: [
      '$181.136 y $362.272',
      '$362.272 y $543.408',
      '$543.408 y $724.545',
      '$724.545 y $905.681',
      '$905.681 y $1.086.817',
      '$1.086.817 y $1.267.953',
      '$1.267.953 y $1.449.089',
      '$1.449.089 y $1.630.225',
      '$1.630.225 y $1.811.362',
      '$1.811.362 y $2.264.202',
      '$2.264.202 y $2.717.042',
      '$2.717.042 y $3.169.883',
      '$3.169.883 y $3.622.723',
      '$3.622.723 y $4.528.404',
      '$4.528.404 y $5.434.085',
      '$5.434.085 y $6.339.766',
      '$6.339.766 y $7.245.446',
      '$7.245.446 y $8.013.680'
    ]
  },
  {
    id: 51,
    values: [
      '$174.102 y $348.205',
      '$348.205 y $522.307',
      '$522.307 y $696.410',
      '$696.410 y $870.512',
      '$870.512 y $1.044.615',
      '$1.044.615 y $1.218.717',
      '$1.218.717 y $1.392.819',
      '$1.392.819 y $1.566.922',
      '$1.566.922 y $1.741.024',
      '$1.741.024 y $2.176.280',
      '$2.176.280 y $2.611.536',
      '$2.611.536 y $3.046.792',
      '$3.046.792 y $3.482.048',
      '$3.482.048 y $4.352.561',
      '$4.352.561 y $5.223.073',
      '$5.223.073 y $6.093.585',
      '$6.093.585 y $6.964.097',
      '$6.964.097 y $7.702.499'
    ]
  },
  {
    id: 52,
    values: [
      '$167.342 y $334.684',
      '$334.684 y $502.025',
      '$502.025 y $669.367',
      '$669.367 y $836.709',
      '$836.709 y $1.004.051',
      '$1.004.051 y $1.171.393',
      '$1.171.393 y $1.338.734',
      '$1.338.734 y $1.506.076',
      '$1.506.076 y $1.673.418',
      '$1.673.418 y $2.091.773',
      '$2.091.773 y $2.510.127',
      '$2.510.127 y $2.928.482',
      '$2.928.482 y $3.346.836',
      '$3.346.836 y $4.183.545',
      '$4.183.545 y $5.020.254',
      '$5.020.254 y $5.856.963',
      '$5.856.963 y $6.693.672',
      '$6.693.672 y $7.403.402'
    ]
  },
  {
    id: 53,
    values: [
      '$160.844 y $321.687',
      '$321.687 y $482.531',
      '$482.531 y $643.375',
      '$643.375 y $804.219',
      '$804.219 y $965.062',
      '$965.062 y $1.125.906',
      '$1.125.906 y $1.286.750',
      '$1.286.750 y $1.447.594',
      '$1.447.594 y $1.608.437',
      '$1.608.437 y $2.010.547',
      '$2.010.547 y $2.412.656',
      '$2.412.656 y $2.814.765',
      '$2.814.765 y $3.216.874',
      '$3.216.874 y $4.021.093',
      '$4.021.093 y $4.825.312',
      '$4.825.312 y $5.629.530',
      '$5.629.530 y $6.433.749',
      '$6.433.749 y $7.115.919'
    ]
  },
  {
    id: 54,
    values: [
      '$154.598 y $309.196',
      '$309.196 y $463.794',
      '$463.794 y $618.392',
      '$618.392 y $772.990',
      '$772.990 y $927.588',
      '$927.588 y $1.082.186',
      '$1.082.186 y $1.236.784',
      '$1.236.784 y $1.391.382',
      '$1.391.382 y $1.545.980',
      '$1.545.980 y $1.932.475',
      '$1.932.475 y $2.318.970',
      '$2.318.970 y $2.705.464',
      '$2.705.464 y $3.091.959',
      '$3.091.959 y $3.864.949',
      '$3.864.949 y $4.637.939',
      '$4.637.939 y $5.410.929',
      '$5.410.929 y $6.183.919',
      '$6.183.919 y $6.839.599'
    ]
  },
  {
    id: 55,
    values: [
      '$148.595 y $297.189',
      '$297.189 y $445.784',
      '$445.784 y $594.379',
      '$594.379 y $742.974',
      '$742.974 y $891.568',
      '$891.568 y $1.040.163',
      '$1.040.163 y $1.188.758',
      '$1.188.758 y $1.337.353',
      '$1.337.353 y $1.485.947',
      '$1.485.947 y $1.857.434',
      '$1.857.434 y $2.228.921',
      '$2.228.921 y $2.600.408',
      '$2.600.408 y $2.971.895',
      '$2.971.895 y $3.714.868',
      '$3.714.868 y $4.457.842',
      '$4.457.842 y $5.200.816',
      '$5.200.816 y $5.943.790',
      '$5.943.790 y $6.574.009'
    ]
  },
  {
    id: 56,
    values: [
      '$142.825 y $285.649',
      '$285.649 y $428.474',
      '$428.474 y $571.298',
      '$571.298 y $714.123',
      '$714.123 y $856.948',
      '$856.948 y $999.772',
      '$999.772 y $1.142.597',
      '$1.142.597 y $1.285.422',
      '$1.285.422 y $1.428.246',
      '$1.428.246 y $1.785.308',
      '$1.785.308 y $2.142.369',
      '$2.142.369 y $2.499.431',
      '$2.499.431 y $2.856.492',
      '$2.856.492 y $3.570.616',
      '$3.570.616 y $4.284.739',
      '$4.284.739 y $4.998.862',
      '$4.998.862 y $5.712.985',
      '$5.712.985 y $6.318.732'
    ]
  },
  {
    id: 57,
    values: [
      '$137.279 y $274.557',
      '$274.557 y $411.836',
      '$411.836 y $549.114',
      '$549.114 y $686.393',
      '$686.393 y $823.671',
      '$823.671 y $960.950',
      '$960.950 y $1.098.229',
      '$1.098.229 y $1.235.507',
      '$1.235.507 y $1.372.786',
      '$1.372.786 y $1.715.982',
      '$1.715.982 y $2.059.179',
      '$2.059.179 y $2.402.375',
      '$2.402.375 y $2.745.571',
      '$2.745.571 y $3.431.964',
      '$3.431.964 y $4.118.357',
      '$4.118.357 y $4.804.750',
      '$4.804.750 y $5.491.143',
      '$5.491.143 y $6.073.368'
    ]
  },
  {
    id: 58,
    values: [
      '$131.948 y $263.896',
      '$263.896 y $395.844',
      '$395.844 y $527.792',
      '$527.792 y $659.739',
      '$659.739 y $791.687',
      '$791.687 y $923.635',
      '$923.635 y $1.055.583',
      '$1.055.583 y $1.187.531',
      '$1.187.531 y $1.319.479',
      '$1.319.479 y $1.649.348',
      '$1.649.348 y $1.979.218',
      '$1.979.218 y $2.309.088',
      '$2.309.088 y $2.638.958',
      '$2.638.958 y $3.298.697',
      '$3.298.697 y $3.958.436',
      '$3.958.436 y $4.618.176',
      '$4.618.176 y $5.277.915',
      '$5.277.915 y $5.837.532'
    ]
  },
  {
    id: 59,
    values: [
      '$126.824 y $253.648',
      '$253.648 y $380.473',
      '$380.473 y $507.297',
      '$507.297 y $634.121',
      '$634.121 y $760.945',
      '$760.945 y $887.769',
      '$887.769 y $1.014.593',
      '$1.014.593 y $1.141.418',
      '$1.141.418 y $1.268.242',
      '$1.268.242 y $1.585.302',
      '$1.585.302 y $1.902.363',
      '$1.902.363 y $2.219.423',
      '$2.219.423 y $2.536.484',
      '$2.536.484 y $3.170.604',
      '$3.170.604 y $3.804.725',
      '$3.804.725 y $4.438.846',
      '$4.438.846 y $5.072.967',
      '$5.072.967 y $5.610.853'
    ]
  },
  {
    id: 60,
    values: [
      '$121.899 y $243.799',
      '$243.799 y $365.698',
      '$365.698 y $487.598',
      '$487.598 y $609.497',
      '$609.497 y $731.397',
      '$731.397 y $853.296',
      '$853.296 y $975.196',
      '$975.196 y $1.097.095',
      '$1.097.095 y $1.218.994',
      '$1.218.994 y $1.523.743',
      '$1.523.743 y $1.828.492',
      '$1.828.492 y $2.133.240',
      '$2.133.240 y $2.437.989',
      '$2.437.989 y $3.047.486',
      '$3.047.486 y $3.656.983',
      '$3.656.983 y $4.266.480',
      '$4.266.480 y $4.875.978',
      '$4.875.978 y $5.392.977'
    ]
  },
  {
    id: 61,
    values: [
      '$117.166 y $234.332',
      '$234.332 y $351.498',
      '$351.498 y $468.664',
      '$468.664 y $585.830',
      '$585.830 y $702.996',
      '$702.996 y $820.162',
      '$820.162 y $937.328',
      '$937.328 y $1.054.493',
      '$1.054.493 y $1.171.659',
      '$1.171.659 y $1.464.574',
      '$1.464.574 y $1.757.489',
      '$1.757.489 y $2.050.404',
      '$2.050.404 y $2.343.319',
      '$2.343.319 y $2.929.148',
      '$2.929.148 y $3.514.978',
      '$3.514.978 y $4.100.808',
      '$4.100.808 y $4.686.638',
      '$4.686.638 y $5.183.561'
    ]
  },
  {
    id: 62,
    values: [
      '$112.616 y $225.232',
      '$225.232 y $337.849',
      '$337.849 y $450.465',
      '$450.465 y $563.081',
      '$563.081 y $675.697',
      '$675.697 y $788.314',
      '$788.314 y $900.930',
      '$900.930 y $1.013.546',
      '$1.013.546 y $1.126.162',
      '$1.126.162 y $1.407.703',
      '$1.407.703 y $1.689.244',
      '$1.689.244 y $1.970.784',
      '$1.970.784 y $2.252.325',
      '$2.252.325 y $2.815.406',
      '$2.815.406 y $3.378.487',
      '$3.378.487 y $3.941.568',
      '$3.941.568 y $4.504.650',
      '$4.504.650 y $4.982.277'
    ]
  },
  {
    id: 63,
    values: [
      '$108.243 y $216.486',
      '$216.486 y $324.730',
      '$324.730 y $432.973',
      '$432.973 y $541.216',
      '$541.216 y $649.459',
      '$649.459 y $757.703',
      '$757.703 y $865.946',
      '$865.946 y $974.189',
      '$974.189 y $1.082.432',
      '$1.082.432 y $1.353.040',
      '$1.353.040 y $1.623.648',
      '$1.623.648 y $1.894.256',
      '$1.894.256 y $2.164.864',
      '$2.164.864 y $2.706.080',
      '$2.706.080 y $3.247.296',
      '$3.247.296 y $3.788.513',
      '$3.788.513 y $4.329.729',
      '$4.329.729 y $4.788.809'
    ]
  },
  {
    id: 64,
    values: [
      '$104.040 y $208.080',
      '$208.080 y $312.120',
      '$312.120 y $416.160',
      '$416.160 y $520.200',
      '$520.200 y $624.240',
      '$624.240 y $728.280',
      '$728.280 y $832.320',
      '$832.320 y $936.360',
      '$936.360 y $1.040.400',
      '$1.040.400 y $1.300.500',
      '$1.300.500 y $1.560.600',
      '$1.560.600 y $1.820.700',
      '$1.820.700 y $2.080.800',
      '$2.080.800 y $2.601.000',
      '$2.601.000 y $3.121.200',
      '$3.121.200 y $3.641.400',
      '$3.641.400 y $4.161.600',
      '$4.161.600 y $4.602.854'
    ]
  },
  {
    id: 65,
    values: [
      '$100.000 y $200.000',
      '$200.000 y $300.000',
      '$300.000 y $400.000',
      '$400.000 y $500.000',
      '$500.000 y $600.000',
      '$600.000 y $700.000',
      '$700.000 y $800.000',
      '$800.000 y $900.000',
      '$900.000 y $1.000.000',
      '$1.000.000 y $1.250.000',
      '$1.250.000 y $1.500.000',
      '$1.500.000 y $1.750.000',
      '$1.750.000 y $2.000.000',
      '$2.000.000 y $2.500.000',
      '$2.500.000 y $3.000.000',
      '$3.000.000 y $3.500.000',
      '$3.500.000 y $4.000.000',
      '$4.000.000 y $4.424.120'
    ]
  }
];

export const MALE_PENSION_VALUES = [
  {
    id: 25,
    values: [
      '$2.302 y $4.604',
      '$4.604 y $6.906',
      '$6.906 y $9.208',
      '$9.208 y $11.510',
      '$11.510 y $13.812',
      '$13.812 y $16.114',
      '$16.114 y $18.416',
      '$18.416 y $20.718',
      '$20.718 y $23.020',
      '$23.020 y $28.775',
      '$28.775 y $34.530',
      '$34.530 y $40.285',
      '$40.285 y $46.040',
      '$46.040 y $57.550',
      '$57.550 y $69.060',
      '$69.060 y $80.570',
      '$80.570 y $92.080',
      '$92.080 y $101.844'
    ]
  },
  {
    id: 26,
    values: [
      '$2.218 y $4.437',
      '$4.437 y $6.655',
      '$6.655 y $8.873',
      '$8.873 y $11.092',
      '$11.092 y $13.310',
      '$13.310 y $15.528',
      '$15.528 y $17.747',
      '$17.747 y $19.965',
      '$19.965 y $22.183',
      '$22.183 y $27.729',
      '$27.729 y $33.275',
      '$33.275 y $38.821',
      '$38.821 y $44.367',
      '$44.367 y $55.458',
      '$55.458 y $66.550',
      '$66.550 y $77.642',
      '$77.642 y $88.733',
      '$88.733 y $98.142'
    ]
  },
  {
    id: 27,
    values: [
      '$2.138 y $4.276',
      '$4.276 y $6.413',
      '$6.413 y $8.551',
      '$8.551 y $10.689',
      '$10.689 y $12.827',
      '$12.827 y $14.964',
      '$14.964 y $17.102',
      '$17.102 y $19.240',
      '$19.240 y $21.378',
      '$21.378 y $26.722',
      '$26.722 y $32.066',
      '$32.066 y $37.411',
      '$37.411 y $42.755',
      '$42.755 y $53.444',
      '$53.444 y $64.133',
      '$64.133 y $74.822',
      '$74.822 y $85.510',
      '$85.510 y $94.577'
    ]
  },
  {
    id: 28,
    values: [
      '$2.060 y $4.120',
      '$4.120 y $6.181',
      '$6.181 y $8.241',
      '$8.241 y $10.301',
      '$10.301 y $12.361',
      '$12.361 y $14.421',
      '$14.421 y $16.481',
      '$16.481 y $18.542',
      '$18.542 y $20.602',
      '$20.602 y $25.752',
      '$25.752 y $30.903',
      '$30.903 y $36.053',
      '$36.053 y $41.203',
      '$41.203 y $51.504',
      '$51.504 y $61.805',
      '$61.805 y $72.106',
      '$72.106 y $82.407',
      '$82.407 y $91.145'
    ]
  },
  {
    id: 29,
    values: [
      '$1.985 y $3.971',
      '$3.971 y $5.956',
      '$5.956 y $7.942',
      '$7.942 y $9.927',
      '$9.927 y $11.913',
      '$11.913 y $13.898',
      '$13.898 y $15.884',
      '$15.884 y $17.869',
      '$17.869 y $19.855',
      '$19.855 y $24.818',
      '$24.818 y $29.782',
      '$29.782 y $34.746',
      '$34.746 y $39.709',
      '$39.709 y $49.637',
      '$49.637 y $59.564',
      '$59.564 y $69.491',
      '$69.491 y $79.419',
      '$79.419 y $87.839'
    ]
  },
  {
    id: 30,
    values: [
      '$1.914 y $3.827',
      '$3.827 y $5.741',
      '$5.741 y $7.654',
      '$7.654 y $9.568',
      '$9.568 y $11.481',
      '$11.481 y $13.395',
      '$13.395 y $15.308',
      '$15.308 y $17.222',
      '$17.222 y $19.135',
      '$19.135 y $23.919',
      '$23.919 y $28.703',
      '$28.703 y $33.487',
      '$33.487 y $38.271',
      '$38.271 y $47.838',
      '$47.838 y $57.406',
      '$57.406 y $66.973',
      '$66.973 y $76.541',
      '$76.541 y $84.657'
    ]
  },
  {
    id: 31,
    values: [
      '$1.844 y $3.688',
      '$3.688 y $5.533',
      '$5.533 y $7.377',
      '$7.377 y $9.221',
      '$9.221 y $11.065',
      '$11.065 y $12.910',
      '$12.910 y $14.754',
      '$14.754 y $16.598',
      '$16.598 y $18.442',
      '$18.442 y $23.053',
      '$23.053 y $27.664',
      '$27.664 y $32.274',
      '$32.274 y $36.885',
      '$36.885 y $46.106',
      '$46.106 y $55.327',
      '$55.327 y $64.549',
      '$64.549 y $73.770',
      '$73.770 y $81.592'
    ]
  },
  {
    id: 32,
    values: [
      '$1.778 y $3.555',
      '$3.555 y $5.333',
      '$5.333 y $7.110',
      '$7.110 y $8.888',
      '$8.888 y $10.665',
      '$10.665 y $12.443',
      '$12.443 y $14.220',
      '$14.220 y $15.998',
      '$15.998 y $17.775',
      '$17.775 y $22.219',
      '$22.219 y $26.663',
      '$26.663 y $31.107',
      '$31.107 y $35.551',
      '$35.551 y $44.438',
      '$44.438 y $53.326',
      '$53.326 y $62.214',
      '$62.214 y $71.101',
      '$71.101 y $78.640'
    ]
  },
  {
    id: 33,
    values: [
      '$1.713 y $3.427',
      '$3.427 y $5.140',
      '$5.140 y $6.853',
      '$6.853 y $8.566',
      '$8.566 y $10.280',
      '$10.280 y $11.993',
      '$11.993 y $13.706',
      '$13.706 y $15.420',
      '$15.420 y $17.133',
      '$17.133 y $21.416',
      '$21.416 y $25.699',
      '$25.699 y $29.983',
      '$29.983 y $34.266',
      '$34.266 y $42.832',
      '$42.832 y $51.399',
      '$51.399 y $59.965',
      '$59.965 y $68.532',
      '$68.532 y $75.798'
    ]
  },
  {
    id: 34,
    values: [
      '$1.651 y $3.303',
      '$3.303 y $4.954',
      '$4.954 y $6.606',
      '$6.606 y $8.257',
      '$8.257 y $9.909',
      '$9.909 y $11.560',
      '$11.560 y $13.211',
      '$13.211 y $14.863',
      '$14.863 y $16.514',
      '$16.514 y $20.643',
      '$20.643 y $24.771',
      '$24.771 y $28.900',
      '$28.900 y $33.028',
      '$33.028 y $41.286',
      '$41.286 y $49.543',
      '$49.543 y $57.800',
      '$57.800 y $66.057',
      '$66.057 y $73.061'
    ]
  },
  {
    id: 35,
    values: [
      '$1.592 y $3.184',
      '$3.184 y $4.776',
      '$4.776 y $6.367',
      '$6.367 y $7.959',
      '$7.959 y $9.551',
      '$9.551 y $11.143',
      '$11.143 y $12.735',
      '$12.735 y $14.327',
      '$14.327 y $15.918',
      '$15.918 y $19.898',
      '$19.898 y $23.878',
      '$23.878 y $27.857',
      '$27.857 y $31.837',
      '$31.837 y $39.796',
      '$39.796 y $47.755',
      '$47.755 y $55.714',
      '$55.714 y $63.673',
      '$63.673 y $70.425'
    ]
  },
  {
    id: 36,
    values: [
      '$1.534 y $3.069',
      '$3.069 y $4.603',
      '$4.603 y $6.138',
      '$6.138 y $7.672',
      '$7.672 y $9.207',
      '$9.207 y $10.741',
      '$10.741 y $12.276',
      '$12.276 y $13.810',
      '$13.810 y $15.345',
      '$15.345 y $19.181',
      '$19.181 y $23.017',
      '$23.017 y $26.853',
      '$26.853 y $30.689',
      '$30.689 y $38.361',
      '$38.361 y $46.034',
      '$46.034 y $53.706',
      '$53.706 y $61.378',
      '$61.378 y $67.886'
    ]
  },
  {
    id: 37,
    values: [
      '$1.479 y $2.958',
      '$2.958 y $4.438',
      '$4.438 y $5.917',
      '$5.917 y $7.396',
      '$7.396 y $8.875',
      '$8.875 y $10.354',
      '$10.354 y $11.834',
      '$11.834 y $13.313',
      '$13.313 y $14.792',
      '$14.792 y $18.490',
      '$18.490 y $22.188',
      '$22.188 y $25.886',
      '$25.886 y $29.584',
      '$29.584 y $36.980',
      '$36.980 y $44.376',
      '$44.376 y $51.772',
      '$51.772 y $59.168',
      '$59.168 y $65.441'
    ]
  },
  {
    id: 38,
    values: [
      '$1.426 y $2.852',
      '$2.852 y $4.278',
      '$4.278 y $5.704',
      '$5.704 y $7.130',
      '$7.130 y $8.556',
      '$8.556 y $9.982',
      '$9.982 y $11.408',
      '$11.408 y $12.834',
      '$12.834 y $14.260',
      '$14.260 y $17.825',
      '$17.825 y $21.389',
      '$21.389 y $24.954',
      '$24.954 y $28.519',
      '$28.519 y $35.649',
      '$35.649 y $42.779',
      '$42.779 y $49.909',
      '$49.909 y $57.039',
      '$57.039 y $63.086'
    ]
  },
  {
    id: 39,
    values: [
      '$1.375 y $2.749',
      '$2.749 y $4.124',
      '$4.124 y $5.499',
      '$5.499 y $6.874',
      '$6.874 y $8.248',
      '$8.248 y $9.623',
      '$9.623 y $10.998',
      '$10.998 y $12.372',
      '$12.372 y $13.747',
      '$13.747 y $17.184',
      '$17.184 y $20.621',
      '$20.621 y $24.057',
      '$24.057 y $27.494',
      '$27.494 y $34.368',
      '$34.368 y $41.241',
      '$41.241 y $48.115',
      '$48.115 y $54.988',
      '$54.988 y $60.818'
    ]
  },
  {
    id: 40,
    values: [
      '$1.325 y $2.651',
      '$2.651 y $3.976',
      '$3.976 y $5.301',
      '$5.301 y $6.627',
      '$6.627 y $7.952',
      '$7.952 y $9.277',
      '$9.277 y $10.603',
      '$10.603 y $11.928',
      '$11.928 y $13.253',
      '$13.253 y $16.567',
      '$16.567 y $19.880',
      '$19.880 y $23.193',
      '$23.193 y $26.507',
      '$26.507 y $33.133',
      '$33.133 y $39.760',
      '$39.760 y $46.386',
      '$46.386 y $53.013',
      '$53.013 y $58.634'
    ]
  },
  {
    id: 41,
    values: [
      '$1.278 y $2.556',
      '$2.556 y $3.833',
      '$3.833 y $5.111',
      '$5.111 y $6.389',
      '$6.389 y $7.667',
      '$7.667 y $8.944',
      '$8.944 y $10.222',
      '$10.222 y $11.500',
      '$11.500 y $12.778',
      '$12.778 y $15.972',
      '$15.972 y $19.167',
      '$19.167 y $22.361',
      '$22.361 y $25.555',
      '$25.555 y $31.944',
      '$31.944 y $38.333',
      '$38.333 y $44.722',
      '$44.722 y $51.111',
      '$51.111 y $56.530'
    ]
  },
  {
    id: 42,
    values: [
      '$1.232 y $2.464',
      '$2.464 y $3.696',
      '$3.696 y $4.928',
      '$4.928 y $6.160',
      '$6.160 y $7.392',
      '$7.392 y $8.624',
      '$8.624 y $9.856',
      '$9.856 y $11.088',
      '$11.088 y $12.320',
      '$12.320 y $15.400',
      '$15.400 y $18.479',
      '$18.479 y $21.559',
      '$21.559 y $24.639',
      '$24.639 y $30.799',
      '$30.799 y $36.959',
      '$36.959 y $43.119',
      '$43.119 y $49.279',
      '$49.279 y $54.504'
    ]
  },
  {
    id: 43,
    values: [
      '$1.188 y $2.376',
      '$2.376 y $3.564',
      '$3.564 y $4.751',
      '$4.751 y $5.939',
      '$5.939 y $7.127',
      '$7.127 y $8.315',
      '$8.315 y $9.503',
      '$9.503 y $10.691',
      '$10.691 y $11.878',
      '$11.878 y $14.848',
      '$14.848 y $17.818',
      '$17.818 y $20.787',
      '$20.787 y $23.757',
      '$23.757 y $29.696',
      '$29.696 y $35.635',
      '$35.635 y $41.575',
      '$41.575 y $47.514',
      '$47.514 y $52.552'
    ]
  },
  {
    id: 44,
    values: [
      '$1.145 y $2.291',
      '$2.291 y $3.436',
      '$3.436 y $4.581',
      '$4.581 y $5.727',
      '$5.727 y $6.872',
      '$6.872 y $8.017',
      '$8.017 y $9.163',
      '$9.163 y $10.308',
      '$10.308 y $11.453',
      '$11.453 y $14.317',
      '$14.317 y $17.180',
      '$17.180 y $20.044',
      '$20.044 y $22.907',
      '$22.907 y $28.634',
      '$28.634 y $34.360',
      '$34.360 y $40.087',
      '$40.087 y $45.814',
      '$45.814 y $50.672'
    ]
  },
  {
    id: 45,
    values: [
      '$1.104 y $2.209',
      '$2.209 y $3.313',
      '$3.313 y $4.418',
      '$4.418 y $5.522',
      '$5.522 y $6.626',
      '$6.626 y $7.731',
      '$7.731 y $8.835',
      '$8.835 y $9.940',
      '$9.940 y $11.044',
      '$11.044 y $13.805',
      '$13.805 y $16.566',
      '$16.566 y $19.327',
      '$19.327 y $22.088',
      '$22.088 y $27.610',
      '$27.610 y $33.132',
      '$33.132 y $38.654',
      '$38.654 y $44.176',
      '$44.176 y $48.860'
    ]
  },
  {
    id: 46,
    values: [
      '$1.065 y $2.130',
      '$2.130 y $3.195',
      '$3.195 y $4.260',
      '$4.260 y $5.325',
      '$5.325 y $6.390',
      '$6.390 y $7.455',
      '$7.455 y $8.520',
      '$8.520 y $9.585',
      '$9.585 y $10.650',
      '$10.650 y $13.312',
      '$13.312 y $15.975',
      '$15.975 y $18.637',
      '$18.637 y $21.300',
      '$21.300 y $26.624',
      '$26.624 y $31.949',
      '$31.949 y $37.274',
      '$37.274 y $42.599',
      '$42.599 y $47.116'
    ]
  },
  {
    id: 47,
    values: [
      '$1.027 y $2.054',
      '$2.054 y $3.081',
      '$3.081 y $4.108',
      '$4.108 y $5.135',
      '$5.135 y $6.162',
      '$6.162 y $7.189',
      '$7.189 y $8.216',
      '$8.216 y $9.243',
      '$9.243 y $10.270',
      '$10.270 y $12.837',
      '$12.837 y $15.405',
      '$15.405 y $17.972',
      '$17.972 y $20.540',
      '$20.540 y $25.675',
      '$25.675 y $30.810',
      '$30.810 y $35.945',
      '$35.945 y $41.080',
      '$41.080 y $45.435'
    ]
  },
  {
    id: 48,
    values: [
      '$990 y $1.981',
      '$1.981 y $2.971',
      '$2.971 y $3.962',
      '$3.962 y $4.952',
      '$4.952 y $5.942',
      '$5.942 y $6.933',
      '$6.933 y $7.923',
      '$7.923 y $8.914',
      '$8.914 y $9.904',
      '$9.904 y $12.380',
      '$12.380 y $14.856',
      '$14.856 y $17.332',
      '$17.332 y $19.808',
      '$19.808 y $24.760',
      '$24.760 y $29.712',
      '$29.712 y $34.664',
      '$34.664 y $39.616',
      '$39.616 y $43.816'
    ]
  },
  {
    id: 49,
    values: [
      '$955 y $1.910',
      '$1.910 y $2.865',
      '$2.865 y $3.821',
      '$3.821 y $4.776',
      '$4.776 y $5.731',
      '$5.731 y $6.686',
      '$6.686 y $7.641',
      '$7.641 y $8.596',
      '$8.596 y $9.551',
      '$9.551 y $11.939',
      '$11.939 y $14.327',
      '$14.327 y $16.715',
      '$16.715 y $19.103',
      '$19.103 y $23.879',
      '$23.879 y $28.654',
      '$28.654 y $33.430',
      '$33.430 y $38.206',
      '$38.206 y $42.257'
    ]
  },
  {
    id: 50,
    values: [
      '$921 y $1.842',
      '$1.842 y $2.764',
      '$2.764 y $3.685',
      '$3.685 y $4.606',
      '$4.606 y $5.527',
      '$5.527 y $6.448',
      '$6.448 y $7.369',
      '$7.369 y $8.291',
      '$8.291 y $9.212',
      '$9.212 y $11.515',
      '$11.515 y $13.818',
      '$13.818 y $16.121',
      '$16.121 y $18.424',
      '$18.424 y $23.030',
      '$23.030 y $27.636',
      '$27.636 y $32.242',
      '$32.242 y $36.847',
      '$36.847 y $40.754'
    ]
  },
  {
    id: 51,
    values: [
      '$888 y $1.777',
      '$1.777 y $2.665',
      '$2.665 y $3.554',
      '$3.554 y $4.442',
      '$4.442 y $5.331',
      '$5.331 y $6.219',
      '$6.219 y $7.108',
      '$7.108 y $7.996',
      '$7.996 y $8.885',
      '$8.885 y $11.106',
      '$11.106 y $13.327',
      '$13.327 y $15.548',
      '$15.548 y $17.769',
      '$17.769 y $22.212',
      '$22.212 y $26.654',
      '$26.654 y $31.096',
      '$31.096 y $35.539',
      '$35.539 y $39.307'
    ]
  },
  {
    id: 52,
    values: [
      '$857 y $1.714',
      '$1.714 y $2.571',
      '$2.571 y $3.428',
      '$3.428 y $4.285',
      '$4.285 y $5.142',
      '$5.142 y $5.999',
      '$5.999 y $6.856',
      '$6.856 y $7.713',
      '$7.713 y $8.569',
      '$8.569 y $10.712',
      '$10.712 y $12.854',
      '$12.854 y $14.997',
      '$14.997 y $17.139',
      '$17.139 y $21.424',
      '$21.424 y $25.708',
      '$25.708 y $29.993',
      '$29.993 y $34.278',
      '$34.278 y $37.912'
    ]
  },
  {
    id: 53,
    values: [
      '$827 y $1.653',
      '$1.653 y $2.480',
      '$2.480 y $3.306',
      '$3.306 y $4.133',
      '$4.133 y $4.959',
      '$4.959 y $5.786',
      '$5.786 y $6.613',
      '$6.613 y $7.439',
      '$7.439 y $8.266',
      '$8.266 y $10.332',
      '$10.332 y $12.399',
      '$12.399 y $14.465',
      '$14.465 y $16.532',
      '$16.532 y $20.665',
      '$20.665 y $24.797',
      '$24.797 y $28.930',
      '$28.930 y $33.063',
      '$33.063 y $36.569'
    ]
  },
  {
    id: 54,
    values: [
      '$797 y $1.595',
      '$1.595 y $2.392',
      '$2.392 y $3.189',
      '$3.189 y $3.987',
      '$3.987 y $4.784',
      '$4.784 y $5.581',
      '$5.581 y $6.379',
      '$6.379 y $7.176',
      '$7.176 y $7.973',
      '$7.973 y $9.967',
      '$9.967 y $11.960',
      '$11.960 y $13.953',
      '$13.953 y $15.946',
      '$15.946 y $19.933',
      '$19.933 y $23.920',
      '$23.920 y $27.906',
      '$27.906 y $31.893',
      '$31.893 y $35.274'
    ]
  },
  {
    id: 55,
    values: [
      '$769 y $1.538',
      '$1.538 y $2.307',
      '$2.307 y $3.077',
      '$3.077 y $3.846',
      '$3.846 y $4.615',
      '$4.615 y $5.384',
      '$5.384 y $6.153',
      '$6.153 y $6.922',
      '$6.922 y $7.691',
      '$7.691 y $9.614',
      '$9.614 y $11.537',
      '$11.537 y $13.460',
      '$13.460 y $15.383',
      '$15.383 y $19.228',
      '$19.228 y $23.074',
      '$23.074 y $26.920',
      '$26.920 y $30.765',
      '$30.765 y $34.027'
    ]
  },
  {
    id: 56,
    values: [
      '$742 y $1.484',
      '$1.484 y $2.226',
      '$2.226 y $2.968',
      '$2.968 y $3.710',
      '$3.710 y $4.452',
      '$4.452 y $5.194',
      '$5.194 y $5.936',
      '$5.936 y $6.678',
      '$6.678 y $7.420',
      '$7.420 y $9.275',
      '$9.275 y $11.130',
      '$11.130 y $12.984',
      '$12.984 y $14.839',
      '$14.839 y $18.549',
      '$18.549 y $22.259',
      '$22.259 y $25.969',
      '$25.969 y $29.679',
      '$29.679 y $32.826'
    ]
  },
  {
    id: 57,
    values: [
      '$716 y $1.432',
      '$1.432 y $2.147',
      '$2.147 y $2.863',
      '$2.863 y $3.579',
      '$3.579 y $4.295',
      '$4.295 y $5.011',
      '$5.011 y $5.726',
      '$5.726 y $6.442',
      '$6.442 y $7.158',
      '$7.158 y $8.947',
      '$8.947 y $10.737',
      '$10.737 y $12.526',
      '$12.526 y $14.316',
      '$14.316 y $17.895',
      '$17.895 y $21.474',
      '$21.474 y $25.053',
      '$25.053 y $28.632',
      '$28.632 y $31.668'
    ]
  },
  {
    id: 58,
    values: [
      '$691 y $1.381',
      '$1.381 y $2.072',
      '$2.072 y $2.762',
      '$2.762 y $3.453',
      '$3.453 y $4.143',
      '$4.143 y $4.834',
      '$4.834 y $5.525',
      '$5.525 y $6.215',
      '$6.215 y $6.906',
      '$6.906 y $8.632',
      '$8.632 y $10.359',
      '$10.359 y $12.085',
      '$12.085 y $13.812',
      '$13.812 y $17.264',
      '$17.264 y $20.717',
      '$20.717 y $24.170',
      '$24.170 y $27.623',
      '$27.623 y $30.552'
    ]
  },
  {
    id: 59,
    values: [
      '$666 y $1.333',
      '$1.333 y $1.999',
      '$1.999 y $2.665',
      '$2.665 y $3.331',
      '$3.331 y $3.998',
      '$3.998 y $4.664',
      '$4.664 y $5.330',
      '$5.330 y $5.997',
      '$5.997 y $6.663',
      '$6.663 y $8.328',
      '$8.328 y $9.994',
      '$9.994 y $11.660',
      '$11.660 y $13.326',
      '$13.326 y $16.657',
      '$16.657 y $19.988',
      '$19.988 y $23.320',
      '$23.320 y $26.651',
      '$26.651 y $29.477'
    ]
  },
  {
    id: 60,
    values: [
      '$643 y $1.286',
      '$1.286 y $1.929',
      '$1.929 y $2.571',
      '$2.571 y $3.214',
      '$3.214 y $3.857',
      '$3.857 y $4.500',
      '$4.500 y $5.143',
      '$5.143 y $5.786',
      '$5.786 y $6.429',
      '$6.429 y $8.036',
      '$8.036 y $9.643',
      '$9.643 y $11.250',
      '$11.250 y $12.857',
      '$12.857 y $16.072',
      '$16.072 y $19.286',
      '$19.286 y $22.500',
      '$22.500 y $25.715',
      '$25.715 y $28.441'
    ]
  },
  {
    id: 61,
    values: [
      '$620 y $1.241',
      '$1.241 y $1.861',
      '$1.861 y $2.481',
      '$2.481 y $3.101',
      '$3.101 y $3.722',
      '$3.722 y $4.342',
      '$4.342 y $4.962',
      '$4.962 y $5.583',
      '$5.583 y $6.203',
      '$6.203 y $7.754',
      '$7.754 y $9.304',
      '$9.304 y $10.855',
      '$10.855 y $12.406',
      '$12.406 y $15.507',
      '$15.507 y $18.609',
      '$18.609 y $21.710',
      '$21.710 y $24.812',
      '$24.812 y $27.443'
    ]
  },
  {
    id: 62,
    values: [
      '$599 y $1.197',
      '$1.197 y $1.796',
      '$1.796 y $2.394',
      '$2.394 y $2.993',
      '$2.993 y $3.591',
      '$3.591 y $4.190',
      '$4.190 y $4.788',
      '$4.788 y $5.387',
      '$5.387 y $5.986',
      '$5.986 y $7.482',
      '$7.482 y $8.978',
      '$8.978 y $10.475',
      '$10.475 y $11.971',
      '$11.971 y $14.964',
      '$14.964 y $17.957',
      '$17.957 y $20.949',
      '$20.949 y $23.942',
      '$23.942 y $26.481'
    ]
  },
  {
    id: 63,
    values: [
      '$578 y $1.155',
      '$1.155 y $1.733',
      '$1.733 y $2.310',
      '$2.310 y $2.888',
      '$2.888 y $3.466',
      '$3.466 y $4.043',
      '$4.043 y $4.621',
      '$4.621 y $5.198',
      '$5.198 y $5.776',
      '$5.776 y $7.220',
      '$7.220 y $8.664',
      '$8.664 y $10.108',
      '$10.108 y $11.552',
      '$11.552 y $14.440',
      '$14.440 y $17.328',
      '$17.328 y $20.216',
      '$20.216 y $23.104',
      '$23.104 y $25.554'
    ]
  },
  {
    id: 64,
    values: [
      '$557 y $1.115',
      '$1.115 y $1.672',
      '$1.672 y $2.230',
      '$2.230 y $2.787',
      '$2.787 y $3.344',
      '$3.344 y $3.902',
      '$3.902 y $4.459',
      '$4.459 y $5.017',
      '$5.017 y $5.574',
      '$5.574 y $6.968',
      '$6.968 y $8.361',
      '$8.361 y $9.755',
      '$9.755 y $11.148',
      '$11.148 y $13.935',
      '$13.935 y $16.722',
      '$16.722 y $19.509',
      '$19.509 y $22.296',
      '$22.296 y $24.660'
    ]
  },
  {
    id: 65,
    values: [
      '$538 y $1.076',
      '$1.076 y $1.614',
      '$1.614 y $2.152',
      '$2.152 y $2.690',
      '$2.690 y $3.228',
      '$3.228 y $3.766',
      '$3.766 y $4.304',
      '$4.304 y $4.841',
      '$4.841 y $5.379',
      '$5.379 y $6.724',
      '$6.724 y $8.069',
      '$8.069 y $9.414',
      '$9.414 y $10.759',
      '$10.759 y $13.448',
      '$13.448 y $16.138',
      '$16.138 y $18.828',
      '$18.828 y $21.518',
      '$21.518 y $23.799'
    ]
  }
];
