import { FormGroup } from '@angular/forms';

export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    matchingControl.setErrors(null);
    const controlValue = typeof(control.value) === 'string' ? control.value.toLowerCase() : control.value;
    const matchingControlValue = typeof(matchingControl.value) === 'string' ? matchingControl.value.toLowerCase() : matchingControl.value;
    if (controlValue !== matchingControlValue && matchingControlValue !== '') {
      matchingControl.setErrors({ mustMatch: true });
    }
  };
}
