import { Mockup } from '@interfaces/mockup.interface';

export const CONTACT_MOCKUP: Mockup = {
    success: [{
      response: {
        folio: '123456',
        successful: true
      }
    }],
    failures: [{
      response: {}
    }]
  };

  export const GET_REASONS_MOCKUP: Mockup = {
    success: [{
      response: [
        {code: '1', reason: 'Quiero traspasarme a AFP PlanVital'},
        {code: '2', reason: 'Mis trabajadores se quieren cambiar a  AFP PlanVital (visita a empresa)'},
        {code: '4', reason: 'Pago de Pensiones y Otros Beneficios'},
        {code: '5', reason: 'Cambio/Distribución de Fondos'},
        {code: '6', reason: 'Certificados'},
        {code: '7', reason: 'Rezagos'},
        {code: '8', reason: 'Cobranza'},
        {code: '9', reason: 'Quejas o sugerencias'},
        {code: '10', reason: 'Otros'},
        {code: '11', reason: 'Solicito visita para abrir cuenta 2'},
      ],
    }],
    failures: [{
      response: {}
    }]
  };

