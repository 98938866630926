import { ModalData } from '@interfaces/modal-data.interface';

export const DEFAULT_ERROR_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: 'Ha ocurrido un error inesperado',
  message: 'Vuelve a intentarlo más tarde',
  firstBtnText: 'Aceptar',
  primaryCallback: () => { }
};

export const DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS: ModalData = {
  ...DEFAULT_ERROR_MODAL_PROPS,
  firstBtnText: 'Reintentar',
};

export const DEFAULT_ERROR_MODAL_WITH_BACK_PROPS: ModalData = {
  ...DEFAULT_ERROR_MODAL_PROPS,
  firstBtnText: 'Volver',
};

export const ERROR_STATIC_DATA: ModalData = {
  ...DEFAULT_ERROR_MODAL_PROPS,
  title: 'Hubo un error al ingresar a la solicitud.',
  message: 'Inténtalo nuevamente.',
};

export const ERROR_MODAL_INVALIDATING_REJECTION: ModalData = {
  ...DEFAULT_ERROR_MODAL_PROPS,
  title: 'El estado de tu solicitud no permite ingresar nuevamente',
  message: 'Cualquier duda puedes escribirnos a infovital@planvital.cl o en nuestras Redes Sociales.',
};

export const ERROR_MODAL_NOT_CLIENT: ModalData = {
  ...DEFAULT_ERROR_MODAL_PROPS,
  title: 'Al parecer no estás en AFP PlanVital',
  message: 'Te invitamos a realizar la solicitud en tu AFP. Si no la conoces, puedes consultarlo en la página de la ' +
    'Superintendencia de pensiones.',
};

export const ERROR_MODAL_POLITICAL_AUTHORITIES: ModalData = {
  ...DEFAULT_ERROR_MODAL_PROPS,
  title: 'Tu solicitud no se ha podido realizar',
  message: 'Por normativa quedan inhabilitados de retiro quienes tengan altos cargos públicos y de gobierno.',
};

export const SEND_VOUCHER_SUCCESS_MODAL: ModalData = {
  iconName: 'i-checkmark',
  title: '¡Listo!',
  message: 'El comprobante de tu solicitud se ha enviado a tu correo exitosamente.',
  firstBtnText: 'Aceptar',
  primaryCallback: () => { }
};

export const PERCENTAGE_ERROR_MESSAGE = {
  MAXIMUM_PERCENTAGE: 'No puedes retirar más del 100%',
  MINIMUM_PERCENTAGE: 'No puedes retirar menos del 1%',
  PATTERN: 'No debe contener decimales'
};

export const EXISTING_WITHDRAWAL_ERROR = {
  2: 'Ya tenemos registrada una Solicitud del segundo retiro de fondos, para revisar su estado',
  3: 'Ya tenemos registrada una Solicitud del tercer retiro de fondos, para revisar su estado',
};
