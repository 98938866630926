import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '@components/header/header.component';
import { MenuController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { WithdrawalsService } from '@services/withdrawals/withdrawals.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
})
export class HeaderDesktopComponent extends HeaderComponent {
  constructor(
    public font: FontService,
    protected utils: Utils,
    protected navService: NavigationService,
    protected menu: MenuController,
    protected router: Router,
    protected withdrawalsService: WithdrawalsService
  ) {
    super(font, utils, navService, menu, router, withdrawalsService);
  }
}
