import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalComponent } from '@components/modal/modal.component';
import { CONTACT_RESULT_MODAL } from '@constants/pages-content/help-button.constant';
import { ContactState } from '@interfaces/contact-form.interface';
import { Section } from '@interfaces/general.interface';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';

@Component({
    selector: 'app-modal-contact-result',
    templateUrl: './contact-result-modal.component.html',
    styleUrls: ['./contact-result-modal.component.scss'],
  })
  export class ContactResultModalComponent {
    public content = CONTACT_RESULT_MODAL;
    public folio: string;
    public successful: boolean;

    constructor(
      public font: FontService,
      private utils: Utils,
      private dialogRef: MatDialogRef<ModalComponent>,
      @Inject(MAT_DIALOG_DATA) private params: ContactState,
    ) {
      this.folio = this.params.folio;
      this.successful = this.params.successful;
    }

    public get data(): Section {
      const { success, error } = this.content;
      if (!this.successful) { return error; }

      const params = this.resultParams;
      return {
        title: success.title,
        description: success.description.replace('{{ date }}', params.date).replace('{{ folio }}', params.folio)
      };
    }

    public get resultParams() {
      return { folio: this.folio, date: this.utils.getFormattedDate(new Date()) };
    }

    public closeModal() {
      this.dialogRef.close();
    }

  }
