import { Environment } from '@interfaces/environment.interface';

export const environment: Environment = {
  name: 'qa',
  production: false,
  clientsUrl: 'http://34.120.156.248/clients/',
  withdrawalsUrl: 'http://34.120.156.248/withdrawals/',
  proxyApiUrl: 'http://34.120.156.248/public/',
  cmsApiUrl: 'http://35.186.243.77/cms/sitio-publico/',
  cmsFilesUrl: 'http://35.186.243.77/cms/uploads/sitio-publico/originals/',
  cmsAssetsUrl: 'http://35.186.243.77/cms/',
  cmsContent: 'published',
  siteKey: '6LdMsLcZAAAAAILUOD0ObyYhJHeWDjUeMSaPWFbC',
  googleMapsKey: 'AIzaSyDK_MHYxjJmdNh3TV5o3hN-K-viZxZzOmM',
  googleAnalyticsId: 'UA-174559163-2',
  mockHttpCalls: false,
  mockedResponseDelay: 0,
  mockedResponseSuccessRate: 1,
  loginUrl: 'https://shckltn2.planvital.cl:444/NewLogin',
  balanceUrl: 'http://34.107.148.233/',
  recoverAccessKeyUrl: 'https://shckltn2.planvital.cl:448/RecuperarClaveAcceso.aspx?canal=web',
  publicSiteUrl: 'http://34.102.189.39/',
};
