import { Item } from '@interfaces/general.interface';
import { ModalData } from '@interfaces/modal-data.interface';

export const FISCAL_BONUS_VALIDATE_OPTIONS: Array<Item> = [
  { id: 1, name: 'Con mi RUT' },
  { id: 2, name: 'Con mi NIC (solo para extranjeros sin Cédula de Identidad)' },
  { id: 3, name: 'Desde el extranjero' },
];

export const VALID_OPTIONS_REQUEST = {
  rut: 'R',
  nic: 'N',
};

export const TITLE_MODAL_BONUS_CONFIRMATION = 'Confirma tu solicitud de retiro del Bono de Cargo Fiscal';

export const BONUS_CERTIFICATE_NAME = 'certificadoBonoCargoFiscal';

export const REQUIRED_FILES = [
  {
    order: 1,
    buttonLabel: 'Certificado Nacimiento',
    fileIndex: 'birthCertificate',
    required: true
  },
  {
    order: 2,
    buttonLabel: 'Cédula de Identidad',
    fileIndex: 'identificationFile',
    required: true
  }
];

export const APPLICANT_INFORMATION_MODAL = {
  title: 'Solicitante',
  message: 'El Solicitante es el padre, madre o tutor que tiene la facultad de representar al menor para esta solicitud y es quien recibe \
  el pago',
  firstBtnText: 'Entendido',
  iconName: 'i-applicant-information'
} as ModalData;
