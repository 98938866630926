import { Component, Input } from '@angular/core';
import { Banner, BannerStyle, BannerType } from '@interfaces/banner.interface';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
})
export class InfoBannerComponent {
  @Input() public banner: Banner;
  @Input() public type: BannerType;
  @Input() public style: BannerStyle;

  constructor(
    public font: FontService,
    private navService: NavigationService,
  ) { }

  public goToUrl() {
    this.navService.goTo(this.banner.url);
  }

  public get styleClass() {
    return this.style || '' ;
  }

  public get styleBackgroundClass() {
    return this.banner ? this.banner.style || '' : '';
  }

  public get iconClass() {
    return this.banner ? `i-${this.banner.icon}` : '';
  }

}
