import { finalize } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ANNUL_LIMIT_DAYS_ERROR_CODE } from '@constants/error-codes.constant';
import { BASE_REASONS, CANCEL_REASONS, GENERIC_WITHDRAWAL_NUMBER } from '@constants/funds-withdrawal.constant';
import { WITHDRAWAL_INSTANCES } from '@constants/pages-content/home.constant';
import { CancelRequestData, WithdrawalType } from '@interfaces/funds-withdrawal.interface';
import { Item } from '@interfaces/general.interface';
import { LoadingService } from '@providers/loading/loading.service';
import { WithdrawalsService } from '@services/withdrawals/withdrawals.service';

@Component({
  selector: 'app-cancel-generic-request-modal',
  templateUrl: './cancel-generic-request-modal.component.html',
  styleUrls: ['./cancel-generic-request-modal.component.scss']
})
export class CancelGenericRequestModalComponent implements OnInit {
  public form: FormGroup;
  public errorMessage: string;
  public withdrawalInstance: WithdrawalType;
  public withdrawalReferences = WITHDRAWAL_INSTANCES;
  public reasons: Array<Item>;

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private withdrawalsService: WithdrawalsService,
    public dialogRef: MatDialogRef<CancelGenericRequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CancelRequestData,
  ) { }

  public ngOnInit(): void {
    this.withdrawalInstance = this.data.withdrawalInstance || 2;
    this.reasons = this.getReasons();
    this.createForm();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public cancelRequest(): void {
    if (this.form.invalid) { return; }
    this.errorMessage = undefined;
    this.loadingService.showLoading();

    const cancelRequestService = this.getAnnulRequestService();

    cancelRequestService
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        () => this.closeDialog(),
        (error) => this.handleCancelError(error)
      );
  }

  public reasonId(): number {
    const { reason, option1, option2, option3 } = this.form.controls;
    Object.keys(this.form.controls).forEach((key) => this.clearOptionValidator(this.form.get(key)));
    if (reason.value === 3) {
      this.clearOptionValidator(option1, true);
    }
    if (reason.value === 0 || reason.value === 1 || reason.value === 2) {
      this.clearOptionValidator(option2, true);
    }
    if (reason.value === 2) {
      this.clearOptionValidator(option3, true);
    }
    return reason.value;
  }

  private getReasons(): Array<Item> {
    switch (this.withdrawalInstance) {
      case GENERIC_WITHDRAWAL_NUMBER.SECOND:
      case GENERIC_WITHDRAWAL_NUMBER.THIRD:
        return CANCEL_REASONS;
      case GENERIC_WITHDRAWAL_NUMBER.FIRST_BONUS:
        return BASE_REASONS;
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      reason: ['', Validators.required],
      option1: [false],
      option2: [false],
      option3: [false],
    });
  }

  private handleCancelError(error): void {
    if (error.statusCode === 404) {
      this.errorMessage = 'Los datos ingresados no corresponden a ninguna de tus solicitudes.';
    } else if (error.error) {
      if (error.error.code === ANNUL_LIMIT_DAYS_ERROR_CODE) {
        this.errorMessage = error.error.message;
      }
    } else { this.errorMessage = 'Ha ocurrido un error inesperado, no hemos podido anular tu solicitud, vuelve a intentarlo más tarde.'; }
  }

  private clearOptionValidator(control: AbstractControl, required?: boolean): void {
    control.setValidators(required ? Validators.requiredTrue : null);
    control.updateValueAndValidity();
  }

  private getAnnulRequestService() {
    const { reason } = this.form.getRawValue();
    const { request, rut } = this.data;
    return this.withdrawalsService.annulGenericRequest(this.withdrawalInstance, rut, request.folio, reason);
  }
}
