import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEsCl from '@angular/common/locales/es-CL';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { LoadingComponent } from '@components/loading/loading.component';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client/http-client.interceptor';
import { RecaptchaInterceptor } from '@interceptors/recaptcha/recaptcha.interceptor';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateModule } from '@ngx-translate/core';
import { EmailDomainValidator } from '@providers/email-domain-validator/email-domain-validator.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IpInterceptor } from '@interceptors/http-ip-interceptor/ip-interceptor';
import { CmsUtils } from '@utils/cms.utils';

registerLocaleData(localeEsCl);

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
  ],
  providers: [
    CmsUtils,
    ErrorUtils,
    HttpClientInterceptor,
    LoadingService,
    SplashScreen,
    StatusBar,
    Utils,
    ModalService,
    EmailDomainValidator,
    { provide: NavParams, useValue: '' },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.siteKey },
    { provide: HTTP_INTERCEPTORS, useClass: RecaptchaInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IpInterceptor, multi: true },
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } },
  ],
  exports: [
    ReactiveFormsModule,
    TranslateModule
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
