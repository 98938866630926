import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  ContactResultModalComponent
} from '@components/contact-result-modal/contact-result-modal.component';
import { ModalComponent } from '@components/modal/modal.component';
import {
  CONTACT_FORM_FIELDS, CONTACT_FORM_OPTIONS, CONTACT_MODAL_TEXT
} from '@constants/pages-content/help-button.constant';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@constants/regex.constant';
import { ContactReasonItem } from '@interfaces/contact-form.interface';
import { ModalData } from '@interfaces/modal-data.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal';
import { ContactService } from '@services/contact/contact.service';
import { Utils } from '@utils/utils';
import { ValidateRut } from '@validators/rut.validator';

@Component({
  selector: 'app-modal-contact',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent extends ModalComponent implements OnInit {
  public fieldNames = CONTACT_FORM_FIELDS;
  public content = CONTACT_MODAL_TEXT;
  public contentOptions = CONTACT_FORM_OPTIONS;
  public contactReasons: Array<ContactReasonItem>;
  public form: FormGroup = new FormGroup({
    rut: new FormControl('', [ValidateRut, Validators.required]),
    fatherLastName: new FormControl('', [Validators.required]),
    motherLastName: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern(PHONE_PATTERN)]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]),
    reasonCode: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    addedFile: new FormControl(false),
    fileName: new FormControl(),
    file: new FormControl(),
  });

  public get rut() { return this.form.get('rut') as FormControl; }
  public get fileName() { return this.form.get('fileName') as FormControl; }
  public get addedFile() { return this.form.get('addedFile') as FormControl; }
  public get reasonCode() { return this.form.get('reasonCode') as FormControl; }
  public get labelButtonFile() { return !this.fileName.value ? this.contentOptions.upload : this.contentOptions.update; }

  constructor(
    public font: FontService,
    private utils: Utils,
    private contactService: ContactService,
    protected modalService: ModalService,
    public dialogRef: MatDialogRef<ContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
    super(dialogRef, data);
  }

  ngOnInit() {
    this.getContactReasons();
  }

  private getContactReasons() {
    this.contactService.getReasons().subscribe((response) => this.contactReasons = response);
  }

  public sendFormData() {
    if (this.form.invalid) { return; }
    const formData = new FormData();
    Object.keys(this.form.controls).forEach((key) => this.formDataAppendHandler(formData, key, this.form.get(key).value));
    this.contactService.contact(formData)
      .subscribe(
        (response) => {
          this.dialogRef.afterClosed().subscribe(
            () => this.modalService.openCustomModal(ContactResultModalComponent, { ...response })
          );
          this.closeDialog();
        },
        (error) => {
          this.dialogRef.afterClosed().subscribe(
            () => this.modalService.openModal({
              title: 'Error',
              message: error.messageDescription,
              firstBtnText: 'Volver',
              primaryCallback: () => { }
            })
          );
          this.closeDialog();
        },
      );
  }

  private formDataAppendHandler(formData: FormData, key: string, value: any): void {
    if (!value || key === 'addedFile' || key === 'fileName') { return; }
    if (key === 'rut') { value = this.utils.rutClean(value); }
    formData.append(key, value);
  }

  public onFileChange(event) {
    this.form.patchValue({ file: null, fileName: null, });
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.form.patchValue({ file: file, fileName: file.name });
    }
  }
}
