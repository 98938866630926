import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  BLOCKED_ID_ERROR_CODE, EXPIRED_ID_ERROR_CODE, INVALID_DOCUMENT_ID, INVALID_EQUIFAX_REJECTION,
  INVALID_RUT_SECURITY_KEY_REJECTION
} from '@constants/error-codes.constant';
import {
  PASSWORD_MIN_LENGTH, SECURITY_KEY_MAX_LENGTH, SECURITY_KEY_MIN_LENGTH
} from '@constants/funds-withdrawal.constant';
import { environment } from '@environment';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent {
  public form: FormGroup;
  public securityKeyMinLength: number;
  public securityKeyMaxLength = SECURITY_KEY_MAX_LENGTH;

  private errorMessages = {
    [BLOCKED_ID_ERROR_CODE]: 'Tu cédula de identidad está bloqueada',
    [EXPIRED_ID_ERROR_CODE]: 'Tu cédula de identidad está vencida',
    [INVALID_DOCUMENT_ID]: 'Tu número de serie es incorrecto',
    [INVALID_EQUIFAX_REJECTION]: 'No fue posible validar tu cédula de identidad'
  };

  constructor(
    public dialogRef: MatDialogRef<PasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { code: number, isSecondWithdrawal: boolean, isBeneficiary?: boolean },
    private formBuilder: FormBuilder,
    private navService: NavigationService,
  ) {
    this.securityKeyMinLength = data.isSecondWithdrawal ? PASSWORD_MIN_LENGTH : SECURITY_KEY_MIN_LENGTH;
    this.form = this.formBuilder.group({
      securityKey: ['', [
        Validators.required,
        Validators.minLength(this.securityKeyMinLength),
        Validators.maxLength(this.securityKeyMaxLength),
      ]],
    });
  }

  public get isBeneficiary(): boolean { return this.data.isBeneficiary; }

  public get isRetry(): boolean { return this.data.code === INVALID_RUT_SECURITY_KEY_REJECTION; }

  public get idStatusMessage(): string { return this.errorMessages[this.data.code]; }

  public get securityKey(): AbstractControl { return this.form.get('securityKey'); }

  public goToBalance() {
    this.navService.goTo(environment.balanceUrl);
  }

  public closeDialog(callback: boolean = false) {
    if (callback && this.form.invalid) { return; }
    if (this.isBeneficiary) { return this.dialogRef.close(callback ? this.securityKey.value : 'home'); }
    this.dialogRef.close(callback ? this.securityKey.value : null);
  }

}
